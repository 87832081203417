import React, { useState } from 'react'
import { Chip, TextField, Box, Grid, FormControl, Typography } from '@mui/material'
import styles from './index.module.css'
type TagsInputBoxProps = {
  tags: string[]
  setTags: (tags: string[]) => void
  label: string
}

const TagsInputBox = ({ tags, setTags, label }: TagsInputBoxProps) => {
  const [inputValue, setInputValue] = useState<string>('')

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value)
  }

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      setTags([...tags, inputValue.trim()])
      setInputValue(' ')
    }
  }

  const handleDeleteTag = (tagIndexToDelete: number) => {
    const updatedTags = tags.filter((tag,index) => index !== tagIndexToDelete)
    setTags(updatedTags)
  }

  return (
    <FormControl variant='standard' fullWidth sx={{height:'100%'}}>
      <Typography variant='subtitle2' component='p' color='white' mb={1} fontWeight='bold'>
        <label> {label}</label>
      </Typography>
      <Grid container className={styles.tagsInputBoxContainer}>
        {tags.map((tag, index) => (
          <Chip
            key={index}
            label={tag}
            onDelete={() => handleDeleteTag(index)}
            size='small'
          />
        ))}
        <TextField
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyPress}
          size='small'
          placeholder='Type to add tags'
          sx={{
            height: '33px',
          }}
          inputProps={{
            style: {
              border: 'none',
              padding: '5px',
            },
          }}
        />
      </Grid>
    </FormControl>
  )
}

export default TagsInputBox
