import axios from 'src/api/axios'

const ENDPOINT = 'api/v1/house/admin/management/amenities/'

interface addAmenitiesPayload{
  name: string
}

export function addAmenities(payload: addAmenitiesPayload) {
  return new Promise((resolve, reject) => {
    axios
      .post(ENDPOINT , payload)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function fetchAmenities() {
  return new Promise((resolve, reject) => {
    axios
      .get(ENDPOINT)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

interface changeAmenitiesStatusPayload{
  id: number;
  flagged: boolean
}
export function changeAmenitiesStatus(payload: changeAmenitiesStatusPayload) {
  return new Promise((resolve, reject) => {
    axios
      .put(ENDPOINT + `${payload.id}/` , {
        flagged: payload.flagged
      })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
