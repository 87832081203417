import React from 'react';
import addicon from 'src/assets/icons/add.png';

interface IAddIcon {
    width?: number,
    height?: number,
    style?: object,
    id?: string;
    className?: any;
}

const AddIcon = (props: IAddIcon) => {
    const { width = 25, height = 25, style = {}, id , className} = props
    return (
        <img src={addicon} width={width} height={height} style={style} id={id} className={className} />
    );
};

export default AddIcon;