/* eslint-disable camelcase */
import * as React from 'react'
import { Box, Typography, Popover, Pagination, Chip, IconButton, Grid } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import SearchInputBox from 'src/Components/SearchInputBox'
import FilterIcon from 'src/Components/Icons/FilterIcon'

import EnhancedTableHead, { HeadCell, Order } from 'src/Components/Table/EnhancedTableHead'

import TableLoader from 'src/Components/Table/TableLoader'
import {
  perPageForListing,
  reportHouseCategoryArr,
  reportHouseStatusArr,
  routeUrls,
} from 'src/utils/constant'

import dayjs from 'dayjs'

import LinkIcon from 'src/Components/Icons/LinkIcon'
import { useNavigate } from 'react-router-dom'

import ExportIcon from 'src/Components/Icons/ExportIcon'
import { errorToast } from 'src/utils/toastService'
import { exportReportedHouseData, fetchReportedHouseRequests } from 'src/api/reportedHouse'
import {
  IReportedHouseRequest,
  IReportedHouseRequestStatus,
  paginationPayload,
} from 'src/api/reportedHouse/index.types'

const headCells: readonly HeadCell[] = [
  {
    id: 'link',
    label: '',
    sortable: false,
  },
  {
    id: 'id',
    label: '#',
    sortable: true,
  },
  {
    id: 'created_at',
    label: 'Date',
    sortable: true,
  },
  {
    id: 'reported_by__name',
    label: 'Name',
    sortable: true,
  },
  {
    id: 'reported_by__email',
    label: 'Email',
    sortable: true,
  },
  {
    id: 'category',
    label: 'Category',
    sortable: true,
  },
  {
    id: 'status',
    label: 'Status',
    sortable: true,
  },
]

export default function ReportedHouse() {
  const [order, setOrder] = React.useState<Order>('desc')
  const [orderBy, setOrderBy] = React.useState<string>('id')
  const [page, setPage] = React.useState(1)
  const [search, setSearch] = React.useState<string>('')
  const [statusRef, setStatusRef] = React.useState<any>(null)
  const [statusValue, setStatusValue] = React.useState<string>('')
  const [categoryRef, setCategoryRef] = React.useState<any>(null)
  const [categoryValue, setcategoryValue] = React.useState<string>('')

  const navigate = useNavigate()

  const payload: paginationPayload = {
    page: page,
    per_page_value: perPageForListing,
    order_by_field: orderBy,
    order_by: order,
    search: search,
    status: statusValue,
    category: categoryValue,
  }

  const { isLoading, data }: any = useQuery({
    queryKey: ['reported_house', payload],
    queryFn: () => fetchReportedHouseRequests(payload),
    cacheTime: 0,
    retry: 0,
  })
  console.log(data, 'data')

  const DATA_LIST = data?.results || []
  const META_INFO = { total_pages: data?.total_pages || 0 }

  //
  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setStatusRef(event.currentTarget)
  }
  const handleCategoryClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setCategoryRef(event.currentTarget)
  }

  const handleFilterClose = () => {
    setStatusRef(null)
  }
  const handleCategoryClose = () => {
    setCategoryRef(null)
  }

  const onstatusValueChange = (value: string) => {
    setStatusValue(value)
    handleFilterClose()
  }
  const onCategoryValueChange = (value: string) => {
    console.log('Category Val  :' + value)
    setcategoryValue(value)
    handleCategoryClose()
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const navigateToDetailPage = (id: number) => {
    navigate(routeUrls.viewReportedHouseRequest.replace(':id', id.toString()))
  }

  interface RequestStatus {
    status: IReportedHouseRequestStatus
  }
  const RequestStatus = ({ status }: RequestStatus) => {
    if (status === 'Active') {
      return <Chip label={status} color='primary' />
    } else if (status === 'Pending') {
      return <Chip label={status} color='info' />
    } else {
      return <Chip label={status} color='success' />
    }
  }

  const exportMutation = useMutation(exportReportedHouseData, {
    onError: (error: any, variables, context) => {
      // An error happened!
      errorToast('Oops, unable to export!')
    },
    onSuccess: (data: any, variables, context) => {
      // Boom baby!
      console.log(data)

      // create "a" HTML element with href to file & click
      const link = document.createElement('a')
      link.href = `data:text/csv;charset=utf-8,${escape(data)}`
      link.setAttribute('download', 'reported-house.csv')
      document.body.appendChild(link)
      link.click()

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
    },
  })

  const handleExportData = () => {
    exportMutation.mutate()
  }
  return (
    <Box p={3}>
      <Box>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Typography variant='h5' color='primary' fontWeight='bold' marginBottom={5}>
              Reported House
            </Typography>

            <SearchInputBox
              placeholder='Looking for a User?'
              width='400px'
              onChangeWithDebounce={setSearch}
              sx={{ display: 'inline-block', marginRight: '20px' }}
            />

            <IconButton
              size='small'
              sx={{
                color: 'white',
                fontSize: '15px',
                padding: '10px',
              }}
              onClick={handleFilterClick}
            >
              <FilterIcon height={20} width={20} />
              Status
            </IconButton>

            <Popover
              open={!!statusRef}
              anchorEl={statusRef}
              onClose={handleFilterClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                {reportHouseStatusArr.map((item, index) => {
                  return (
                    <Typography
                      key={item}
                      sx={{ cursor: 'pointer' }}
                      className={`popoverMenuItem  ${
                        statusValue === item ? 'popoverMenuItem-active' : ''
                      } `}
                      onClick={() => onstatusValueChange(item)}
                    >
                      <span>{item}</span>
                    </Typography>
                  )
                })}
                <Typography
                  sx={{ cursor: 'pointer' }}
                  className={`popoverMenuItem  ${
                    statusValue === '' ? 'popoverMenuItem-active' : ''
                  } `}
                  onClick={() => onstatusValueChange('')}
                >
                  <span>All</span>
                </Typography>
              </Box>
            </Popover>

            <IconButton
              size='small'
              sx={{
                color: 'white',
                fontSize: '15px',
                padding: '10px',
              }}
              onClick={handleCategoryClick}
            >
              <FilterIcon height={20} width={20} />
              Category
            </IconButton>

            <Popover
              open={!!categoryRef}
              anchorEl={categoryRef}
              onClose={handleCategoryClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                {reportHouseCategoryArr.map((item, index) => {
                  return (
                    <Typography
                      key={item}
                      sx={{ cursor: 'pointer' }}
                      className={`popoverMenuItem  ${
                        categoryValue === item ? 'popoverMenuItem-active' : ''
                      } `}
                      onClick={() => onCategoryValueChange(item)}
                    >
                      <span>{item}</span>
                    </Typography>
                  )
                })}
                <Typography
                  sx={{ cursor: 'pointer' }}
                  className={`popoverMenuItem  ${
                    categoryValue === '' ? 'popoverMenuItem-active' : ''
                  } `}
                  onClick={() => onCategoryValueChange('')}
                >
                  <span>All</span>
                </Typography>
              </Box>
            </Popover>
          </Grid>

          <Grid item>
            {exportMutation.isLoading ? (
              <Grid container alignItems='center'>
                <Typography ml={1} variant='subtitle1' color='primary'>
                  Loading...
                </Typography>
              </Grid>
            ) : (
              <Grid
                container
                alignItems='center'
                onClick={handleExportData}
                sx={{ cursor: 'pointer' }}
              >
                <ExportIcon />
                <Typography ml={1} variant='subtitle1' color='primary'>
                  Export
                </Typography>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Box>

      <Paper sx={{ mb: 2, mt: 5, overflow: 'hidden', background: 'none' }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='small'>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={DATA_LIST.length}
              headCells={headCells}
            />
            <TableBody>
              <TableLoader isLoading={isLoading} colSpan={headCells.length} />
              {DATA_LIST.map((row: IReportedHouseRequest) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell align='left'>
                      <IconButton onClick={() => navigateToDetailPage(row.id)}>
                        <LinkIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align='left'>{row.id}</TableCell>
                    <TableCell align='left'>
                      {dayjs(row.created_at).format('MMM DD, YYYY hh:mm A')}
                    </TableCell>
                    <TableCell align='left'>{row.reported_by.name}</TableCell>
                    <TableCell align='left'>{row.reported_by.email}</TableCell>
                    <TableCell align='left'>{row.category}</TableCell>
                    <TableCell align='left'>
                      <RequestStatus status={row.status} />
                    </TableCell>
                  </TableRow>
                )
              })}
              <TableRow
                style={{
                  height: '80px',
                }}
              >
                <TableCell colSpan={headCells.length} />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Pagination count={META_INFO.total_pages} page={page} onChange={handlePageChange} />
    </Box>
  )
}
