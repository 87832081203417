import { createTheme } from '@mui/material/styles'

const theme = createTheme({
  palette: {
    // mode: 'dark',
    primary: {
      main: '#25D2EE',
      light: '#25D2EE',
      dark: '#25D2EE',
    },
    secondary: {
      main: '#343434',
      light: '#343434',
      dark: '#343434',
    },
    warning: {
      main: '#FEF15D',
      light: '#FEF15D',
      dark: '#FEF15D',
    },
    info: {
      main: '#fff',
      light: '#fff',
      dark: '#fff',
    },
  },
  typography: {
    fontFamily: 'Space Grotesk',
    fontSize: 16,
    h2: {
      fontSize: '48px',
    },
  },
  components: {
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: 'white',
          borderRadius: 16,
          position: 'relative',
          backgroundColor: 'transparent',
          border: '1px solid #EEEFF2',
          fontSize: 16,
          width: '100%',
          padding: '10px 12px',
        },
        disabled:{
          background:'red',
          color:'blue !important'
        }
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        // Name of the slot
        root: {
          // Some CSS
          color: 'white',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          border: 'none',
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          borderRadius: 16,
          paddingTop: 8,
          paddingBottom: 9,
        },
        icon: {
          color: 'white',
        },
        standard: {
          background: '#171717',
          border: 'none',
          borderRadius: '6px',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '32px',
          fontWeight: 700,
          textTransform: 'initial',
          height: '48px',
        },
        containedPrimary: {
          border: '1px solid #171717',
          boxShadow: '10px 10px 40px rgba(0, 0, 0, 0.4)',
        },
        containedSecondary: {
          border: '1px solid #616161',
          filter: 'drop-shadow(10px 10px 40px rgba(0, 0, 0, 0.4))',
          boxShadow: 'none',
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: '#343434',
          color: 'white',
          borderRadius: '16px',
          padding: '30px 20px 0px 20px',
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          backgroundColor: '#5f5f5f',
          color: 'white',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: '#616161',
        },
        
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          borderColor: '#616161',
        },
      },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: {
          background: '#343434',
          border: '1px solid #DFE0EB',
          borderRadius: '7px',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: '1px solid #616161',
          color: 'white',
        },
        head: {
          fontWeight: 'bold',
        },
      },
    },

    MuiTableSortLabel: {
      styleOverrides: {
        root: {
          color: 'white',
          ':hover': {
            color: 'white',
          },
          ':active': {
            color: 'white',
          },
          ':focus': {
            color: 'white',
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: 'white',
          '&[type="button"][aria-current="true"]': {
            color: '#fef15d',
          },
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          width: 'max-content',
          marginLeft: 'auto',
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          fontWeight: 'bold',
          background: '#343434',
          color: '#E5DA58',
        },
        colorPrimary: {
          background: '#25D2EE',
          color: 'black',
        },
        colorSecondary: {
          background: '#808080',
          color: 'black',
        },
        colorWarning: {
          color: 'black',
          background: '#FEF15D',
        },
        colorSuccess: {
          color: 'black',
          background: '#8DC881',
        },
        colorInfo: {
          color: 'black',
          background: '#FEF15D',
        },
        colorError: {
          color: 'black',
          background: '#FFBC78',
        },
        deleteIcon:{
          color:'white',
          ':hover':{
            color:'#E5DA58',
          }
        }
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: 'white',
          fontWeight: 'bold',
          fontSize: '14px',
          textTransform: 'none',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          background: '#343434',
          borderRadius: '15px',
        },
      },
    },

    // MuiPaper:{
    // Do not style Paper Component
    //   styleOverrides:{
    //     root:{
    //       background:'none'
    //     }
    //   }
    // }
  },
})

export default theme
