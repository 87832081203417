import axios from 'src/api/axios'

const ENDPOINT = 'api/v1/house/admin/management/house-rules/'

interface addHouseRulePayload{
  name: string
}

export function addHouseRule(payload: addHouseRulePayload) {
  return new Promise((resolve, reject) => {
    axios
      .post(ENDPOINT , payload)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function fetchHouseRules() {
  return new Promise((resolve, reject) => {
    axios
      .get(ENDPOINT)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

interface changeHouseRuleStatusPayload{
  id: number;
  flagged: boolean
}
export function changeHouseRuleStatus(payload: changeHouseRuleStatusPayload) {
  return new Promise((resolve, reject) => {
    axios
      .put(ENDPOINT + `${payload.id}/` , {
        flagged: payload.flagged
      })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
