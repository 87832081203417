/* eslint-disable camelcase */
/* eslint-disable no-prototype-builtins */
import React, { useEffect, useState } from 'react'
import { Chat, MessageList, MessageInput, MessagePayload } from '@pubnub/react-chat-components'
import MsgPreview from 'src/Components/Chat/MsgPreview'
import { headerHeight } from 'src/Layout/DashboardLayout'
import { Box, Paper } from '@mui/material'
import EastIcon from '@mui/icons-material/East'
import { usePubNub } from 'pubnub-react'
import { useMutation } from 'react-query'
import { sendChatPushNotification } from 'src/api/notification'
import { useParams } from 'react-router-dom'
import MessageInputBox from 'src/Components/Chat/MessageInputBox'
import { myPubnub } from 'src/utils/constant'

interface ChatComponentProps {
  currentChannel: string
  senderUser: any
  receiverUser: any
}
const ChatComponent = ({ currentChannel, receiverUser, senderUser }: ChatComponentProps) => {
  const pubnub = usePubNub()
  const params = useParams()
  const { id } = params

  const pushNotificationMutation = useMutation(sendChatPushNotification)

  const messageSentCallback = (msg: any) => {
    pubnub.hereNow(
      {
        channels: [currentChannel],
      },
      (status, response) => {
        if (response.totalOccupancy < 2) {
          // call push notification api
          pushNotificationMutation.mutate({
            data: {
              // eslint-disable-next-line camelcase
              notification_type: 'chat_request',
              id: id,
            },
            message: (() => {
              if (typeof msg.text === 'string') {
                return msg?.text?.length > 40 ? msg?.text?.substring(0, 40) + '...' : msg?.text
              }
              return 'A message from customer support.'
            })(),
            recipient: [receiverUser.id],
          })
        }
      },
    )
  }

  const onBeforeMessageSend = (msg: MessagePayload) => {
    console.log(msg)
    msg.channel = currentChannel
    msg.message = msg.text
    msg.sendByPost = false
    msg.meta = msg.sender
    return msg
  }

  // const onPresence = (e: any) => {
  //   console.log('onPresence')

  //   console.log(e)
  // }

  const onSendMessage = (message: string) => {
    myPubnub
      .publish({
        channel: currentChannel,
        message: message,
        meta: {
          user: {
            id: senderUser.id,
            email: senderUser.email,
            name: senderUser.name,
            first_name: senderUser.name,
            last_name: '',
            profile: true,
          },
        },
      })
      .then((res) => {
        console.log(res)
      })
  }

  const onFileMessage = (file: File) => {
    myPubnub
      .sendFile({
        channel: currentChannel,
        file: file,
        meta: {
          user: {
            id: senderUser.id,
            email: senderUser.email,
            name: senderUser.name,
            first_name: senderUser.name,
            last_name: '',
            profile: true,
          },
        },
      })
      .then((res) => {
        console.log(res)
      })
  }

  useEffect(() => {
    myPubnub.setUUID(senderUser?.id.toString() || '')
    return () => {
      pubnub.unsubscribe({
        channels: [currentChannel],
      })
    }
  }, [])

  return (
    <Chat
      theme='support-dark'
      currentChannel={currentChannel}
      enablePresence
      // onPresence={onPresence}
      users={[senderUser, receiverUser]}
    >
      <Paper
        style={{
          height: `calc(100vh - ${headerHeight * 3}px)`,
          overflow: 'auto',
          boxShadow: 'none',
          background: 'none',
        }}
      >
        <MessageList
          fetchMessages={25}
          messageRenderer={(props: any) => (
            <MsgPreview message={props.message} time={props.time} isMyMsg={props.isOwn} />
          )}
        />
      </Paper>
      {/* <MessageInput
        placeholder='Type your message here...'
        typingIndicator
        fileUpload='all'
        sendButton={
          <Box
            sx={{
              background: '#FEF15D',
              height: '50px',
              width: '50px',
              borderRadius: '100%',
              color: 'black',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <EastIcon />
          </Box>
        }
        onBeforeSend={onBeforeMessageSend}
        onSend={messageSentCallback}
        senderInfo={true}
      /> */}
      <MessageInputBox onSendMessage={onSendMessage} onSendFile={onFileMessage} />
    </Chat>
  )
}

export default ChatComponent
