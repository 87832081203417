import React, { useState } from 'react'
import { Box, Typography, Grid, CircularProgress, Checkbox, IconButton } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import CustomizedDialog from 'src/Components/CustomizedDialog'
import InputBoxWithIcon from '../Components/InputBoxWithIcon'
import { useMutation, useQuery } from 'react-query'
import { addHouseRule, changeHouseRuleStatus, fetchHouseRules } from 'src/api/management/houseRule'
import { errorToast, successToast } from 'src/utils/toastService'

interface Row {
  id: number
  name: string
  flagged: boolean
}

const HouseRules = () => {
  const [openAddModal, setOpenAddModal] = useState(false)
  const [name, setName] = useState('')

  const {
    isFetching,
    data = [],
    refetch,
  }: any = useQuery({
    queryFn: fetchHouseRules,
  })

  const handleInput = (e: any) => {
    setName(e.target.value)
  }

  const handleOpenAddModal = () => {
    setOpenAddModal(true)
  }

  const handleCloseAddModal = () => {
    setOpenAddModal(false)
  }

  const insertMutation = useMutation(addHouseRule, {
    onSuccess: (data) => {
      console.log(data)
      successToast('House Rule added successfully.')
      setName('')
      handleCloseAddModal()
      refetch()
    },
    onError: (error: any) => {
      if (error?.response?.data?.name) {
        error.response.data.name.forEach((msg: string) => {
          errorToast(msg)
        })
      } else {
        errorToast('Oops, Something went wrong.')
      }
    },
  })

  const statusChangeMutation = useMutation(changeHouseRuleStatus, {
    onSuccess: (data) => {
      console.log(data)
      successToast('House Rule status changed successfully.')
      refetch()
    },
    onError: (error: any) => {
      errorToast('Oops, Something went wrong.')
    },
  })

  const handleSubmit = () => {
    insertMutation.mutate({
      name: name,
    })
  }

  const handleStatusChange = (row: Row) => {
    statusChangeMutation.mutate({
      id: row.id,
      flagged: !row.flagged,
    })
  }
  return (
    <Box p={5}>
      <Box>
        {isFetching || statusChangeMutation.isLoading ? (
          <Grid container pl={5}>
            <CircularProgress />
          </Grid>
        ) : (
          data.map((row: Row) => {
            return (
              <Grid container key={row.id} alignItems='center'>
                <Checkbox
                  color='warning'
                  checked={row.flagged}
                  onChange={() => handleStatusChange(row)}
                />
                {row.name}
              </Grid>
            )
          })
        )}

        <Grid container alignItems='center' pl={4}>
          <AddIcon fontSize='small' color='primary' />
          <Typography
            variant='subtitle2'
            color='primary'
            className='cursorPointer'
            onClick={handleOpenAddModal}
          >
            <span>Add Another</span>
          </Typography>
        </Grid>
      </Box>

      <Box>
        <CustomizedDialog
          open={openAddModal}
          onClose={handleCloseAddModal}
          title='Add Rule'
          onSave={handleSubmit}
          maxWidth='xs'
          actionBtnPosition='center'
          saveBtnPosition='first'
          loading={insertMutation.isLoading}
          saveBtnText='+ Add'
        >
          <Box mb={5}>
            <Typography variant='subtitle2' component='p' color='white' mb={1} fontWeight='bold'>
              <label> Rule</label>
            </Typography>
            <InputBoxWithIcon value={name} onChange={handleInput} placeholder='Enter rule' />
          </Box>
        </CustomizedDialog>
      </Box>
    </Box>
  )
}

export default HouseRules
