import axios from 'src/api/axios'
import { paginationPayload } from './index.types'
import { Page } from 'src/typings/pagination.types'

const ENDPOINT = 'users/api/v1/admin/user-management/'

export function fetchUers(paginationPayload: paginationPayload) {
  return new Promise((resolve, reject) => {
    axios
      .post(ENDPOINT, paginationPayload)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

interface UpdateUserStatusPayload{
  userId: number | undefined;
  isActive:boolean
}

export function UpdateUserStatus({userId, isActive}:UpdateUserStatusPayload) {
  return new Promise((resolve, reject) => {
    axios
      .put(ENDPOINT + `${userId}/`, {
        // eslint-disable-next-line camelcase
        toggle_value: isActive,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}


export function fetchUserInfoById(id: number) {
  return new Promise((resolve, reject) => {
    axios
      .get(ENDPOINT + `${id}/`,)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
