/* eslint-disable camelcase */
import * as React from 'react'
import { Box, Typography, Grid, Popover, Pagination, Chip, IconButton } from '@mui/material'
import { useQuery } from 'react-query'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import EnhancedTableHead, { HeadCell, Order } from 'src/Components/Table/EnhancedTableHead'

import TableLoader from 'src/Components/Table/TableLoader'
import { houseRequestStatus, perPageForListing, routeUrls } from 'src/utils/constant'

import dayjs from 'dayjs'

import FilterIcon from 'src/Components/Icons/FilterIcon'

import {
  IHouseRequest,
  IHouseRequestStatus,
  paginationPayload,
} from 'src/api/houseRequest/index.types'
import { fetchHouseRequests } from 'src/api/houseRequest'
import LinkIcon from 'src/Components/Icons/LinkIcon'
import { useNavigate } from 'react-router-dom'
import SearchInputBox from 'src/Components/SearchInputBox'

const headCells: readonly HeadCell[] = [
  {
    id: 'link',
    label: '',
    sortable: false,
  },
  {
    id: 'id',
    label: '#',
    sortable: true,
  },
  {
    id: 'created',
    label: 'Date',
    sortable: true,
  },
  {
    id: 'house_name',
    label: 'House Name',
    sortable: true,
  },
  {
    id: 'house_mother_details',
    label: 'Email',
    sortable: false,
  },
  {
    id: 'address',
    label: 'Location',
    sortable: true,
  },
  {
    id: 'status',
    label: 'Status',
    sortable: true,
  },
]

export default function HouseRequests() {
  const [order, setOrder] = React.useState<Order>('desc')
  const [orderBy, setOrderBy] = React.useState<string>('id')
  const [page, setPage] = React.useState(1)
  const [filterRef, setFilterRef] = React.useState<any>(null)
  const [filterValue, setFilterValue] = React.useState<string>('all')
  const [search, setSearch] = React.useState<string>('')

  const navigate = useNavigate()

  const payload: paginationPayload = {
    page: page,
    per_page_value: perPageForListing,
    order_by_field: orderBy,
    order_by: order,
    status: filterValue,
    search: search,
  }

  const { isLoading, data }: any = useQuery({
    queryKey: ['house_requestes', payload],
    queryFn: () => fetchHouseRequests(payload),
    cacheTime: 0,
    retry: 0,
  })

  const DATA_LIST = data?.data || []
  const META_INFO = data?.pagination_meta || {}

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterRef(event.currentTarget)
  }

  const handleFilterClose = () => {
    setFilterRef(null)
  }

  const onFilterValueChange = (value: string) => {
    setFilterValue(value)
    handleFilterClose()
  }

  const navigateToDetailPage = (id: number) => {
    navigate(routeUrls.viewhouseRequest.replace(':id', id.toString()))
  }

  interface RequestStatusProps {
    status: IHouseRequestStatus
  }

  const RequestStatus = ({ status }: RequestStatusProps) => {
    if (status === houseRequestStatus.approved) {
      return <Chip label={status} color='primary' />
    } else if (status === houseRequestStatus.pending) {
      return <Chip label={status} color='warning' />
    } else if (status === houseRequestStatus.requested) {
      return <Chip label={status} color='error' />
    }

    return <Chip label='NA' color='default' />
  }
  return (
    <Box p={3}>
      <Box>
        <Typography variant='h5' color='primary' fontWeight='bold'>
          House Listing Request
        </Typography>

        <Grid pt={5} pb={5} container alignContent='center' justifyContent='space-between'>
          <Grid item>
            <SearchInputBox
              placeholder='Search house'
              width='400px'
              onChangeWithDebounce={setSearch}
              sx={{ display: 'inline-block', marginRight: '20px' }}
            />
          </Grid>
          <Grid item>
            <IconButton
              size='small'
              sx={{
                color: 'white',
                fontSize: '15px',
                padding: '10px',
              }}
              onClick={handleFilterClick}
            >
              <FilterIcon height={20} width={20} /> Filter
            </IconButton>
            <Popover
              open={!!filterRef}
              anchorEl={filterRef}
              onClose={handleFilterClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box p={2}>
                <Typography
                  sx={{ cursor: 'pointer' }}
                  className={`popoverMenuItem  ${
                    filterValue === houseRequestStatus.pending ? 'popoverMenuItem-active' : ''
                  } `}
                  onClick={() => onFilterValueChange(houseRequestStatus.pending)}
                >
                  <span>Pending Approval</span>
                </Typography>
                <Typography
                  sx={{ cursor: 'pointer' }}
                  className={`popoverMenuItem  ${
                    filterValue === houseRequestStatus.requested ? 'popoverMenuItem-active' : ''
                  } `}
                  onClick={() => onFilterValueChange(houseRequestStatus.requested)}
                >
                  <span>Requested Changes</span>
                </Typography>
                <Typography
                  sx={{ cursor: 'pointer' }}
                  className={`popoverMenuItem  ${
                    filterValue === houseRequestStatus.approved ? 'popoverMenuItem-active' : ''
                  } `}
                  onClick={() => onFilterValueChange(houseRequestStatus.approved)}
                >
                  <span>Approved</span>
                </Typography>
                <Typography
                  sx={{ cursor: 'pointer' }}
                  className={`popoverMenuItem  ${
                    filterValue === 'all' ? 'popoverMenuItem-active' : ''
                  } `}
                  onClick={() => onFilterValueChange('all')}
                >
                  <span>All</span>
                </Typography>
              </Box>
            </Popover>
          </Grid>
        </Grid>
      </Box>

      <Paper sx={{ mb: 2, overflow: 'hidden', background: 'none' }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='small'>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={DATA_LIST.length}
              headCells={headCells}
            />
            <TableBody>
              <TableLoader isLoading={isLoading} colSpan={headCells.length} />
              {DATA_LIST.map((row: IHouseRequest) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell align='left'>
                      <IconButton onClick={() => navigateToDetailPage(row.id)}>
                        <LinkIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell align='left'>{row.id}</TableCell>
                    <TableCell align='left'>
                      {dayjs(row.created).format('MMM DD, YYYY hh:mm A')}
                    </TableCell>
                    <TableCell align='left'>{row.house_name}</TableCell>
                    <TableCell align='left'>{row.house_mother_details.email}</TableCell>
                    <TableCell align='left'>{row.address}</TableCell>
                    <TableCell align='left'>
                      <RequestStatus status={row.status} />
                    </TableCell>
                  </TableRow>
                )
              })}
              <TableRow
                style={{
                  height: '80px',
                }}
              >
                <TableCell colSpan={headCells.length} />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <Pagination count={META_INFO.total_pages} page={page} onChange={handlePageChange} />
    </Box>
  )
}
