import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import Input from '@mui/material/Input'
import AddIcon from 'src/Components/Icons/AddIcon'
// Define a custom styled file input component
const StyledFileInput = styled('label')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
  //   width: '100%',
  cursor: 'pointer',
})

// Define the props interface for the styled file input component
interface StyledFileInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  multiple?: boolean
  label: any
  width?: string,
  fileType:string,
  disabled?: boolean
}

// Define the styled file input component
const SimpleFileUploadButton: React.FC<StyledFileInputProps> = ({
  onChange,
  multiple = false,
  label,
  width = '100%',
  fileType,
  disabled = false
}) => (
  <StyledFileInput
    style={{
      width,
    }}
  >
    <Input
      type='file'
      onChange={onChange}
      inputProps={{ accept: fileType, multiple }}
      sx={{ display: 'none' }}
      disabled={disabled}
    />
    {label}
  </StyledFileInput>
)

export default SimpleFileUploadButton
