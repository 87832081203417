'use client'
import React, { useEffect, useState } from 'react'
import GoogleMapReact from 'google-map-react'
import MapPointer from '../Icons/MapPointer'
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete'
import { ErrorBoundary } from 'react-error-boundary'

import Typography from '@mui/material/Typography'
import { GOOGLE_MAP_API_KEY } from 'src/utils/constant'
interface IProps {
  lat: number
  lng: number
}
const LocationMarker = (props: IProps) => {
  return (
    <div>
      <MapPointer width={100} height={100} />
    </div>
  )
}

interface SimpleMapProps {
  address?: string
  _lat?: number
  _lng?: number
  getDecodedLatLng ?: (lat: number, lng: number) => void
}

const OurFallbackComponent = ({ error, componentStack, resetErrorBoundary }: any) => {
  return (
    <div>
      <h1>An error occurred: {error.message}</h1>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

export default function SimpleMap(props: SimpleMapProps) {
  // new york as default lat, lng.
  const { address = '', _lat = 40.6841592, _lng = -73.9984338, getDecodedLatLng } = props

  if (_lat < -90 || _lat > 90 || _lng < -180 || _lng > 180) {
    return (
      <Typography variant='subtitle2' color='red' mt={1}>
        Unable to load map due to invalid latitude or longitude !
      </Typography>
    )
  }

  const defaultProps = {
    center: {
      lat: _lat,
      lng: _lng,
    },
    zoom: 17,
  }

  const [lat, setLat] = useState(defaultProps.center.lat)
  const [lng, setLng] = useState(defaultProps.center.lng)

  useEffect(() => {
    if (address) {
      geocodeByAddress(address)
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          // console.log('Successfully got latitude and longitude', { lat, lng })
          setLat(lat)
          setLng(lng)
          if(getDecodedLatLng){
            getDecodedLatLng(lat, lng)
          }
        })
    }
  }, [address])

  // console.log(lat, lng)

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '200px', width: '100%' }}>
      <ErrorBoundary FallbackComponent={OurFallbackComponent}>
        <GoogleMapReact
          bootstrapURLKeys={{ key: GOOGLE_MAP_API_KEY }}
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
          center={{ lat, lng }}
        >
          <LocationMarker lat={lat} lng={lng} />
        </GoogleMapReact>
      </ErrorBoundary>
    </div>
  )
}
