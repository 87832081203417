import axios from 'src/api/axios'
import { IHouseRequest, IHouseRequestStatus, paginationPayload } from './index.types'
import { Page, QueryResponse } from 'src/typings/pagination.types'

const ENDPOINT = 'api/v1/house/admin/house/'

export function fetchHouseRequests(paginationPayload: paginationPayload) {
  return new Promise((resolve, reject) => {
    axios
      .post<QueryResponse<Page<IHouseRequest>>>(ENDPOINT, paginationPayload)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function fetchHouseRequestById(id: number) {
  return new Promise((resolve, reject) => {
    axios
      .get(ENDPOINT + `${id}/`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

interface updateHouseRequestStatusPayload {
  id: number
  status: IHouseRequestStatus
}
export function updateHouseRequestStatus(payload: updateHouseRequestStatusPayload) {
  return new Promise((resolve, reject) => {
    axios
      .put(ENDPOINT + `${payload.id}/`, {
        status: payload.status,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
