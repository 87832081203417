import * as React from 'react'
import { Box, Tabs, Tab } from '@mui/material'

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  }
}

interface TabMenuProps {
  value: number
  onChange: (e: React.SyntheticEvent, value: number) => void
  menus: string[]
}

const TabMenu = (props: TabMenuProps) => {
  const { value, onChange, menus } = props
  return (
    <Box sx={{ borderBottom: 1, borderColor: '#616161' }}>
      <Tabs value={value} onChange={onChange} aria-label='tab component'>
        {menus.map((menu, i) => {
          return <Tab key={i} label={menu} {...a11yProps(i)} />
        })}
      </Tabs>
    </Box>
  )
}

export default TabMenu
