import { styled } from '@mui/material/styles'
const GradientBadge = styled('span')(({ theme }) => ({
  padding: '7px 9px',
  background: 'linear-gradient(91.11deg, #00C6E6 -6.64%, #FEF15D 115.96%)',
  borderRadius: '32px',
  fontWeight: 'bold',
  fontSize: '15px',
}))

export default GradientBadge
