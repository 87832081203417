/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react'
import { useMutation } from 'react-query'

import { styled } from '@mui/material/styles'
import { Box, Typography, Grid, MenuItem, Button, IconButton } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import CustomizedDialog from 'src/Components/CustomizedDialog'
import InputField from 'src/Components/InputField'
import Input from '@mui/material/Input'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { businessCategories } from 'src/utils/constant'

import styles from '../AddBusinessDirectoryModal/styles.module.css'
import SimpleMap from 'src/Components/SimpleMap'
import StyledFileInputComponent from 'src/Components/StyledFileInputComponent'
import BusinessHoursModal from '../BusinessHoursModal'
import {
  ITimeSlot,
  IbusinessHoursInitialState,
  IbusinessHoursInitialState_IDays,
  getBusinessHoursInitialState,
  isBusinessHourValid,
  isFormValid,
} from '../AddBusinessDirectoryModal/constant'
import { fetchBusinessById, updateBusiness } from 'src/api/business'
import { errorToast, successToast } from 'src/utils/toastService'
import dayjs from 'dayjs'
import BusinessHourPreview from '../AddBusinessDirectoryModal/BusinessHourPreview'
import AutoCompleteLocation, {
  GooglePlacesAutocomplete_LocationValue,
} from 'src/Components/AutoCompleteLocation'
import TagsInputBox from 'src/Components/TagsInputBox'

const Image = styled('img')`
  width: 100%;
  object-fit: cover;
  margin-bottom: ${(props) => props.theme.spacing(2)};
`
export interface EditBusinessDirectoryModalProps {
  open: boolean
  onClose: () => void
  id: number | undefined
}

interface IFormData {
  name: string
  category: string
  location: GooglePlacesAutocomplete_LocationValue | null
  phone: string
  description: string
  businessHours: IbusinessHoursInitialState
  existingPlacePhotos: any[]
  newPlacePhotos: any[]
  deletedPlacePhotos: any[]
  latitude?: number
  longitude?: number
  tags: string[]
}

const EditBusinessDirectoryModal = (props: EditBusinessDirectoryModalProps) => {
  const { open, onClose } = props
  const [openBusinessHourModal, setBusinessHourModal] = useState(false)
  const [form, setForm] = useState<IFormData>({
    name: '',
    category: '',
    location: null,
    phone: '',
    description: '',
    businessHours: getBusinessHoursInitialState(),
    existingPlacePhotos: [],
    newPlacePhotos: [],
    deletedPlacePhotos: [],
    tags: [],
  })

  const fetchBusinessMutation = useMutation(fetchBusinessById, {
    onSuccess: (data: any, variables, context) => {
      console.log(data.data.business_hours)
      if (data.success === true) {
        setForm({
          name: data.data.name,
          category: data.data.category,
          location: {
            label: data.data.location,
            value: {},
          },
          phone: data.data.phone,
          description: data.data.description,
          businessHours: {
            isOpenAllDays: data.data.is_open_all_day,
            days: data.data.business_hours.map((day: any): IbusinessHoursInitialState_IDays => {
              return {
                day: day.day,
                active: day.is_active,
                isEdited: false,
                id: day.id,
                timeSlot: (() => {
                  if (day.time_slot.length > 0) {
                    return day.time_slot.map((slot: any): ITimeSlot => {
                      return {
                        from: dayjs(slot.from_time, 'HH:mm:ss'),
                        to: dayjs(slot.to_time, 'HH:mm:ss'),
                      }
                    })
                  }

                  return [
                    {
                      from: null,
                      to: null,
                    },
                  ]
                })(),
              }
            }),
          },
          existingPlacePhotos: data.data.images || [],
          newPlacePhotos: [],
          deletedPlacePhotos: [],
          latitude: data.data.latitude,
          longitude: data.data.longitude,
          tags: data.data.tags,
        })
      }
    },
    onError: (error: any, variables, context) => {
      console.log(error)
    },
  })

  useEffect(() => {
    console.log(props)

    if (props.id && props.open) {
      fetchBusinessMutation.mutate(props.id)
    }
  }, [props])

  const onInputChange = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const setTags = (tags: string[]) => {
    if (tags.length > 5) {
      errorToast('Ensure tags has no more than 5 elements.')
      return
    }
    setForm({
      ...form,
      tags,
    })
  }

  const onLocationAddressChange = (location: any) => {
    console.log(location)
    setForm({
      ...form,
      location: location,
    })
  }

  const getDecodedLatLng = (lat: number, lng: number) => {
    setForm({
      ...form,
      latitude: lat,
      longitude: lng,
    })
  }

  const onFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setForm({
        ...form,
        newPlacePhotos: [...form.newPlacePhotos, ...Array.from(e.target.files)],
      })
      e.target.value = ''
    }
  }

  const removeNewPlacePhoto = (index: number) => {
    setForm({
      ...form,
      newPlacePhotos: form.newPlacePhotos.filter((file, i) => i !== index),
    })
  }

  const removeExistingPlacePhoto = (id: number) => {
    setForm({
      ...form,
      deletedPlacePhotos: [...form.deletedPlacePhotos, id],
      existingPlacePhotos: form.existingPlacePhotos.filter((image) => image.id !== id),
    })
  }

  const onBusinessHourModalOpen = () => {
    setBusinessHourModal(true)
  }

  const onBusinessHourModalClose = () => {
    setBusinessHourModal(false)
  }

  const onBusinessHourModalSave = (data: IbusinessHoursInitialState) => {
    console.log(data, 'data')
    if (!isBusinessHourValid(data)) return
    setForm({
      ...form,
      businessHours: data,
    })
    setBusinessHourModal(false)
  }

  const handleModalCloseEvent = () => {
    setForm({
      name: '',
      category: '',
      location: null,
      phone: '',
      description: '',
      businessHours: getBusinessHoursInitialState(),
      existingPlacePhotos: [],
      newPlacePhotos: [],
      deletedPlacePhotos: [],
      tags: [],
    })
    onClose()
  }

  const updateBusinessMutation = useMutation(updateBusiness, {
    onError: (error: any, variables, context) => {
      // An error happened!
      console.log(error, variables, context)

      try {
        if (error.response.data) {
          for (const key in error.response.data) {
            if (Object.prototype.hasOwnProperty.call(error.response.data, key)) {
              error.response.data[key].forEach((errorMessage: string) => {
                errorToast(errorMessage)
              })
            }
          }
        }
      } catch (err) {
        errorToast(error?.response?.data.message || 'Oops, Something went wrong!')
      }
    },
    onSuccess: (data: any, variables, context) => {
      // Boom baby!
      console.log(data, variables, context)
      if (data.data.success === true) {
        successToast('Business added successfully.')
        handleModalCloseEvent()
      }
    },
  })

  const onSubmit = () => {
    if (!isFormValid(form)) return

    const formData = new FormData()

    formData.append('name', form.name)
    formData.append('phone', form.phone)
    formData.append('category', form.category)
    formData.append('tags', JSON.stringify(form.tags))
    formData.append('location', form?.location?.label || '')
    if (form.description) {
      formData.append('description', form.description)
    }
    formData.append('latitude', form.latitude?.toString() || '')
    formData.append('longitude', form.longitude?.toString() || '')

    if (form.newPlacePhotos && form.newPlacePhotos.length) {
      for (let i = 0; i < form.newPlacePhotos.length; i++) {
        formData.append('images', form.newPlacePhotos[i])
      }
    }
    formData.append('delete_images', JSON.stringify(form.deletedPlacePhotos))
    formData.append('is_open_all_day', JSON.stringify(form.businessHours.isOpenAllDays))

    if (form.businessHours.isOpenAllDays) {
      const default_business_hour = form.businessHours.days.map((day) => {
        return {
          id: day.id,
          day: day.day,
          is_active: false,
          is_edited: false,
          time_slot: [],
        }
      })
      formData.append('business_hour', JSON.stringify(default_business_hour))
    } else {
      const business_hour = form.businessHours.days.map((day) => {
        return {
          id: day.id,
          day: day.day,
          is_active: day.active,
          is_edited: day.isEdited,
          time_slot: day.timeSlot
            .filter((slot) => !!(slot.from && slot.to))
            .map((slot) => {
              return {
                // eslint-disable-next-line camelcase
                from_time: slot.from?.format('HH:mm:ss'),
                // eslint-disable-next-line camelcase
                to_time: slot.to?.format('HH:mm:ss'),
              }
            }),
        }
      })
      formData.append('business_hour', JSON.stringify(business_hour))
    }

    updateBusinessMutation.mutate({
      payload: formData,
      id: props.id,
    })
  }

  return (
    <Box>
      <CustomizedDialog
        open={open}
        onClose={handleModalCloseEvent}
        onSave={onSubmit}
        title='Edit the information'
        maxWidth='lg'
        loading={fetchBusinessMutation.isLoading || updateBusinessMutation.isLoading}
      >
        <Grid container>
          <Grid item md={6} sm={12} xs={12} pl={2} pr={2} pt={2}>
            <InputField
              name='name'
              id='name'
              type='text'
              label='Name'
              placeholder='Name'
              value={form.name}
              onChange={onInputChange}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12} pl={2} pr={2} pt={2}>
            <Typography variant='subtitle2' component='p' color='white' mb={1} fontWeight='bold'>
              <label> Category</label>
            </Typography>
            <Select
              name='category'
              id='category'
              value={form.category}
              onChange={onInputChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              fullWidth
            >
              <MenuItem value=''>Select</MenuItem>
              {businessCategories.map((category, i) => {
                return (
                  <MenuItem key={i} value={category}>
                    {category}
                  </MenuItem>
                )
              })}
            </Select>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={6} sm={12} xs={12} pl={2} pr={2}>
            <Box pt={2}>
              <InputField
                name='phone'
                id='phone'
                type='text'
                regex={/[^0-9]*$/}
                label='Phone'
                placeholder='Type Phone Number'
                value={form.phone}
                onChange={onInputChange}
              />
            </Box>
            <Box pt={2}>
              <AutoCompleteLocation
                label='Location'
                value={form.location}
                onChange={onLocationAddressChange}
              />
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12} pl={2} pr={2} pt={2}>
            <TagsInputBox tags={form.tags} setTags={setTags} label='Tags' />
          </Grid>
        </Grid>

        <Grid item pl={2} pr={2} pt={2}>
          <SimpleMap address={form?.location?.label || ''} getDecodedLatLng={getDecodedLatLng} />
        </Grid>

        <Grid container>
          <Grid item md={6} sm={12} xs={12} pl={2} pr={2} pt={2}>
            <InputField
              multiline
              rows={5}
              id='description'
              name='description'
              type='text'
              label='Description'
              placeholder='Description'
              value={form.description}
              onChange={onInputChange}
            />
          </Grid>

          <Grid item md={6} sm={12} xs={12} pl={2} pr={2} pt={2}>
            <Typography variant='subtitle2' component='p' color='white' mb={1} fontWeight='bold'>
              <label> Business hours</label>
            </Typography>
            <Grid container className={styles.customContainer}>
              <BusinessHourPreview businessHours={form.businessHours} />
              <Grid item xs={12} container justifyContent='center' alignItems='center'>
                <Box className={styles.businessHourBtn} onClick={onBusinessHourModalOpen}>
                  Set business hours
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} pl={2} pr={2} pt={2}>
            <Typography variant='subtitle2' component='p' color='white' mb={1} fontWeight='bold'>
              <label> Place Photo</label>
            </Typography>
          </Grid>

          {form.existingPlacePhotos.map((image) => {
            return (
              <Grid
                item
                md={3}
                sm={12}
                xs={12}
                pl={2}
                pr={2}
                key={image.id}
                sx={{ position: 'relative' }}
              >
                <IconButton
                  aria-label='delete'
                  size='small'
                  sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '15px',
                    background: 'black',
                    color: 'yellow',
                  }}
                  onClick={() => removeExistingPlacePhoto(image.id)}
                >
                  <DeleteIcon fontSize='inherit' />
                </IconButton>
                <Image className={styles.ImageContainer} src={image.image} alt='image' />
              </Grid>
            )
          })}

          {form.newPlacePhotos.map((file, index) => {
            const image = URL.createObjectURL(file)
            return (
              <Grid
                item
                md={3}
                sm={12}
                xs={12}
                pl={2}
                pr={2}
                key={index}
                sx={{ position: 'relative' }}
              >
                <IconButton
                  aria-label='delete'
                  size='small'
                  sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '15px',
                    background: 'black',
                    color: 'yellow',
                  }}
                  onClick={() => removeNewPlacePhoto(index)}
                >
                  <DeleteIcon fontSize='inherit' />
                </IconButton>
                <Image className={styles.ImageContainer} src={image} alt='image' />
              </Grid>
            )
          })}

          <Grid item md={3} sm={12} xs={12} pl={2} pr={2}>
            <Grid container className={styles.ImageContainer}>
              <StyledFileInputComponent onChange={onFileInput} multiple />
            </Grid>
          </Grid>
        </Grid>

        <BusinessHoursModal
          open={openBusinessHourModal}
          onClose={onBusinessHourModalClose}
          onSave={onBusinessHourModalSave}
          data={form.businessHours}
        />
      </CustomizedDialog>
    </Box>
  )
}

export default EditBusinessDirectoryModal
