import React from 'react'
import { Box, Typography, CircularProgress } from '@mui/material'

import dayjs from 'dayjs'

import relativeTime from 'dayjs/plugin/relativeTime'
import NotificationRow from './NotificationRow'

dayjs.extend(relativeTime)

interface TodayYesterdayNotificationsProps {
  todayNotifications: any[]
  yesterdayNotifications: any[]
  isLoading: boolean
}
const TodayYesterdayNotifications = ({
  todayNotifications,
  yesterdayNotifications,
  isLoading,
}: TodayYesterdayNotificationsProps) => {
  if (isLoading) {
    return (
      <Box pt={3}>
        <Box textAlign='center'>
          {' '}
          <CircularProgress />{' '}
        </Box>
      </Box>
    )
  }
  return (
    <Box>
      <Box pt={3}>
        <Typography variant='subtitle1' color='white' fontWeight='bold'>
          Today
        </Typography>
        <Box>
          {todayNotifications.map((notification: any, index: number) => {
            return <NotificationRow key={index} notification={notification} />
          })}
          {todayNotifications.length === 0 && <Box textAlign='center'> No new notifications </Box>}
        </Box>
      </Box>

      <Box pt={3}>
        <Typography variant='subtitle1' color='white' fontWeight='bold'>
          Yesterday
        </Typography>
        <Box>
          <Box>
            {yesterdayNotifications.map((notification: any, index: number) => {
              return <NotificationRow key={index} notification={notification} />
            })}

            {yesterdayNotifications.length === 0 && (
              <Box textAlign='center'> No new notifications </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default TodayYesterdayNotifications
