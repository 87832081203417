/* eslint-disable @typescript-eslint/no-empty-interface */
/* eslint-disable camelcase */
import React, { useState } from 'react'
import { useMutation } from 'react-query'

import { styled } from '@mui/material/styles'
import { Box, Typography, Grid, MenuItem, IconButton, Chip } from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'

import CustomizedDialog from 'src/Components/CustomizedDialog'
import InputField from 'src/Components/InputField'
import Select from '@mui/material/Select'
import { businessCategories } from 'src/utils/constant'

import styles from './styles.module.css'
import SimpleMap from 'src/Components/SimpleMap'
import StyledFileInputComponent from 'src/Components/StyledFileInputComponent'
import BusinessHoursModal from '../BusinessHoursModal'
import {
  IbusinessHoursInitialState,
  getBusinessHoursInitialState,
  isBusinessHourValid,
  isFormValid,
} from './constant'
import { addBusiness } from 'src/api/business'
import { errorToast, successToast } from 'src/utils/toastService'
import BusinessHourPreview from './BusinessHourPreview'
import AutoCompleteLocation, {
  GooglePlacesAutocomplete_LocationValue,
} from 'src/Components/AutoCompleteLocation'
import TagsInputBox from 'src/Components/TagsInputBox'

const Image = styled('img')`
  width: 100%;
  object-fit: cover;
  margin-bottom: ${(props) => props.theme.spacing(2)};
`
export interface AddBusinessDirectoryModalProps {
  open: boolean
  onClose: () => void
}

interface IFormData {
  name: string
  category: string
  location: GooglePlacesAutocomplete_LocationValue | null
  phone: string
  description: string
  businessHours: IbusinessHoursInitialState
  placePhotos: any[]
  latitude?: number
  longitude?: number
  tags: string[]
}

const AddBusinessDirectoryModal = (props: AddBusinessDirectoryModalProps) => {
  const { open, onClose } = props
  const [openBusinessHourModal, setBusinessHourModal] = useState(false)
  const [form, setForm] = useState<IFormData>({
    name: '',
    category: '',
    location: null,
    phone: '',
    description: '',
    businessHours: getBusinessHoursInitialState(),
    placePhotos: [],
    tags: [],
  })

  const onInputChange = (e: any) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const setTags = (tags: string[]) => {
    if(tags.length > 5){
      errorToast('Ensure tags has no more than 5 elements.')
      return;
    }
    setForm({
      ...form,
      tags,
    })
  }

  const onLocationAddressChange = (location: any) => {
    // console.log(location)
    setForm({
      ...form,
      location: location,
    })
  }

  const getDecodedLatLng = (lat: number, lng: number) => {
    setForm({
      ...form,
      latitude: lat,
      longitude: lng,
    })
  }

  const onFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      setForm({
        ...form,
        placePhotos: [...form.placePhotos, ...Array.from(e.target.files)],
      })

      e.target.value = ''
    }
  }

  const removePlacePhoto = (index: number) => {
    setForm({
      ...form,
      placePhotos: form.placePhotos.filter((file, i) => i !== index),
    })
  }

  const onBusinessHourModalOpen = () => {
    setBusinessHourModal(true)
  }

  const onBusinessHourModalClose = () => {
    setBusinessHourModal(false)
  }

  const onBusinessHourModalSave = (data: IbusinessHoursInitialState) => {
    // console.log(data, 'data')
    if (!isBusinessHourValid(data)) return
    setForm({
      ...form,
      businessHours: data,
    })
    setBusinessHourModal(false)
  }

  const handleModalCloseEvent = () => {
    setForm({
      name: '',
      category: '',
      location: null,
      phone: '',
      description: '',
      businessHours: getBusinessHoursInitialState(),
      placePhotos: [],
      tags: [],
    })
    onClose()
  }

  const mutation = useMutation(addBusiness, {
    onError: (error: any, variables, context) => {
      // An error happened!
      console.log(error, variables, context)

      try {
        if (error.response.data) {
          for (const key in error.response.data) {
            if (Object.prototype.hasOwnProperty.call(error.response.data, key)) {
              error.response.data[key].forEach((errorMessage: string) => {
                errorToast(errorMessage)
              })
            }
          }
        }
      } catch (err) {
        errorToast(error?.response?.data.message || 'Oops, Something went wrong!')
      }
    },
    onSuccess: (data: any, variables, context) => {
      // Boom baby!
      console.log(data, variables, context)
      if (data.data.success === true) {
        successToast('Business added successfully.')
        handleModalCloseEvent()
      }
    },
  })

  const onSubmit = () => {
    if (!isFormValid(form)) return

    const formData = new FormData()

    formData.append('name', form.name)
    formData.append('phone', form.phone)
    formData.append('category', form.category)
    formData.append('tags', JSON.stringify(form.tags))
    formData.append('location', form?.location?.label || '')
    if (form.description) {
      formData.append('description', form.description)
    }
    formData.append('latitude', form.latitude?.toString() || '')
    formData.append('longitude', form.longitude?.toString() || '')

    if (form.placePhotos && form.placePhotos.length) {
      for (let i = 0; i < form.placePhotos.length; i++) {
        formData.append('images', form.placePhotos[i])
      }
    }

    formData.append('is_open_all_day', JSON.stringify(form.businessHours.isOpenAllDays))

    if (form.businessHours.isOpenAllDays) {
      const default_business_hour = form.businessHours.days.map((day) => {
        return {
          day: day.day,
          is_active: false,
          time_slot: [],
        }
      })
      formData.append('business_hour', JSON.stringify(default_business_hour))
    } else {
      const business_hour = form.businessHours.days.map((day) => {
        return {
          day: day.day,
          is_active: day.active,
          time_slot: day.timeSlot
            .filter((slot) => !!(slot.from && slot.to))
            .map((slot) => {
              return {
                // eslint-disable-next-line camelcase
                from_time: slot.from?.format('HH:mm:ss'),
                // eslint-disable-next-line camelcase
                to_time: slot.to?.format('HH:mm:ss'),
              }
            }),
        }
      })

      formData.append('business_hour', JSON.stringify(business_hour))
    }

    mutation.mutate(formData)
  }

  return (
    <Box>
      <CustomizedDialog
        open={open}
        onClose={handleModalCloseEvent}
        onSave={onSubmit}
        title='Add new place'
        maxWidth='lg'
        loading={mutation.isLoading}
      >
        <Grid container>
          <Grid item md={6} sm={12} xs={12} pl={2} pr={2} pt={2}>
            <InputField
              name='name'
              id='name'
              type='text'
              label='Name'
              placeholder='Name'
              value={form.name}
              onChange={onInputChange}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12} pl={2} pr={2} pt={2}>
            <Typography variant='subtitle2' component='p' color='white' mb={1} fontWeight='bold'>
              <label> Category</label>
            </Typography>
            <Select
              name='category'
              id='category'
              value={form.category}
              onChange={onInputChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              fullWidth
              variant='outlined'
            >
              <MenuItem value=''>Select</MenuItem>
              {businessCategories.map((category, i) => {
                return (
                  <MenuItem key={i} value={category}>
                    {category}
                  </MenuItem>
                )
              })}
            </Select>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item md={6} sm={12} xs={12} pl={2} pr={2}>
            <Box pt={2}>
              <InputField
                name='phone'
                id='phone'
                type='text'
                regex={/[^0-9]*$/}
                label='Phone'
                placeholder='Type Phone Number'
                value={form.phone}
                onChange={onInputChange}
              />
            </Box>

            <Box pt={2}>
              <AutoCompleteLocation
                label='Location'
                value={form.location}
                onChange={onLocationAddressChange}
              />
            </Box>
          </Grid>
          <Grid item md={6} sm={12} xs={12} pl={2} pr={2} pt={2}>
            <TagsInputBox tags={form.tags} setTags={setTags} label='Tags'/>
          </Grid>
        </Grid>

        <Grid item pl={2} pr={2} pt={2}>
          <SimpleMap address={form?.location?.label || ''} getDecodedLatLng={getDecodedLatLng} />
        </Grid>

        <Grid container>
          <Grid item md={6} sm={12} xs={12} pl={2} pr={2} pt={2}>
            <InputField
              multiline
              rows={5}
              id='description'
              name='description'
              type='text'
              label='Description'
              placeholder='Description'
              value={form.description}
              onChange={onInputChange}
            />
          </Grid>

          <Grid item md={6} sm={12} xs={12} pl={2} pr={2} pt={2}>
            <Typography variant='subtitle2' component='p' color='white' mb={1} fontWeight='bold'>
              <label> Business hours</label>
            </Typography>
            <Grid container className={styles.customContainer}>
              <BusinessHourPreview businessHours={form.businessHours} />

              <Grid item xs={12} container justifyContent='center' alignItems='center'>
                <Box className={styles.businessHourBtn} onClick={onBusinessHourModalOpen}>
                  Set business hours
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} pl={2} pr={2} pt={2}>
            <Typography variant='subtitle2' component='p' color='white' mb={1} fontWeight='bold'>
              <label> Place Photo</label>
            </Typography>
          </Grid>

          {form.placePhotos.map((file, index) => {
            const image = URL.createObjectURL(file)
            return (
              <Grid
                item
                md={3}
                sm={12}
                xs={12}
                pl={2}
                pr={2}
                key={index}
                sx={{ position: 'relative' }}
              >
                <IconButton
                  aria-label='delete'
                  size='small'
                  sx={{
                    position: 'absolute',
                    top: '10px',
                    right: '15px',
                    background: 'black',
                    color: 'yellow',
                  }}
                  onClick={() => removePlacePhoto(index)}
                >
                  <DeleteIcon fontSize='inherit' />
                </IconButton>
                <Image className={styles.ImageContainer} src={image} alt='image' />
              </Grid>
            )
          })}

          <Grid item md={3} sm={12} xs={12} pl={2} pr={2}>
            <Grid container className={styles.ImageContainer}>
              <StyledFileInputComponent onChange={onFileInput} multiple />
            </Grid>
          </Grid>
        </Grid>

        <BusinessHoursModal
          open={openBusinessHourModal}
          onClose={onBusinessHourModalClose}
          onSave={onBusinessHourModalSave}
          data={form.businessHours}
        />
      </CustomizedDialog>
    </Box>
  )
}

export default AddBusinessDirectoryModal
