/* eslint-disable camelcase */
import axios from 'src/api/axios'
import { createOrGetChannelPayload, updateLastSeenPayload } from './index.types'

export function createOrGetChannel(payload: createOrGetChannelPayload) {
  return new Promise((resolve, reject) => {
    axios
      .post('api/v1/chat/create-channel/', {
        category: 'support',
        users: [payload.fromUser, payload.toUser],
        module_id: payload.requestId,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function updateLastSeen(payload: updateLastSeenPayload) {
  return new Promise((resolve, reject) => {
    axios
      .post(`api/v1/chat/create-channel/${payload.channelId}/add_timestamp/`, {
        timestamp: payload.timestamp,
        user_id: payload.userId,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
