import React, { useState, useEffect } from 'react'
import {
  Box,
  Grid,
  Divider,
  IconButton,
  Typography,
  Avatar,
  Chip,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
  SelectChangeEvent,
} from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { useNavigate, useParams } from 'react-router-dom'
import TimerIcon from 'src/Components/Icons/TimerIcon'
import SimpleMap from 'src/Components/SimpleMap'
import { headerHeight } from 'src/Layout/DashboardLayout'
import { useMutation, useQuery } from 'react-query'
import { fetchHouseRequestById, updateHouseRequestStatus } from 'src/api/houseRequest'
import dayjs from 'dayjs'
import { IHouseRequestStatus } from 'src/api/houseRequest/index.types'
import { styled } from '@mui/material/styles'
import { errorToast, successToast } from 'src/utils/toastService'
import { houseRequestStatus } from 'src/utils/constant'

const Image = styled('img')`
  width: 100%;
  object-fit: cover;
  border: 1px solid white;
`

interface statusDropdownOption {
  label: string
  value: IHouseRequestStatus
}

const statusDropdownOptions: statusDropdownOption[] = [
  // { label: 'NA', value: '' },
  { label: 'Pending', value: houseRequestStatus.pending },
  { label: 'Requested Change', value: houseRequestStatus.requested },
  { label: 'Approved', value: houseRequestStatus.approved },
]

const ViewHouseRequest = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params

  const [changeStatus, setChangeStatus] = useState<IHouseRequestStatus>('Pending')

  const { isLoading, data, refetch }: any = useQuery({
    queryKey: ['houseDetails', id],
    queryFn: () => fetchHouseRequestById(Number(id)),
    cacheTime: 0,
    retry: 0,
  })

  const goBack = () => {
    navigate(-1)
  }

  console.log(data)

  interface RequestStatusProps {
    status: IHouseRequestStatus
  }

  const RequestStatus = ({ status }: RequestStatusProps) => {
    if (status === houseRequestStatus.approved) {
      return <Chip label={status} color='primary' />
    } else if (status === houseRequestStatus.pending) {
      return <Chip label={status} color='info' icon={<TimerIcon />} />
    } else if (status === houseRequestStatus.requested) {
      return <Chip label={status} color='error' />
    }

    return <Chip label='NA' color='default' />
  }

  useEffect(() => {
    if (data?.data?.status) {
      setChangeStatus(data.data.status)
    }
  }, [data])

  const updateMutation = useMutation(updateHouseRequestStatus, {
    onError: (error: any, variables, context) => {
      // An error happened!
      errorToast('Oops, Something went wrong!')
    },
    onSuccess: (data: any, variables, context) => {
      // Boom baby!
      successToast('House request status changed successfully.')
      refetch()
    },
  })

  const handleHouseRequestStatusChange = (event: any) => {
    setChangeStatus(event.target.value)
    if (data?.data && event.target.value) {
      updateMutation.mutate({
        id: data.data.id,
        status: event.target.value,
      })
    }
  }
  return (
    <Grid
      sx={{ minHeight: `calc(100vh - ${headerHeight}px)` }}
      container
      alignItems='stretch'
      p={0}
    >
      {isLoading ? (
        <Grid p={3} container minHeight={400} justifyContent='center' alignItems='center'>
          <Grid textAlign='center' item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : data && data.success === true && data.data ? (
        <>
          <Grid p={3} item xs={12} sm={12} lg={4} md={5}>
            <Box>
              <Box>
                <IconButton
                  onClick={goBack}
                  style={{ backgroundColor: 'transparent' }}
                  size='small'
                  color='info'
                >
                  <NavigateBeforeIcon color='info' />{' '}
                  <Typography variant='h5' component='span' color='primary' fontWeight='bold'>
                    Request #{data.data.id}
                  </Typography>
                </IconButton>
              </Box>

              <Box sx={{ flexGrow: 0 }} ml='auto' alignContent='center' alignItems='center' mt={5}>
                <IconButton sx={{ p: 0, mt: 1 }}>
                  <Avatar
                    alt={data.data.house_mother_details.name}
                    src={data.data.house_mother_details.profile_picture || 'errorimage.png'}
                  />
                  <Typography
                    variant='subtitle1'
                    component='span'
                    color='primary'
                    ml={1}
                    textAlign='left'
                    sx={{
                      wordWrap: 'break-word',
                      fontWeight: 'bold',
                      overflowWrap: 'anywhere',
                    }}
                  >
                    {data.data.house_mother_details.name}{' '}
                  </Typography>
                </IconButton>
              </Box>

              <Box mt={5}>
                <Box>
                  <Typography variant='subtitle2' color='white' fontWeight='bold'>
                    Email
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    color='white'
                    mt={1}
                    sx={{
                      wordWrap: 'break-word',
                    }}
                  >
                    {data.data.house_mother_details.email}
                  </Typography>
                </Box>

                <Box mt={3}>
                  <Typography variant='subtitle2' color='white' fontWeight='bold'>
                    User type
                  </Typography>
                  <Typography variant='subtitle2' color='white' mt={1}>
                    <Chip label={data.data.house_mother_details.role_name} color='default' />
                  </Typography>
                </Box>

                <Box mt={3}>
                  <Typography variant='subtitle2' color='white' fontWeight='bold'>
                    Date
                  </Typography>
                  <Typography variant='subtitle2' color='white' mt={1}>
                    {dayjs(data.data.created).format('MMM DD, YYYY hh:mm A')}
                  </Typography>
                </Box>

                <Box mt={3} pr={2}>
                  <Typography
                    variant='subtitle2'
                    component='p'
                    color='white'
                    mb={1}
                    fontWeight='bold'
                  >
                    <label> Request Status</label>
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      name='category'
                      id='category'
                      value={changeStatus}
                      onChange={handleHouseRequestStatusChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {statusDropdownOptions.map((option, i: number) => {
                        return (
                          <MenuItem key={i} value={option.value}>
                            {option.label}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Divider orientation='vertical' flexItem />
          <Grid p={3} item xs={12} sm={12} lg={7} md={6}>
            <Grid pt={5} container alignContent='center' justifyContent='flex-end'>
              <Grid item>
                <RequestStatus status={data.data.status} />
              </Grid>
            </Grid>
            <Grid container pl={2}>
              <Grid xs={12} sm={12} lg={6} md={12}>
                <Box mt={1}>
                  <Typography variant='subtitle1' color='primary' fontWeight='bold'>
                    House Name
                  </Typography>
                  <Typography variant='subtitle2' color='white' mt={1}>
                    {data.data.house_name}
                  </Typography>
                </Box>

                <Box mt={1}>
                  <Typography variant='subtitle1' color='primary' fontWeight='bold'>
                    Overview
                  </Typography>
                  <Typography variant='subtitle2' color='white' mt={1}>
                    {data.data.overview}
                  </Typography>
                </Box>

                <Box mt={3}>
                  <Typography variant='subtitle1' color='primary' fontWeight='bold'>
                    Amenities
                  </Typography>
                  {data.data.amenities &&
                    data.data.amenities.map((amenity: any) => {
                      return (
                        <Typography key={amenity.id} variant='subtitle2' color='white' mt={1}>
                          {amenity.name}
                        </Typography>
                      )
                    })}
                </Box>

                <Box mt={3}>
                  <Typography variant='subtitle1' color='primary' fontWeight='bold'>
                    What we like
                  </Typography>

                  <Box>
                    {data.data.likes &&
                      data.data.likes.map((like: any) => {
                        return (
                          <Chip
                            key={like.id}
                            label={like.name}
                            color='warning'
                            className='chip_rounded_border'
                            size='small'
                            sx={{ marginRight: '5px' }}
                          />
                        )
                      })}

                    {/* <Chip
                      label='Traveller'
                      className='chip_rounded_border simple_chip'
                      size='small'
                    /> */}
                  </Box>
                </Box>
              </Grid>
              <Grid xs={12} sm={12} lg={6} md={12}>
                <Box mt={1}>
                  <Typography variant='subtitle1' color='primary' fontWeight='bold'>
                    Photos
                  </Typography>
                  <Box>
                    <Grid container>
                      {data.data.house_images &&
                        data.data.house_images.map((image: any) => {
                          return (
                            <Grid xs={6} key={image.id} p={1}>
                              <Image
                                src={image.picture}
                                alt='houseImage'
                                width='100%'
                                className='roundedCorner'
                              />
                            </Grid>
                          )
                        })}
                    </Grid>
                  </Box>
                </Box>

                <Box mt={3}>
                  <Typography variant='subtitle1' color='primary' fontWeight='bold'>
                    Rules
                  </Typography>
                  {data.data.house_rules &&
                    data.data.house_rules.map((rule: any) => {
                      return (
                        <Typography key={rule.id} variant='subtitle2' color='white' mt={1}>
                          {rule.name}
                        </Typography>
                      )
                    })}
                </Box>

                <Box mt={3}>
                  <Typography variant='subtitle1' color='primary' fontWeight='bold'>
                    Location
                  </Typography>
                  <Typography variant='subtitle2' color='white' mt={1}>
                    {data.data.address}
                  </Typography>
                  <Box mt={1}>
                    <SimpleMap
                      _lat={Number(data.data.latitude)}
                      _lng={Number(data.data.longitude)}
                    />
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </>
      ) : (
        <Grid p={3} container minHeight={400} justifyContent='center' alignItems='center'>
          <Grid textAlign='center' item>
            Data not available.
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default ViewHouseRequest
