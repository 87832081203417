import React from 'react'
import { Typography, Card  } from '@mui/material'

import styles from './index.module.css'

interface DashboardInfoCardProps {
  label: string
  value: string | number
}

const DashboardInfoCard = (props: DashboardInfoCardProps) => {
  const { label, value } = props
  return (
   <Card>
     <div className={styles.card}>
      <Typography color='white' variant='subtitle1' fontWeight='bold'>
        {label}
      </Typography>

      <Typography sx={{
        color:'#FEF15D'
      }} variant='h5'  fontWeight='bold' >
        {value}
      </Typography>
    </div>
   </Card>
  )
}

export default DashboardInfoCard
