import axios from 'axios'
import { API_BASE_PATH } from 'src/utils/constant';


const ACCEPT_INVITE_ENDPOINT = '/api/v1/house/accept_invite/';

export interface AcceptInvitePayload {
  house_id: number;
  email: string;
}

export function acceptInvite(payload: AcceptInvitePayload) {
  return new Promise((resolve, reject) => {
    axios
      .post(API_BASE_PATH+ACCEPT_INVITE_ENDPOINT, payload)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        reject(err);
      });
  });
}
