import Axios from 'axios'
import { API_BASE_PATH, localStorageKeys } from 'src/utils/constant';

const axios = Axios.create({
  baseURL: API_BASE_PATH,

})

axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  config.headers.Authorization = 'Token ' + localStorage.getItem(localStorageKeys.accessToken)
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data
  return response;
}, function (error) {
  if(error?.response?.status === 401){
    // "Unauthorized"
    localStorage.removeItem(localStorageKeys.accessToken)
    localStorage.removeItem(localStorageKeys.user)
    window.location.assign('/')
  }
  
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});

export default axios
