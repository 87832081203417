import { Box, Grid, IconButton } from '@mui/material'
import React, { useState } from 'react'
import EastIcon from '@mui/icons-material/East'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'
import FileInput from './FileInput'

type MessageInputBoxProps = {
  onSendMessage: (message: string) => void
  onSendFile: (file: File) => void
}
const MessageInputBox = ({ onSendMessage, onSendFile }: MessageInputBoxProps) => {
  const [msg, setMsg] = useState<string>('')

  const handleSendMessage = () => {
    onSendMessage(msg)
    setMsg('')
  }

  return (
    <Grid container alignItems='center' justifyContent='center'>
      <Grid item p={1}>
        <FileInput
          onChange={(e) => {
            if (e.target.files?.[0]) {
              onSendFile(e?.target?.files?.[0])
            }
          }}
        />
      </Grid>

      <Grid item flexGrow={1} p={1}>
        <input
          style={{
            width: '100%',
            background: '#171717',
            border: '1px solid white',
            borderRadius: '25px',
            color: 'white',
            padding: '15px',
          }}
          placeholder='Type your message here...'
          value={msg}
          onChange={(e) => setMsg(e.target.value)}
        />
      </Grid>

      <Grid item p={1}>
        <IconButton
          onClick={handleSendMessage}
          sx={{
            background: '#FEF15D',
            height: '50px',
            width: '50px',
            borderRadius: '100%',
            color: 'black',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            ':hover': {
              background: '#FEF15D',
            },
          }}
        >
          <EastIcon />
        </IconButton>
      </Grid>
    </Grid>
  )
}

export default MessageInputBox
