import React, { useState, useEffect } from 'react'
import { Box, Typography, Grid } from '@mui/material'
import ArrowDown from 'src/Components/Icons/ArrowDown'
import { useMutation } from 'react-query'
import { fetchNotifications } from 'src/api/notification'
import InfiniteScroll from 'react-infinite-scroll-component'
import NotificationRow from './NotificationRow'

interface MoreNotificationsProps {
  onClose: () => void
}
const MoreNotifications = ({ onClose }: MoreNotificationsProps) => {
  const [page, setPage] = useState(1)
  const [allNotification, setAllNotifications] = useState<any>({
    hasMore: true,
    data: [],
  })

  const fetchNotificationsApi = useMutation(fetchNotifications, {
    onSuccess: (data: any, variables, context) => {
      console.log(data)

      setAllNotifications({
        hasMore: !!data.next,
        data: [...allNotification.data, ...data.results],
      })
    },
  })

  const nextPage = () => {
    console.log('nextPage')

    // fetchNotificationsApi.mutate(page + 1)
    setPage((prev) => prev + 1)
  }

  useEffect(() => {
    fetchNotificationsApi.mutate(page)
  }, [page])

  return (
    <Box>
      <Box pt={3}>
        <Grid container alignItems='center' sx={{ cursor: 'pointer' }}>
          <ArrowDown
            style={{
              transform: 'rotate(180deg)',
            }}
            height={10}
            width={12}
          />
          <Typography ml={1} variant='subtitle2' color='primary' onClick={onClose}>
            SEE LESS
          </Typography>
        </Grid>
      </Box>

      <Box>
        <InfiniteScroll
          dataLength={allNotification.data.length}
          next={nextPage}
          hasMore={allNotification.hasMore}
          loader={<h4>Loading...</h4>}
          height='600px'
          endMessage={
            <p style={{ textAlign: 'center' }}>
              <b>No more notifications</b>
            </p>
          }
        >
          {allNotification.data.map((notification: any, index: number) => {
            return <NotificationRow key={index} notification={notification} />
          })}
        </InfiniteScroll>
      </Box>
    </Box>
  )
}

export default MoreNotifications
