import React from 'react'
import { RouterProvider } from 'react-router-dom'
import { ThemeProvider } from '@mui/material/styles'
import { AuthProvider } from './Context/AuthContext'
import { QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify';

import router from './routes'
import theme from './theme'
import './App.css'
import './chat.css'
import queryClient from './api/query-client'
import 'react-toastify/dist/ReactToastify.css';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <RouterProvider router={router} />
          <ToastContainer />
        </AuthProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App
