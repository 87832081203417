import React from 'react'
import { Box } from '@mui/material'
import TabMenu from '../TabMenu'
import TabPanel from '../TabPanel'

interface IMenuItem {
  label: string
  component: JSX.Element
}

interface MyTabProps {
  menuItems: IMenuItem[]
  value: number
  onChange: (e: React.SyntheticEvent, value: number) => void
}

const MyTab = (props: MyTabProps) => {
  const { value, onChange, menuItems } = props

  const menus = menuItems.map((menu) => menu.label)
  return (
    <Box>
      <TabMenu menus={menus} value={value} onChange={onChange} />
      {menuItems.map((menu, i) => {
        return (
          <TabPanel key={i} value={value} index={i}>
            {menu.component}
          </TabPanel>
        )
      })}
    </Box>
  )
}

export default MyTab
