import React, { useContext } from 'react'
import AppBar from '@mui/material/AppBar'
import Box from '@mui/material/Box'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import MenuIcon from '@mui/icons-material/Menu'
import Typography from '@mui/material/Typography'
import Avatar from '@mui/material/Avatar'
import Icon from '@mui/material/Icon'
import BellIcon from 'src/assets/icons/Bell.png'
import { useQuery } from 'react-query'
import { fetchAccountInfo } from 'src/api/setting'
import { AuthContext } from 'src/Context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from 'src/utils/constant'

interface HeaderProps {
  drawerWidth: number
  headerHeight: number
  handleDrawerToggle: () => void
}
const Header = (props: HeaderProps) => {
  const navigate = useNavigate()
  const { drawerWidth, headerHeight, handleDrawerToggle } = props
  const context = useContext(AuthContext)
  const userId = Number(context.user?.id)
  const { isFetching, data }: any = useQuery({
    queryKey: ['headerInfo', userId],
    queryFn: () => fetchAccountInfo(userId),
    // cacheTime: 0,
    retry: 0,
  })

  const getFullname = () => {
    let firstName = 'First'
    let lastName = 'Last'
    if (data?.data) {
      firstName = data.data.first_name
      lastName = data.data.last_name
    }
    return firstName + ' ' + lastName
  }


  const navigateToNotificationPage = () => {
    navigate(routeUrls.notification)
  }
  return (
    <AppBar
      className='dashboardHeader'
      position='fixed'
      sx={{
        width: { md: `calc(100% - ${drawerWidth}px)` },
        ml: { md: `${drawerWidth}px` },
        height: headerHeight + 'px',
      }}
    >
      <Toolbar>
        <Box>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            edge='start'
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { md: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        {isFetching ? null : (
          <Box sx={{ flexGrow: 0 }} ml='auto' alignContent='center' alignItems='center'>
            <IconButton sx={{ p: 0 }}>
              <Avatar alt={getFullname()} src='/broken-image.jpg' />
              <Typography
                variant='subtitle2'
                component='p'
                fontSize='0.8rem'
                color='white'
                ml={1}
                textAlign='left'
              >
                <strong> {getFullname()}</strong>
                <br />
                Admin
              </Typography>
            </IconButton>
            <IconButton sx={{ p: 0, marginLeft: '35px' }} onClick={navigateToNotificationPage} >
              <Icon>
                {' '}
                <img src={BellIcon} height={25} width={25} />{' '}
              </Icon>
            </IconButton>
          </Box>
        )}
      </Toolbar>
    </AppBar>
  )
}

export default Header
