import React, { useState, useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Button, Box, CircularProgress } from '@mui/material'
import { Link, useNavigate } from 'react-router-dom'
import InputField from 'src/Components/InputField'
import TransparentBox from 'src/Components/TransparentBox'
import { AuthContext } from 'src/Context/AuthContext'

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'left',
  width: '348px',
  marginLeft: '20px',
  padding: '10px',
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

const Login = () => {
  const { login: loginApi } = useContext(AuthContext)
  const navigate = useNavigate()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState({
    username: '',
    password: '',
    message: '',
  })
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    if (!email) {
      setError({
        username: 'Email is required.',
        password: '',
        message: '',
      })
      return
    }

    if (!password) {
      setError({
        password: 'Password is required.',
        username: '',
        message: '',
      })
      return
    }
    setLoading(true)
    setError({
      username: '',
      password: '',
      message: '',
    })
    loginApi(email, password)
      .then((res) => {
        console.log('sucess', res)

        navigate('/dashboard')
      })
      .catch((err) => {
        console.log('err', err)
        setError({
          username: '',
          password: '',
          message: err.response.data.message,
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <TransparentBox>
      <Typography
        variant='h2'
        component='h2'
        color='primary'
        textAlign='center'
        mt={7}
        mb={2}
        fontWeight='bold'
      >
        Welcome to HousMthr!
      </Typography>
      <Box alignContent='center' alignItems='center'>
        <Form noValidate autoComplete='off' color='secondary' onSubmit={handleSubmit}>
          <Box>
            <InputField
              name='email'
              id='email'
              type='text'
              label='Email'
              placeholder='Email'
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              required={true}
            />
          </Box>

          <Typography variant='subtitle2' component='p' color='red' textAlign='center'>
            <strong> {error.username} </strong>
          </Typography>

          <Box mt={2}>
            <InputField
              name='password'
              id='password'
              type='password'
              label='Password'
              placeholder='*****'
              value={password}
              onChange={(e: any) => setPassword(e.target.value)}
              required
            />
          </Box>

          <Typography variant='subtitle2' component='p' color='red' textAlign='center'>
            <strong> {error.password || error.message} </strong>
          </Typography>

          <Typography variant='subtitle2' component='p' color='primary' mt={2}>
            <strong>
              {' '}
              <Link className='routerLink' to='/forgot-password'>
                Forgot Password?
              </Link>{' '}
            </strong>
          </Typography>
          <Button
            variant='contained'
            color='primary'
            type='submit'
            sx={{
              marginTop: '20px',
            }}
            disabled={loading}
          >
            {loading ? <CircularProgress /> : 'Log in'}
          </Button>
          <Typography display={'flex'} alignItems='center' mt={1} ml={1}>
            <Typography variant='caption' component='p'>
              If require any assistance, please{' '}
            </Typography>
            <Typography color='primary' variant='caption' ml={1}>
              <Link className='routerLink' to='/contact-us'>
                {' '}
                Contact Us
              </Link>
            </Typography>
          </Typography>
        </Form>
      </Box>
    </TransparentBox>
  )
}

export default Login
