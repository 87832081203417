import * as React from 'react'
import { styled } from '@mui/material/styles'
import { Typography } from '@mui/material'
import Input from '@mui/material/Input'
import AddIcon from 'src/Components/Icons/AddIcon'
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined'

// Define a custom styled file input component
const StyledFileInput = styled('label')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  cursor: 'pointer',
  color: '#FEF15D',
})

// Define the props interface for the styled file input component
interface FileInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  multiple?: boolean
}

// Define the styled file input component
const FileInput: React.FC<FileInputProps> = ({ onChange, multiple = false }) => (
  <StyledFileInput>
    <InsertDriveFileOutlinedIcon />
    <Input
      type='file'
      onChange={onChange}
      inputProps={{ accept: '*', multiple }}
      sx={{ display: 'none' }}
    />
  </StyledFileInput>
)

export default FileInput
