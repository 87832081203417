import React from 'react'
import locationIcon from 'src/assets/icons/location.png'

interface IAddIcon {
  width?: number
  height?: number
  style?: object
  id?: string
  className?: any
}

const MapPointer = (props: IAddIcon) => {
  const { width = 25, height = 25, style = {}, id, className } = props
  return (
    <img
      src={locationIcon}
      width={width}
      height={height}
      style={style}
      id={id}
      className={className}
    />
  )
}

export default MapPointer
