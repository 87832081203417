import axios from 'src/api/axios'
import { DashboardStatsQueryResponse } from './index.types'

const ENDPOINT = 'admin_dashboard/api/v1/dashboard_count/'

export function fetchDashboardStats() {
  return new Promise((resolve, reject) => {
    axios
      .get<DashboardStatsQueryResponse>(ENDPOINT)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
