import { Dayjs } from 'dayjs'
import { errorToast } from 'src/utils/toastService'
export interface ITimeSlot {
  from: null | Dayjs
  to: null | Dayjs
}

export interface IbusinessHoursInitialState_IDays {
  id?: number
  active: boolean
  isEdited?: boolean
  day: string
  timeSlot: ITimeSlot[]
}

export interface IbusinessHoursInitialState {
  isOpenAllDays: boolean
  days: IbusinessHoursInitialState_IDays[]
}

export const getBusinessHoursInitialState = (): IbusinessHoursInitialState => {
  return {
    isOpenAllDays: false,
    days: [
      {
        active: false,
        isEdited: false,
        day: 'Sunday',
        timeSlot: [
          {
            from: null,
            to: null,
          },
        ],
      },
      {
        day: 'Monday',
        active: false,
        isEdited: false,
        timeSlot: [
          {
            from: null,
            to: null,
          },
        ],
      },
      {
        day: 'Tuesday',
        active: false,
        isEdited: false,
        timeSlot: [
          {
            from: null,
            to: null,
          },
        ],
      },
      {
        day: 'Wednesday',
        active: false,
        isEdited: false,
        timeSlot: [
          {
            from: null,
            to: null,
          },
        ],
      },
      {
        day: 'Thursday',
        active: false,
        isEdited: false,
        timeSlot: [
          {
            from: null,
            to: null,
          },
        ],
      },
      {
        day: 'Friday',
        active: false,
        isEdited: false,
        timeSlot: [
          {
            from: null,
            to: null,
          },
        ],
      },
      {
        day: 'Saturday',
        active: false,
        isEdited: false,
        timeSlot: [
          {
            from: null,
            to: null,
          },
        ],
      },
    ],
  }
}

export const isBusinessHourValid = (businessHours: IbusinessHoursInitialState) => {
  if (businessHours.isOpenAllDays === false) {
    let isAnyDayActive = false

    for (let i = 0; i < businessHours.days.length; i++) {
      const day = businessHours.days[i]
      if (day.active) {
        isAnyDayActive = true
        for (let j = 0; j < day.timeSlot.length; j++) {
          const slot = day.timeSlot[j]
          if (!slot.from) {
            errorToast('From time is required.')
            return false
          }
          if (!slot.to) {
            errorToast('To time is required.')
            return false
          }
          if (slot.to.diff(slot.from) <= 0) {
            errorToast('From time should be smaller than To time.')
            return false
          }
        }
      }
    }

    // if (!isAnyDayActive) {
    //   errorToast('Business hours are required.')
    //   return false
    // }
  }

  return true
}

export const isFormValid = (form: any) => {
  if (!form.name) {
    errorToast('Name field is required.')
    return false
  }

  if (!form.category) {
    errorToast('Category field is required.')
    return false
  }

  if (!form.location) {
    errorToast('Location field is required.')
    return false
  }

  if (!form.phone) {
    errorToast('Phone field is required.')
    return false
  }

  // if (!form.description) {
  //   errorToast('Description field is required.')
  //   return false
  // }

  // if ( typeof form.placePhotos != 'undefined' && form.placePhotos.length === 0) {
  //   errorToast('Place photo is required.')
  //   return false
  // }

  if (!isBusinessHourValid(form.businessHours)) return false

  return true
}
