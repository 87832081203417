import React from 'react'
import filter from 'src/assets/icons/filter.png'

interface IconProp {
  width?: number
  height?: number
  style?: object
  id?: string
  className?: any
}

const FilterIcon = (props: IconProp) => {
  const { width = 25, height = 25, style = {}, id, className } = props
  return (
    <img src={filter} width={width} height={height} style={style} id={id} className={className} />
  )
}

export default FilterIcon
