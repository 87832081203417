import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, Box } from '@mui/material'
import { debounce } from 'lodash'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  backgroundColor: '#343434',
  borderRadius: '15px',
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: 0,
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)})`,

    border: 'none',
  },
  width: '95%',
}))

interface SearchInputBoxProps {
  width?: number | string
  sx?: any
  placeholder: string
  onChangeWithDebounce: (value: string) => void
}

const SearchInputBox = (props: SearchInputBoxProps) => {
  const { width = '100%', sx = {}, placeholder, onChangeWithDebounce } = props

  const handleSearch = debounce((e: any) => {
    onChangeWithDebounce(e.target.value)
  }, 1000)

  return (
    <Box width={width} sx={sx}>
      <Search>
        <SearchIconWrapper>
          <IconButton
            sx={{
              background: '#171717',
              boxShadow: '4px 4px 20px rgba(9, 9, 9, 0.5)',
              color: 'yellow',
              height: '35px',
              width: '35px',
            }}
          >
            <SearchIcon sx={{ height: '20px' }} />
          </IconButton>
        </SearchIconWrapper>
        <StyledInputBase
          placeholder={placeholder}
          inputProps={{ 'aria-label': 'search' }}
          onChange={handleSearch}
        />
      </Search>
    </Box>
  )
}

export default SearchInputBox
