/* eslint-disable camelcase */
import axios from 'src/api/axios'
import {
  IReportedHouseRequest,
  IReportedHouseRequestStatus,
  paginationPayload,
} from './index.types'
import { Page, QueryResponse } from 'src/typings/pagination.types'

const ENDPOINT = 'api/v1/help/'

export function fetchReportedHouseRequests(paginationPayload: paginationPayload) {
  return new Promise((resolve, reject) => {
    axios
      .get<QueryResponse<Page<IReportedHouseRequest>>>(ENDPOINT + 'report-house/', {
        params: paginationPayload,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function fetchReportedHouseById(id: number) {
  return new Promise((resolve, reject) => {
    axios
      .get(ENDPOINT + `report-house/${id}/`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function exportReportedHouseData() {
  return new Promise((resolve, reject) => {
    axios
      .get(ENDPOINT + 'report-house/csv/')
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

interface updateStatusPayload {
  id: number
  status: IReportedHouseRequestStatus
}

export function updateReportedHouseStatus(payload: updateStatusPayload) {
  return new Promise((resolve, reject) => {
    axios
      .patch(ENDPOINT + `report-house/${payload.id}/`, {
        status: payload.status,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
