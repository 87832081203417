import { createContext, useState } from 'react';
import axios from 'axios';
import { API_BASE_PATH, localStorageKeys } from 'src/utils/constant';

interface IUser {
    id: number;
    name: string;
    email: string;
}

interface IAuthContext {
    user: IUser | null;
    accessToken: string | null;
    isAuthenticated: boolean,
    login: (username: string, password: string) => Promise<unknown>;
    logout: () => Promise<unknown>;
    generateOTPforForgotPassword: (email: string) => Promise<unknown>;
    verifyForgotPasswordOTP: (email: string, otp: string) => Promise<unknown>;
    resetPassword: (password: string, confirmPassword: string, uid: string, token: string) => Promise<unknown>;
    contactUs: (name: string, email: string, message: string) => Promise<unknown>;
}


const AuthContext = createContext<IAuthContext>({
    user: null,
    accessToken: null,
    isAuthenticated: false,
    login: () => Promise.resolve(),
    logout: () => Promise.resolve(),
    generateOTPforForgotPassword: () => Promise.resolve(),
    verifyForgotPasswordOTP: () => Promise.resolve(),
    resetPassword: () => Promise.resolve(),
    contactUs: () => Promise.resolve(),
});

function AuthProvider(props: { children: React.ReactNode }) {
    const [user, setUser] = useState<IUser | null>((() => {
        const user = localStorage.getItem(localStorageKeys.user) || null
        if (user)
            return JSON.parse(user)
        return null
    })());
    const [accessToken, setAccessToken] = useState<string | null>(localStorage.getItem(localStorageKeys.accessToken) || null);

    const login = (username: string, password: string) => {

        return new Promise((resolve, reject) => {
            axios
                .post(API_BASE_PATH + 'api/v1/login/admin/', {
                    username,
                    password,
                })
                .then((response) => {
                    // console.log('response', response);

                    const { user, token } = response.data;
                    setUser(user);
                    setAccessToken(token);
                    localStorage.setItem(localStorageKeys.accessToken, token);
                    localStorage.setItem(localStorageKeys.user, JSON.stringify(user));
                    resolve(response);
                })
                .catch((error) => {
                    console.error(error);
                    reject(error)
                });
        })
    };

    const logout = () => {
        return new Promise((resolve, reject) => {
            return axios
            .post(API_BASE_PATH + 'rest-auth/logout/',{},{
                headers:{
                    Authorization: 'Token ' + localStorage.getItem(localStorageKeys.accessToken),
                }
            })
            .then((res) => {
                setUser(null);
                setAccessToken(null);
                localStorage.removeItem(localStorageKeys.accessToken);
                localStorage.removeItem(localStorageKeys.user);
                resolve(res)
            })
            .catch((error) => {
                console.error(error);
                reject(error)
            });
        })
        
    };

    const generateOTPforForgotPassword = (email: string) => {

        return new Promise((resolve, reject) => {
            axios
                .post(API_BASE_PATH + 'api/v1/forgot-password/admin/', {
                    email,
                })
                .then((response) => {

                    resolve(response);
                })
                .catch((error) => {
                    reject(error)
                });
        })
    };

    const verifyForgotPasswordOTP = (email: string, otp: string) => {

        return new Promise((resolve, reject) => {
            axios
                .post(API_BASE_PATH + 'api/v1/forgot-password-verify/', {
                    email,
                    otp
                })
                .then((response) => {

                    resolve(response);
                })
                .catch((error) => {
                    reject(error)
                });
        })
    };

    const resetPassword = (password: string, confirmPassword: string, uid: string, token: string) => {

        return new Promise((resolve, reject) => {
            axios
                .post(API_BASE_PATH + 'api/v1/reset-password/', {
                    'new_password1': password,
                    'new_password2': confirmPassword,
                    'uid': uid,
                    'token': token
                })
                .then((response) => {

                    resolve(response);
                })
                .catch((error) => {
                    reject(error)
                });
        })
    };

    const contactUs = (name: string, email: string, message: string) => {
        return new Promise((resolve, reject) => {
          axios
            .post(API_BASE_PATH + 'api/v1/contact-us/', {
              name,
              email,
              message,
            })
            .then((response) => {
              resolve(response)
            })
            .catch((error) => {
              reject(error)
            })
        })
      };

    const contextValue = {
        user,
        accessToken,
        isAuthenticated: !!(accessToken && user),
        login,
        logout,
        generateOTPforForgotPassword,
        verifyForgotPasswordOTP,
        resetPassword,
        contactUs,
    };

    return <AuthContext.Provider value={contextValue} {...props} />;
}

export { AuthContext, AuthProvider };
