import React, { useState } from 'react'

import { Box, Typography, Grid, Divider } from '@mui/material'
import ArrowDown from 'src/Components/Icons/ArrowDown'
import { useQuery } from 'react-query'
import { fetchNotifications } from 'src/api/notification'

import dayjs from 'dayjs'
import isToday from 'dayjs/plugin/isToday'
import isYesterday from 'dayjs/plugin/isYesterday'
import TodayYesterdayNotifications from './TodayYesterdayNotifications'
import MoreNotifications from './MoreNotifications'

dayjs.extend(isToday)
dayjs.extend(isYesterday)

const Notification = () => {
  const [showMore, setShowMore] = useState(false)

  const { isFetching: isLastesNotificationFetching, data: latestNotifications = {} }: any =
    useQuery({
      queryKey: ['latest_notifications'],
      queryFn: () => fetchNotifications(1),
      cacheTime: 0,
      retry: 0,
    })

  let todayNotifications = [],
    yesterdayNotifications = []

  if (latestNotifications?.results?.length > 0) {
    todayNotifications = latestNotifications.results.filter((notification: any) => {
      const date = dayjs(notification.created_at)
      return date.isToday()
    })
  }

  if (latestNotifications?.results?.length > 0) {
    yesterdayNotifications = latestNotifications.results.filter((notification: any) => {
      const date = dayjs(notification.created_at)
      return date.isYesterday()
    })
  }

  return (
    <Box p={3}>
      <Typography variant='h5' color='primary' fontWeight='bold' mb={7}>
        Notification
      </Typography>

      <Divider />

      {showMore ? (
        <MoreNotifications onClose={() => setShowMore(false)} />
      ) : (
        <Box>
          <TodayYesterdayNotifications
            todayNotifications={todayNotifications}
            yesterdayNotifications={yesterdayNotifications}
            isLoading={isLastesNotificationFetching}
          />

          <Box pt={3}>
            <Grid container alignItems='center' sx={{ cursor: 'pointer' }}>
              <ArrowDown height={10} width={12} />
              <Typography
                ml={1}
                variant='subtitle2'
                color='primary'
                onClick={() => setShowMore(true)}
              >
                SEE MORE
              </Typography>
            </Grid>
          </Box>
        </Box>
      )}
    </Box>
  )
}

export default Notification
