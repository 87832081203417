import React, { useState, useEffect } from 'react'
import CustomizedDialog from 'src/Components/CustomizedDialog'
import {
  Checkbox,
  FormControlLabel,
  Typography,
  Divider,
  Grid,
  Box,
  IconButton,
} from '@mui/material'
import AppleSwitchBtn from 'src/Components/AppleSwitchBtn'
import BasicTimePicker from 'src/Components/BasicTimePicker'

import styles from './styles.module.css'
import ClearIcon from '@mui/icons-material/Clear'
import AddIcon from '@mui/icons-material/Add'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import {
  ITimeSlot,
  IbusinessHoursInitialState,
  // eslint-disable-next-line camelcase
  IbusinessHoursInitialState_IDays,
} from '../AddBusinessDirectoryModal/constant'

import { Dayjs } from 'dayjs'

export interface BusinessHoursModalProps {
  open: boolean
  data: IbusinessHoursInitialState
  onClose: () => void
  onSave: (data: IbusinessHoursInitialState) => void
}

const BusinessHoursModal = (props: BusinessHoursModalProps) => {
  const { open, onClose, onSave, data } = props

  const [isOpenAllDays, setIsOpenAllDays] = useState<boolean>(data.isOpenAllDays)

  // eslint-disable-next-line camelcase
  const [days, setDays] = useState<IbusinessHoursInitialState_IDays[]>(data.days)

  useEffect(() => {
    setIsOpenAllDays(data.isOpenAllDays)
    setDays(data.days)
  },[data])

  const handleIsOpenChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsOpenAllDays(event.target.checked)

    // set all days to in-active if bussiness is either closed or open for 24/7;
    toggleOffAllDays()
  }

  const toggleDay = (dayIndex: number, e: React.ChangeEvent<HTMLInputElement>) => {
    setDays((prevState) => {
      const _days = [...prevState]
      _days[dayIndex].active = !!e.target.checked
      _days[dayIndex].isEdited = true
      return _days
    })

    // set  "open 24/7"  to false if user enable any day.
    setIsOpenAllDays(false)
  }

  const addMoreTimeSlot = (dayIndex: number) => {
    setDays((prevState) => {
      const _days = [...prevState]
      _days[dayIndex].timeSlot.push({
        from: null,
        to: null,
      })
      _days[dayIndex].isEdited = true
      return _days
    })
  }

  function toggleOffAllDays() {
    setDays((prevState) => {
      const _days = [...prevState]
      return _days.map((day) => {
        day.active = false
        return day
      })
    })
  }

  const handleTimeChange = (
    dayIndex: number,
    timeSlotIndex: number,
    type: 'from' | 'to',
    value: Dayjs | null,
  ) => {
    setDays((prevState) => {
      const _days = [...prevState]
      _days[dayIndex].timeSlot[timeSlotIndex][type] = value
      _days[dayIndex].isEdited = true
      return _days
    })
  }

  const removeTimeSlot = (dayIndex: number, timeSlotIndex: number) => {
    setDays((prevState) => {
      const _days = [...prevState]
      _days[dayIndex].timeSlot = _days[dayIndex].timeSlot.filter((time, index) => {
        return index !== timeSlotIndex
      })
      _days[dayIndex].isEdited = true
      return _days
    })
  }

  const handleSaveEvent = () => {
    const dataObj: IbusinessHoursInitialState = {
      isOpenAllDays: isOpenAllDays,
      days: days,
    }

    onSave(dataObj)
  }

  return (
    <CustomizedDialog
      open={open}
      onClose={onClose}
      onSave={handleSaveEvent}
      title='Set business hours'
      maxWidth='md'
      actionBtnWidth='200px'
      actionBtnPosition='center'
    >
      <FormControlLabel
        control={<Checkbox checked={isOpenAllDays} onChange={handleIsOpenChange} color='warning' />}
        label={
          <Typography variant='subtitle2' component='span'>
            Open 24/7
          </Typography>
        }
      />

      <Box>
        {
          // eslint-disable-next-line camelcase
          days.map((day: IbusinessHoursInitialState_IDays, dayIndex) => {
            return (
              <>
                <Divider />
                <Grid key={dayIndex} container alignContent='center' alignItems='center'>
                  <Grid item xs={4}>
                    <Grid container alignContent='center' alignItems='center'>
                      <Grid item xs={6}>
                        <Typography variant='subtitle2' component='span' fontWeight='bold'>
                          {day.day}
                        </Typography>
                        <Grid
                          container
                          alignContent='center'
                          alignItems='center'
                          fontSize='14px'
                          className='primaryColor'
                        >
                          <AddIcon fontSize='inherit' />
                          <span className='cursorPointer' onClick={() => addMoreTimeSlot(dayIndex)}>
                            Add Hours
                          </span>
                        </Grid>
                      </Grid>
                      <Grid item xs={6}>
                        <AppleSwitchBtn
                          sx={{ m: 1 }}
                          checked={day.active}
                          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                            toggleDay(dayIndex, e)
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={8}>
                    <Grid container alignContent='center' alignItems='center'>
                      <Grid item xs={4}>
                        <Box className={styles.businessHour_timepicker}>
                          <KeyboardArrowDownIcon className={styles.businessHour_timepicker_icon} />
                          <BasicTimePicker
                            value={day.timeSlot[0].from}
                            onChange={(value: Dayjs | null) =>
                              handleTimeChange(dayIndex, 0, 'from', value)
                            }
                          />
                        </Box>
                      </Grid>
                      <Grid item xs={1}>
                        to
                      </Grid>
                      <Grid item xs={4}>
                        <Box className={styles.businessHour_timepicker}>
                          <KeyboardArrowDownIcon className={styles.businessHour_timepicker_icon} />
                          <BasicTimePicker
                            value={day.timeSlot[0].to}
                            onChange={(value: Dayjs | null) =>
                              handleTimeChange(dayIndex, 0, 'to', value)
                            }
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                {day.timeSlot.length > 1 ? (
                  <Grid container>
                    <Grid xs={4}> </Grid>
                    <Grid xs={8}>
                      {day.timeSlot.map((time: ITimeSlot, timeSlotIndex: number) => {
                        if (timeSlotIndex === 0) return null
                        return (
                          <Grid
                            key={timeSlotIndex}
                            container
                            alignContent='center'
                            alignItems='center'
                          >
                            <Grid item xs={4}>
                              <Box className={styles.businessHour_timepicker}>
                                <KeyboardArrowDownIcon
                                  className={styles.businessHour_timepicker_icon}
                                />
                                <BasicTimePicker
                                  value={time.from}
                                  onChange={(value: Dayjs | null) =>
                                    handleTimeChange(dayIndex, timeSlotIndex, 'from', value)
                                  }
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={1}>
                              to
                            </Grid>
                            <Grid item xs={4}>
                              <Box className={styles.businessHour_timepicker}>
                                <KeyboardArrowDownIcon
                                  className={styles.businessHour_timepicker_icon}
                                />
                                <BasicTimePicker
                                  value={time.to}
                                  onChange={(value: Dayjs | null) =>
                                    handleTimeChange(dayIndex, timeSlotIndex, 'to', value)
                                  }
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={2}>
                              <IconButton onClick={() => removeTimeSlot(dayIndex, timeSlotIndex)}>
                                <ClearIcon color='info' />
                              </IconButton>
                            </Grid>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Grid>
                ) : null}
              </>
            )
          })
        }
      </Box>
    </CustomizedDialog>
  )
}

export default BusinessHoursModal
