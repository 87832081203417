import axios from 'src/api/axios'
import { Business, paginationPayload , BusinessPayload} from './index.types'
import { Page } from 'src/typings/pagination.types'

const ENDPOINT = 'business/api/v1/admin/'

export function addBusiness(payload: FormData) {
  return new Promise((resolve, reject) => {
    axios
      .post(ENDPOINT + 'business-directory/', payload, {
        headers: { 'Content-type': 'multipart/form-data' },
      })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function fetchBusinesses(paginationPayload: paginationPayload) {
  return new Promise((resolve, reject) => {
    axios
      .post(ENDPOINT + 'business-directory/business_list/', paginationPayload)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function fetchBusinessById(id: number) {
  return new Promise((resolve, reject) => {
    axios
      .get(ENDPOINT + `business-directory/${id}/`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

interface deleteBusinessPayload {
  id: number | undefined
}
export function deleteBusiness({ id }: deleteBusinessPayload) {
  return new Promise((resolve, reject) => {
    axios
      .delete(ENDPOINT + `business-directory/${id}/`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function uploadBusinessCSV(payload: FormData) {
  return new Promise((resolve, reject) => {
    axios
      .post(ENDPOINT + 'upload-csv/', payload)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}


interface updateBusinessPayload{
  payload: FormData,
  id: number | undefined
}

export function updateBusiness({payload, id}:updateBusinessPayload) {
  return new Promise((resolve, reject) => {
    axios
      .put(ENDPOINT + `business-directory/${id}/`, payload)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}