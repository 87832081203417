import React, { useContext } from 'react'
import { AuthContext } from 'src/Context/AuthContext'

import { Box, Typography, Grid } from '@mui/material'
import CustomerSupport from './CustomerSupport'
import HouseListing from './HouseListing'
import Stats from './Stats'

const Dashboard = () => {
  const context = useContext(AuthContext)

  console.log(context)

  return (
    <Box p={3}>
      <Typography variant='h5' color='primary' fontWeight='bold'>
        Dashboard
      </Typography>

      <Stats />

      <Grid container alignItems='stretch'>
        {/* <Grid item md={8} sm={12} xs={12} p={2}>
          <Card sx={{ minHeight: '100%' }}>
            <Grid p={3}></Grid>
          </Card>
        </Grid> */}
        <Grid item md={4} sm={12} xs={12} p={2}>
          <CustomerSupport />
        </Grid>
      </Grid>

      <Box p={2}>
        <HouseListing />
      </Box>
    </Box>
  )
}

export default Dashboard
