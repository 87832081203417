import React from 'react'
import { Box, Typography } from '@mui/material'
import styles from './index.module.css'
import { styled } from '@mui/material/styles'
import AttachFileIcon from '@mui/icons-material/AttachFile'
interface MsgPreviewProps {
  message?: any
  time?: string
  isMyMsg: boolean
}

const File = styled('div')`
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  cursor: pointer;
`

const MsgPreview = (props: MsgPreviewProps) => {
  const donwloadFile = () => {
    window.open(props.message.message.file.url, '_blank')
  }
  // console.log('props', props)

  return (
    <Box>
      <Box className={props.isMyMsg ? styles.sent_msgPreview : styles.received_msgPreview}>
        {typeof props?.message?.message === 'string' && (
          <Typography variant='subtitle2'>{props.message.message}</Typography>
        )}
        {props?.message?.message?.text && (
          <Typography variant='subtitle2'>{props.message.message.text}</Typography>
        )}

        {props?.message?.message?.file && (
          <Box>
            <File onClick={donwloadFile}>
              <AttachFileIcon />
              Click to view file
            </File>
          </Box>
        )}
      </Box>
      <Box
        sx={{ color: ' #9FAAB6', fontSize: '12px', textAlign: props.isMyMsg ? 'right' : 'left' }}
      >
        {props.time || ''}
      </Box>
    </Box>
  )
}

export default MsgPreview
