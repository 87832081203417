import * as React from 'react'
import { styled } from '@mui/material/styles'
import Button from '@mui/material/Button'
import { Grid, CircularProgress } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}))

export interface DialogTitleProps {
  id: string
  children?: React.ReactNode
  onClose: () => void
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label='close'
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: 'white',
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  )
}

export interface CustomizedDialogProps {
  open: boolean
  title: string
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl'
  onClose: () => void
  onSave: () => void
  children?: React.ReactNode
  saveBtnText?: string
  cancelBtnText?: string
  actionBtnWidth?: string
  actionBtnPosition?: 'corner' | 'center'
  loading?: boolean
  saveBtnPosition?: 'first' | 'second'
}

export default function CustomizedDialog(props: CustomizedDialogProps) {
  const {
    open,
    title,
    children,
    cancelBtnText = 'Cancel',
    saveBtnText = 'Save',
    onClose,
    onSave,
    maxWidth,
    actionBtnWidth = '350px',
    actionBtnPosition = 'corner',
    loading,
    saveBtnPosition = 'second',
  } = props

  const ActionButtons = () => {
    const CloseBtn = () => (
      <Button
        sx={{
          width: actionBtnWidth,
        }}
        variant='contained'
        color='secondary'
        onClick={onClose}
      >
        {cancelBtnText}
      </Button>
    )

    const SaveBtn = () => (
      <Button
        sx={{
          width: actionBtnWidth,
        }}
        variant='contained'
        color='primary'
        onClick={onSave}
        disabled={loading}
      >
        {loading ? <CircularProgress size={25} /> : saveBtnText}
      </Button>
    )

    return (
      <Grid
        container
        justifyContent={actionBtnPosition === 'corner' ? 'space-between' : 'center'}
        p={0}
        pl={3}
        pr={3}
      >
        <Grid item m={1}>
          {saveBtnPosition === 'first' ? <SaveBtn /> : <CloseBtn />}
        </Grid>
        <Grid item m={1}>
          {saveBtnPosition === 'second' ? <SaveBtn /> : <CloseBtn />}
        </Grid>
      </Grid>
    )
  }
  return (
    <div>
      <BootstrapDialog
        onClose={onClose}
        aria-labelledby='customized-dialog-title'
        open={open}
        fullWidth
        maxWidth={maxWidth}
        disableEscapeKeyDown
      >
        <BootstrapDialogTitle id='customized-dialog-title' onClose={onClose}>
          <Typography variant='h5' color='primary' fontWeight={700}>
            {title}
          </Typography>
        </BootstrapDialogTitle>
        <DialogContent>{children}</DialogContent>
        <DialogActions>
          <ActionButtons />
        </DialogActions>
      </BootstrapDialog>
    </div>
  )
}
