import React, { useState, useContext } from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Box, Button, CircularProgress } from '@mui/material'
import InputField from 'src/Components/InputField'
import TransparentBox from 'src/Components/TransparentBox'
import CorrectTick from 'src/assets/icons/correct-tick.png'

import { AuthContext } from 'src/Context/AuthContext'
import { useNavigate } from 'react-router-dom'

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'left',
  marginTop: '48px',
  width: '348px',
  padding: '10px',
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: '100%',
  },
}))

const initialErrors = {
  email: [],
  error: '',
  message: '',
}

const ForgotPassword = () => {
  const { generateOTPforForgotPassword } = useContext(AuthContext)
  
  const navigate = useNavigate()

  const [step, setStep] = useState(0)
  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(initialErrors)

  const handleGenerateOTP = (e: any) => {
    e.preventDefault()

    if (!email) {
      setErrors({
        ...errors,
        error: 'Email is required.',
      })
      return false
    }

    setLoading(true)
    setErrors(initialErrors)
    generateOTPforForgotPassword(email)
      .then((res: any) => {
        setStep((prevState) => {
          return prevState + 1
        })
      })
      .catch((err: any) => {
        setErrors(err.response.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  
  const goToLoginPage = () => {
    navigate('/login')
  }

  return (
    <TransparentBox>
      {step === 0 && (
        <>
          <Typography
            variant='h4'
            component='h4'
            color='primary'
            textAlign='center'
            fontWeight='bold'
          >
            Forgot Password?
          </Typography>
          <Form noValidate autoComplete='off' color='secondary' onSubmit={handleGenerateOTP}>
            <InputField
              name='email'
              type='text'
              label='Send verification code to:'
              placeholder='Example@gmail.com'
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
            />

            <Typography variant='subtitle2' component='p' color='red' textAlign='center'>
              <strong>
                {' '}
                {errors.email && errors.email.length
                  ? errors.email.toString()
                  : errors.error || errors.message}{' '}
              </strong>
            </Typography>

            <Button
              disabled={loading}
              variant='contained'
              color='primary'
              type='submit'
              sx={{
                marginTop: '20px',
              }}
            >
              {loading ? <CircularProgress /> : 'Send'}
            </Button>
          </Form>
        </>
      )}

      {step === 1 && (
        <Box alignContent='center' alignItems='center' textAlign='center'>
          <Form noValidate autoComplete='off' color='secondary'>
            <Box mb={3}>
              <img src={CorrectTick} height={100} width={100} />
            </Box>
            <Typography
              variant='h5'
              component='h5'
              color='primary'
              textAlign='center'
              fontWeight='bold'
            >
              Password reset e-mail has been sent
            </Typography>
            <Typography
              variant='subtitle1'
              component='p'
              color='white'
              textAlign='center'
              fontWeight='bold'
              mb={2}
            >
              Please check your email.
            </Typography>
            <Button
              onClick={goToLoginPage}
              variant='contained'
              color='primary'
              type='button'
              sx={{
                marginTop: '20px',
              }}
            >
              Log In
            </Button>
          </Form>
        </Box>
      )}
    </TransparentBox>
  )
}

export default ForgotPassword
