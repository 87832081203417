/* eslint-disable camelcase */
import React from 'react'

import {
  Box,
  Typography,
  Grid,
  Card,
  MenuItem,
  Select,
  IconButton,
  CircularProgress,
} from '@mui/material'
import { IHouseRequest, paginationPayload } from 'src/api/houseRequest/index.types'
import { fetchHouseRequests } from 'src/api/houseRequest'
import { useQuery } from 'react-query'

import GradientBadge from 'src/Components/GradientBadge'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight'
import { styled } from '@mui/material/styles'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from 'src/utils/constant'
import noImage from 'src/assets/images/no-image.png'

const Image = styled('img')`
  width: 100%;
  height: 250px;
  object-fit: cover;
`

const HouseListingNavigationButton = styled(IconButton)(({ theme }) => ({
  background: '#FEF15D',
  textAlign: 'center',
  ':hover': {
    background: '#FEF15D',
  },
}))

const HouseListing = () => {
  const [page, setPage] = React.useState(1)
  const navigate = useNavigate()

  const payload: paginationPayload = {
    page: page,
    per_page_value: 3,
    order_by_field: 'created',
    order_by: 'desc',
    status: 'pending',
  }

  const { isLoading, data }: any = useQuery({
    queryKey: ['dashboard_house_requests', payload],
    queryFn: () => fetchHouseRequests(payload),
    cacheTime: 0,
    retry: 0,
  })

  const DATA_LIST = data?.data || []
  const META_INFO = data?.pagination_meta || {}

  const handlePrev = () => {
    if (page > 1) {
      setPage((_page) => _page - 1)
    }
  }

  const handleNext = () => {
    if (page < META_INFO.total_pages) {
      setPage((_page) => _page + 1)
    }
  }

  const navigateToHousePage = (id:number) => {
    navigate(routeUrls.houseRequests + '/' + id)
  }

  return (
    <Card>
      <Grid container justifyContent='space-between' p={3}>
        <Typography color='primary' variant='subtitle1' fontWeight='bold'>
          Houses Listed
        </Typography>
        {/* <Select
            name='category'
            id='category'
            displayEmpty
            inputProps={{ 'aria-label': 'Without label' }}
            value='location'
            variant='standard'
          >
            <MenuItem value='location'>Location</MenuItem>
          </Select> */}
      </Grid>

      <Grid container pb={2} justifyContent='center' alignItems='center'>
        <Grid xs={1} textAlign='center'>
          <HouseListingNavigationButton onClick={handlePrev} disabled={page === 1}>
            <KeyboardArrowLeftIcon />
          </HouseListingNavigationButton>
        </Grid>
        <Grid xs={10}>
          {isLoading ? (
            <Grid container justifyContent='center'>
              {' '}
              <CircularProgress />{' '}
            </Grid>
          ) : (
            <Grid container>
              {DATA_LIST.map((row: IHouseRequest) => {
                return (
                  <Grid lg={4} md={6} sm={6} xs={12} p={1} mt={2} key={row.id}  sx={{cursor:'pointer'}}  onClick={() => navigateToHousePage(row.id)} >
                    <Image src={row.house_images?.picture || noImage} alt={row.house_name} className='roundedCorner' />
                    <Typography color='white' variant='subtitle1' fontWeight='bold' mt={1}>
                      {row.house_name}
                    </Typography>

                    <Typography color='white' variant='subtitle2' mt={2} mb={2}>
                      {row.address}
                    </Typography>

                    <GradientBadge>Pending to approve</GradientBadge>
                  </Grid>
                )
              })}
            </Grid>
          )}
        </Grid>
        <Grid xs={1} textAlign='center'>
          <HouseListingNavigationButton
            onClick={handleNext}
            disabled={page === META_INFO.total_pages}
          >
            <KeyboardArrowRightIcon />
          </HouseListingNavigationButton>
        </Grid>
      </Grid>
    </Card>
  )
}

export default HouseListing
