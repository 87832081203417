import React, { useState, useContext, useEffect } from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Box, Button, CircularProgress } from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'
import InputField from 'src/Components/InputField'
import TransparentBox from 'src/Components/TransparentBox'
import CorrectTick from 'src/assets/icons/correct-tick.png'

import { AuthContext } from 'src/Context/AuthContext'

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  // alignItems: 'left',
  marginTop: '48px',
  width: '348px',
  padding: '10px',
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: '100%',
  },
}))

const initialErrors = {
  email: [],
  otp: [],
  // eslint-disable-next-line camelcase
  new_password1: [],
  // eslint-disable-next-line camelcase
  new_password2: [],
  error: '',
  message: '',
}

const ResetPassword = () => {
  const { verifyForgotPasswordOTP, resetPassword } = useContext(AuthContext)

  const navigate = useNavigate()

  const [queryParams] = useSearchParams()

  let _code = ''
  let _email = ''
  let hasCode = false
  if (queryParams.get('code') && queryParams.get('email')) {
    try {
      _code = atob(queryParams.get('code') || '')
      _email = queryParams.get('email') || ''
      hasCode = true
    } catch (error: any) {
      console.log(error)
    }
  }

  const [step, setStep] = useState(0)
  const [newPassword, serNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')
  const [uid, setUid] = useState('')
  const [token, setToken] = useState('')
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState(initialErrors)

  const handleVerification = () => {
    setLoading(true)
    setErrors(initialErrors)
    verifyForgotPasswordOTP(_email, _code)
      .then((res: any) => {
        setUid(res.data.uid)
        setToken(res.data.token)
        setStep((prevState) => {
          return prevState + 1
        })
      })
      .catch((err: any) => {
        setErrors({
          ...errors,
          message: 'Verification link is Invalid or Expired!',
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const handleChangePassword = (e: any) => {
    e.preventDefault()

    if (!newPassword) {
      setErrors({
        ...errors,
        error: 'New password is required.',
      })
      return false
    }

    if (newPassword !== confirmPassword) {
      setErrors({
        ...errors,
        error: 'Confirm password is not correct.',
      })
      return false
    }

    setLoading(true)
    setErrors(initialErrors)
    resetPassword(newPassword, confirmPassword, uid, token)
      .then((res: any) => {
        setStep((prevState) => {
          return prevState + 1
        })
      })
      .catch((err: any) => {
        setErrors(err.response.data)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const goToLoginPage = () => {
    navigate('/login')
  }

  useEffect(() => {
    if (hasCode) {
      handleVerification()
    } else {
      setErrors({
        ...errors,
        message: 'Verification link is Invalid or Expired!',
      })
    }
  }, [])

  return (
    <TransparentBox>
      {step === 0 && (
        <>
          <Typography
            variant='h4'
            component='h4'
            color='primary'
            textAlign='center'
            fontWeight='bold'
          >
            Verifying
          </Typography>

          <Form noValidate autoComplete='off' color='secondary'>
            <Typography variant='subtitle2' component='p' color='red' textAlign='center'>
              <strong>{errors.message}</strong>
              {loading ? (
                <CircularProgress />
              ) : (
                <Button
                  onClick={goToLoginPage}
                  variant='contained'
                  color='primary'
                  type='button'
                  sx={{
                    marginTop: '20px',
                  }}
                >
                  Go to Log In
                </Button>
              )}
            </Typography>
          </Form>
        </>
      )}

      {step === 1 && (
        <>
          <Typography
            variant='h4'
            component='h4'
            color='primary'
            textAlign='center'
            fontWeight='bold'
          >
            Recover password
          </Typography>

          <Form noValidate autoComplete='off' color='secondary' onSubmit={handleChangePassword}>
            <InputField
              name='password'
              type='password'
              label='Enter new password'
              placeholder='*****'
              value={newPassword}
              onChange={(e: any) => serNewPassword(e.target.value)}
            />

            <Box mt={2}>
              <InputField
                name='confirmPassword'
                type='password'
                label='Confirm password'
                placeholder='*****'
                value={confirmPassword}
                onChange={(e: any) => setConfirmPassword(e.target.value)}
              />
            </Box>
            <Typography variant='subtitle2' component='p' color='red' textAlign='center'>
              <strong>
                {' '}
                {errors.new_password2 && errors.new_password2.length
                  ? errors.new_password2.toString()
                  : errors.error || errors.message}{' '}
              </strong>
            </Typography>

            <Button
              disabled={loading}
              variant='contained'
              color='primary'
              type='submit'
              sx={{
                marginTop: '20px',
              }}
            >
              {loading ? <CircularProgress /> : 'Change Password'}
            </Button>
          </Form>
        </>
      )}

      {step === 2 && (
        <Box alignContent='center' alignItems='center' textAlign='center'>
          <Form noValidate autoComplete='off' color='secondary'>
            <Box mb={3}>
              <img src={CorrectTick} height={100} width={100} />
            </Box>
            <Typography
              variant='h5'
              component='h5'
              color='primary'
              textAlign='center'
              fontWeight='bold'
              mb={2}
            >
              Your password has been changed succesfully
            </Typography>

            <Button
              onClick={goToLoginPage}
              variant='contained'
              color='primary'
              type='button'
              sx={{
                marginTop: '20px',
              }}
            >
              Log In
            </Button>
          </Form>
        </Box>
      )}
    </TransparentBox>
  )
}

export default ResetPassword
