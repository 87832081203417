import React, { useState, useEffect, useContext } from 'react'
import { AuthContext } from 'src/Context/AuthContext'

import {
  Box,
  Grid,
  Divider,
  IconButton,
  Typography,
  Avatar,
  Chip,
  Select,
  MenuItem,
  FormControl,
  CircularProgress,
} from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { useNavigate, useParams } from 'react-router-dom'

import { headerHeight } from 'src/Layout/DashboardLayout'
import { useMutation, useQuery } from 'react-query'
import dayjs from 'dayjs'
import { ICustomerSupportRequestStatus } from 'src/api/customerSupport/index.types'

import { errorToast, successToast } from 'src/utils/toastService'

import ChatComponenet from '../../../Components/ChatComponent'
import LinkIcon from 'src/Components/Icons/LinkIcon'
import { routeUrls } from 'src/utils/constant'
import { fetchReportedHouseById, updateReportedHouseStatus } from 'src/api/reportedHouse'
import { createOrGetChannel, updateLastSeen } from 'src/api/chatApi'

interface statusDropdownOption {
  label: string
  value: ICustomerSupportRequestStatus
}

const statusDropdownOptions: statusDropdownOption[] = [
  { label: 'Pending', value: 'Pending' },
  { label: 'Active', value: 'Active' },
  { label: 'Resolved', value: 'Resolved' },
]

const ViewReportedHouse = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params
  const { user } = useContext(AuthContext)
  const [channelInfo, setChannelInfo] = useState<any>()

  const [changeStatus, setChangeStatus] = useState<ICustomerSupportRequestStatus | ''>('Pending')

  const { isLoading, data, refetch }: any = useQuery({
    queryKey: ['fetchReportedHouseById', id],
    queryFn: () => fetchReportedHouseById(Number(id)),
    cacheTime: 0,
    retry: 0,
  })

  const goBack = () => {
    navigate(-1)
  }

  const updateMutation = useMutation(updateReportedHouseStatus, {
    onError: (error: any, variables, context) => {
      // An error happened!
      errorToast('Oops, Something went wrong!')
    },
    onSuccess: (data: any, variables, context) => {
      // Boom baby!
      successToast('Specific request status changed successfully.')
      refetch()
    },
  })

  const createChannelMutation = useMutation(createOrGetChannel, {
    onError: (error: any, variables, context) => {
      // An error happened!
      errorToast('Oops, Unable to initiate chat.')
    },
    onSuccess: (data: any, variables, context) => {
      // Boom baby!
      console.log(data)
      if (data?.channel_data) {
        setChannelInfo(data?.channel_data)
      }
    },
  })

  const lastSeenMutation = useMutation(updateLastSeen, {
    onSuccess: (data: any, variables, context) => {
      // Boom baby!
      console.log(data)
    },
  })

  const handleRequestStatusChange = (event: any) => {
    setChangeStatus(event.target.value)

    if (data && event.target.value) {
      updateMutation.mutate({
        id: data.id,
        status: event.target.value,
      })
    }
  }

  const navigateToDetailPage = (id: number) => {
    navigate(routeUrls.viewhouseRequest.replace(':id', id.toString()))
  }

  useEffect(() => {
    if (data?.status) {
      setChangeStatus(data.status)
    }
    if (data && user && id) {
      createChannelMutation.mutate({
        fromUser: user.id,
        toUser: data.reported_by.id,
        requestId: Number(id),
      })
    }
  }, [data])

  useEffect(() => {
    // set admin last seen
    return () => {
      if (channelInfo && user) {
        lastSeenMutation.mutate({
          channelId: channelInfo.id,
          userId: user.id,
          timestamp: (new Date().getTime() * 10000).toString(),
        })
      }
    }
  }, [channelInfo])

  const senderUser = {
    id: user?.id,
    email: user?.email,
    name: user?.name,
  }
  const receiverUser = {
    id: data?.reported_by?.id || 0,
    email: data?.reported_by?.email || '',
    name: data?.reported_by?.name || '',
  }

  return (
    <Grid
      sx={{ minHeight: `calc(100vh - ${headerHeight}px)` }}
      container
      alignItems='stretch'
      p={0}
    >
      {isLoading ? (
        <Grid p={3} container minHeight={400} justifyContent='center' alignItems='center'>
          <Grid textAlign='center' item>
            <CircularProgress />
          </Grid>
        </Grid>
      ) : data ? (
        <>
          <Grid p={3} item xs={12} sm={12} lg={4} md={5}>
            <Box>
              <Box>
                <IconButton
                  onClick={goBack}
                  style={{ backgroundColor: 'transparent' }}
                  size='small'
                  color='info'
                >
                  <NavigateBeforeIcon color='info' />{' '}
                  <Typography variant='h5' component='span' color='primary' fontWeight='bold'>
                    Request #{id}
                  </Typography>
                </IconButton>
              </Box>

              <Box sx={{ flexGrow: 0 }} ml='auto' alignContent='center' alignItems='center' mt={5}>
                <IconButton sx={{ p: 0, mt: 1 }}>
                  <Avatar alt={data?.reported_by?.name} src={data?.user?.picture || 'broken-img.png'} />
                  <Typography
                    variant='subtitle1'
                    component='span'
                    color='primary'
                    ml={1}
                    textAlign='left'
                  >
                    <strong>{data?.reported_by?.name}</strong>
                  </Typography>
                </IconButton>
              </Box>

              <Box mt={5}>
                <Box>
                  <Typography variant='subtitle2' color='white' fontWeight='bold'>
                    Email
                  </Typography>
                  <Typography
                    variant='subtitle2'
                    color='white'
                    mt={1}
                    sx={{
                      wordWrap: 'break-word',
                    }}
                  >
                    {data?.reported_by?.email}
                  </Typography>
                </Box>

                <Box mt={3}>
                      <Typography variant='subtitle2' color='white' fontWeight='bold'>
                        User type
                      </Typography>
                      <Typography variant='subtitle2' color='white' mt={1}>
                        <Chip label={data?.user?.role_name || 'NA'} color='default' />
                      </Typography>
                    </Box>

                    <Box mt={3}>
                      <Typography variant='subtitle2' color='white' fontWeight='bold'>
                        House ID : &nbsp; #{data?.reported_module_id}{' '}
                        <IconButton onClick={() => navigateToDetailPage(data?.reported_module_id)}>
                          <LinkIcon />
                        </IconButton>
                      </Typography>
                    </Box>

                <Box mt={3}>
                  <Typography variant='subtitle2' color='white' fontWeight='bold'>
                    Category
                  </Typography>
                  <Typography variant='subtitle2' color='white' mt={1}>
                    {data.category}
                  </Typography>
                </Box>

                
                <Box mt={3}>
                  <Typography variant='subtitle2' color='white' fontWeight='bold'>
                    Description
                  </Typography>
                  <Typography variant='subtitle2' color='white' mt={1}>
                    {data.details}
                  </Typography>
                </Box>

                <Box mt={3}>
                  <Typography variant='subtitle2' color='white' fontWeight='bold'>
                    Date
                  </Typography>
                  <Typography variant='subtitle2' color='white' mt={1}>
                    {dayjs(data?.created_at).format('MMM DD, YYYY hh:mm A')}
                  </Typography>
                </Box>

                <Box mt={3} pr={2}>
                  <Typography
                    variant='subtitle2'
                    component='p'
                    color='white'
                    mb={1}
                    fontWeight='bold'
                  >
                    <label> Ticket Status</label>
                  </Typography>
                  <FormControl fullWidth>
                    <Select
                      name='ticketStatus'
                      id='ticketStatus'
                      value={changeStatus}
                      onChange={handleRequestStatusChange}
                      displayEmpty
                      inputProps={{ 'aria-label': 'Without label' }}
                    >
                      {statusDropdownOptions.map((option, i: number) => {
                        return (
                          <MenuItem key={i} value={option.value}>
                            {option.label}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Divider orientation='vertical' flexItem />
          <Grid p={3} item xs={12} sm={12} lg={7} md={6}>
            {channelInfo && (
              <ChatComponenet
                currentChannel={channelInfo.channel_id}
                senderUser={senderUser}
                receiverUser={receiverUser}
              />
            )}
          </Grid>
        </>
      ) : (
        <Grid p={3} container minHeight={400} justifyContent='center' alignItems='center'>
          <Grid textAlign='center' item>
            Data not available.
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}

export default ViewReportedHouse
