/* eslint-disable camelcase */
import axios from 'src/api/axios'
import {
  ICustomerSupportRequest,
  ICustomerSupportRequestStatus,
  paginationPayload,
} from './index.types'
import { Page, QueryResponse } from 'src/typings/pagination.types'

const ENDPOINT = 'support/api/v1/admin/'

export function fetchCutomerSupportRequests(paginationPayload: paginationPayload) {
  return new Promise((resolve, reject) => {
    axios
      .post<QueryResponse<Page<ICustomerSupportRequest>>>(
        ENDPOINT + 'customer_support/',
        paginationPayload,
      )
      .then((res) => {
        // console.log(res,'res');
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function fetchCutomerSupportRequestById(id: number) {
  return new Promise((resolve, reject) => {
    axios
      .get(ENDPOINT + `support_ticket/${id}/`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function exportCustomerSupportData() {
  return new Promise((resolve, reject) => {
    axios
      .get(ENDPOINT + 'export_csv/')
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

interface updateSpecificSupportRequestStatusPayload {
  id: number
  status: ICustomerSupportRequestStatus
}
export function updateSpecificRequestStatus(payload: updateSpecificSupportRequestStatusPayload) {
  return new Promise((resolve, reject) => {
    axios
      .put(ENDPOINT + `customer_support/${payload.id}/`, {
        status: payload.status,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
