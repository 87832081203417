import React from 'react'

import {
  Box,
  Grid,
  IconButton,
  Divider,
  Button,
  Typography,
  Chip,
  CircularProgress,
} from '@mui/material'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import { useNavigate, useParams } from 'react-router-dom'
import { useQuery } from 'react-query'

import LocationOnIcon from '@mui/icons-material/LocationOn'
import ImageCarousel from 'src/Components/ImageCarousel'
import { fetchBusinessById } from 'src/api/business'
import dayjs from 'dayjs'
import EditBusinessDirectoryModal from '../EditBusinessDirectoryModal'

const ViewBusiness = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { id } = params
  const [openEditBusinessDirectory, setOpenEditBusinessDirectory] = React.useState(false)

  const goBack = () => {
    navigate(-1)
  }

  const { isFetching, data, refetch }: any = useQuery({
    queryKey: ['business', id],
    queryFn: () => fetchBusinessById(Number(id)),
    cacheTime: 0,
    retry: 0,
  })

  const handleCloseEditBusinessDirectory = () => {
    setOpenEditBusinessDirectory(false)
    refetch()
  }

  const handleOpenEditBusinessDirectory = () => {
    setOpenEditBusinessDirectory(true)
  }

  console.log(data)

  return (
    <Box p={3}>
      <IconButton
        onClick={goBack}
        style={{ backgroundColor: 'transparent' }}
        size='small'
        color='info'
      >
        <NavigateBeforeIcon color='info' /> <span style={{ fontSize: '15px' }}> Go back </span>
      </IconButton>
      <Divider />
      <Box>
        {!isFetching ? (
          data && data.success === true && data.data ? (
            <>
              {' '}
              <Grid
                container
                justifyContent='space-between'
                p={0}
                pl={3}
                pr={3}
                alignItems='center'
              >
                <Grid item m={1}>
                  <Typography variant='h5' color='primary' fontWeight='bold'>
                    {data.data.name}
                  </Typography>
                  <Grid container alignItems='center'>
                    <LocationOnIcon />
                    <Typography variant='subtitle2' color='white' fontWeight='bold'>
                      {data.data.location}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item m={1}>
                  <Button
                    sx={{
                      width: '300px',
                      fontWeight: 'bold',
                    }}
                    variant='contained'
                    color='primary'
                    onClick={handleOpenEditBusinessDirectory}
                  >
                    Edit Information
                  </Button>
                  <EditBusinessDirectoryModal
                    open={openEditBusinessDirectory}
                    onClose={handleCloseEditBusinessDirectory}
                    id={Number(id)}
                  />
                </Grid>
              </Grid>
              <Box>
                <Grid container>
                  <Grid item xs={12} sm={12} md={6}>
                    <ImageCarousel imageUrls={data.data.images.map((image: any) => image.image)} />
                  </Grid>

                  <Grid item xs={12} sm={12} md={6} p={2}>
                    <Grid
                      container
                      justifyContent='space-around'
                      alignItems='flex-start'
                      height='100%'
                      p={5}
                    >
                      <Grid item xs={12}>
                        <Typography variant='subtitle1' color='white' fontWeight='bold'>
                          Description
                        </Typography>
                        <Typography variant='subtitle2' color='white'>
                          {data.data.description}
                        </Typography>
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant='subtitle1' color='white' fontWeight='bold'>
                          Business hours
                        </Typography>
                        {data.data.is_open_all_day === true ? (
                          <Typography variant='subtitle2' color='white'>
                            {' '}
                            Open 24/7{' '}
                          </Typography>
                        ) : (
                          data.data.business_hours.map((day: any) => {
                            if (day.is_active) {
                              return (
                                <Box key={day.id}>
                                  <Typography variant='subtitle2' color='white'>
                                    {day.day}
                                    {' : '}
                                    {day.time_slot.map((slot: any) => {
                                      if (slot.from_time && slot.to_time) {
                                        return (
                                          <Chip
                                            key={slot.id}
                                            label={`${dayjs(slot.from_time, 'HH:mm:ss').format(
                                              'hh:mm A',
                                            )} to ${dayjs(slot.to_time, 'HH:mm:ss').format(
                                              'hh:mm A',
                                            )}`}
                                            color='primary'
                                            sx={{ margin: '5px' }}
                                          />
                                        )
                                      }
                                      return null
                                    })}
                                  </Typography>
                                </Box>
                              )
                            }
                            return null
                          })
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant='subtitle1' color='white' fontWeight='bold'>
                          Category
                        </Typography>
                        <Chip label={data.data.category} color='primary' />
                      </Grid>

                      <Grid item xs={12}>
                        <Typography variant='subtitle1' color='white' fontWeight='bold'>
                          Tags
                        </Typography>
                        <Box>
                          {data.data.tags.map((tag: string, i: number) => {
                            return <Chip label={tag} key={i} sx={{ marginRight: '5px' }} />
                          })}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </>
          ) : (
            <Grid container minHeight={400} justifyContent='center' alignItems='center'>
              <Grid textAlign='center' item>
                Data not available.
              </Grid>
            </Grid>
          )
        ) : (
          <Grid container minHeight={400} justifyContent='center' alignItems='center'>
            <Grid textAlign='center' item>
              <CircularProgress />
            </Grid>
          </Grid>
        )}
      </Box>
      <Divider />
    </Box>
  )
}

export default ViewBusiness
