/* eslint-disable camelcase */
import axios from 'src/api/axios'

const ENDPOINT = 'api/v1/admin/management/'
const choiceName = 'types_of_house'

type iTypeOfHouseType = '' | 'personality' | 'likes' | 'hobbies'

function getApiPath(type: iTypeOfHouseType) {
  const apis: any = {
    personality: ENDPOINT + 'personality/',
    likes: ENDPOINT + 'likes/',
    hobbies: ENDPOINT + 'hobbies/',
  }

  return apis[type]
}

interface insertPayload {
  name: string
  type: iTypeOfHouseType
}

export function addTypeOfHouse(payload: insertPayload) {
  return new Promise((resolve, reject) => {
    axios
      .post(getApiPath(payload.type), {
        name: payload.name,
        choice_name: choiceName,
      })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

function fetchTypeOfHouseByType(type: iTypeOfHouseType) {
  return new Promise((resolve, reject) => {
    axios
      .get(getApiPath(type) + choiceName + '/')
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function fetchTypeOfHouse() {
  return Promise.all([
    fetchTypeOfHouseByType('personality'),
    fetchTypeOfHouseByType('likes'),
    fetchTypeOfHouseByType('hobbies'),
  ])
}

interface deletePayload {
  id: number
  type: iTypeOfHouseType
}

export function deleteTypeOfHouse(payload: deletePayload) {
  return new Promise((resolve, reject) => {
    axios
      .delete(getApiPath(payload.type) + `${payload.id}/`)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

interface updatePayload {
  id: number
  name: string
  type: iTypeOfHouseType
}

export function updateTypeOfHouse(payload: updatePayload) {
  return new Promise((resolve, reject) => {
    axios
      .put(getApiPath(payload.type) + `${payload.id}/`, {
        name: payload.name,
        choice_name: choiceName,
      })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
