import * as React from 'react'
import { styled, alpha } from '@mui/material/styles'
import InputBase from '@mui/material/InputBase'
import SearchIcon from '@mui/icons-material/Search'
import { IconButton, Box } from '@mui/material'
import { debounce } from 'lodash'
import Addv2Icon from 'src/Components/Icons/Addv2Icon'

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  // backgroundColor: '#343434',
  borderRadius: '15px',
  border:'1px solid #EEEFF2'
}))

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  right: 0,
}))

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    // vertical padding + font size from searchIcon
    paddingRight: `calc(1em + ${theme.spacing(4)})`,

    border: 'none',
  },
  width: '95%',
}))

interface InputBoxWithIconProps {
  value: string;
  width?: number | string
  sx?: any
  placeholder: string,
  onChange?: (e:any) => void
}

const InputBoxWithIcon = (props: InputBoxWithIconProps) => {
  const { width = '100%', sx = {}, placeholder , onChange, value} = props


  return (
    <Box width={width} sx={sx}>
      <Search>
        <SearchIconWrapper>
          <IconButton
          >
            <Addv2Icon height={25} width={25} />
          </IconButton>
        </SearchIconWrapper>
        <StyledInputBase value={value} placeholder={placeholder} onChange={onChange} />
      </Search>
    </Box>
  )
}

export default InputBoxWithIcon
