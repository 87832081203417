import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';

const TransparentBox = styled(Box)(({ theme }) => ({
    background: 'rgba(23, 23, 23, 0.8)',
    padding: '20px',
    borderRadius: '20px',
    [theme.breakpoints.down('md')]: {
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.8)',
    },
}));

export default TransparentBox