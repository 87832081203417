import React from 'react'
import InputBase from '@mui/material/InputBase'
import Typography from '@mui/material/Typography'
import FormControl from '@mui/material/FormControl'

interface InputFieldtProps {
  name: string
  type: string
  label: string
  id?: string
  value?: any
  placeholder?: string
  onChange?: any
  multiline?: boolean
  rows?: number
  required?: boolean
  autoComplete?: string
  regex?: RegExp
  disabled?: boolean
}

const InputField = (props: InputFieldtProps) => {
  const handleChange = (e: any) => {
    if (props.regex) {
      e.target.value = e.target.value.replace(props.regex, '')
    }
    if (typeof props.onChange === 'function') {
      props.onChange(e)
    }
  }
  return (
    <FormControl variant='standard' fullWidth>
      <Typography variant='subtitle2' component='p' color='white' mb={1} fontWeight='bold'>
        <label htmlFor={props.id}> {props.label}</label>
      </Typography>
      <InputBase
        name={props.name}
        id={props.id}
        value={props.value}
        placeholder={props.placeholder}
        onChange={handleChange}
        type={props.type}
        multiline={!!props.multiline}
        rows={props.rows || 0}
        required={!!props.required}
        autoComplete={props.autoComplete}
        // disabled={!!props.disabled}
        className={props.disabled?'input_disabled':''}
      />
    </FormControl>
  )
}

export default InputField
