import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { useMutation } from 'react-query'
import { acceptInvite, AcceptInvitePayload } from 'src/api/management/invites'

const AcceptInvite = () => {
  const location = useLocation()

  const [wasInviteSuccessful, setWasInviteSuccessful] = useState<boolean | null>(null)
  const [isInviteNotFound, setIsInviteNotFound] = useState<boolean>(false)

  const mutation = useMutation(acceptInvite, {
    onError: (error: any, variables, context) => {
      if (error.response?.status === 404) {
        const errorMessage = error.response?.data?.message
        console.log(error.response?.data)

        if (errorMessage === 'Invite not found') {
          setIsInviteNotFound(true)
          console.log('Invite not found.')
        } else {
          // Handle other 404 errors
          console.log('404 Error: ', errorMessage)
        }
      } else {
        // Handle other types of errors
        console.log('An error occurred:', error)
      }

      setWasInviteSuccessful(false)
    },
    onSuccess: (data: any, variables, context) => {
      setWasInviteSuccessful(true)
    },
  })

  useEffect(() => {
    const params = new URLSearchParams(location.search)
    const email = params.get('email')
    const houseId = params.get('house_id')

    if (email && houseId) {
      console.log('Email:', email)
      console.log('House ID:', houseId)

      /* eslint-disable camelcase */
      const payload: AcceptInvitePayload = {
        house_id: Number(houseId),
        email: email,
      }
      /* eslint-enable camelcase */

      mutation.mutate(payload)
    }
  }, [location.search])

  return (
    <div>
      {wasInviteSuccessful === null && <h2>Processing...</h2>}
      {wasInviteSuccessful === true && <h2>Invite accepted successfully!</h2>}
      {wasInviteSuccessful === false && isInviteNotFound === true && (
        <h2>
          <div
            style={{
              paddingLeft: '40px',
              paddingRight: '40px',
            }}
          >
            Your invitation link is expired, kindly request again.
          </div>
        </h2>
      )}

      {wasInviteSuccessful === false && isInviteNotFound === false && (
        <div
          style={{
            padding: '40px',
          }}
        >
          <div className='relative z-10 container mx-auto pt-16 lg:pt-32 pb-16 lg:pb-32'>
            <div className='transition-all ease-in-out duration-500 opacity-100 translate-y-0 flex flex-col gap-4 max-w-3xl items-center mx-auto text-center'>
              <h2 className='break-word heading-large' style={{ color: 'rgb(255, 255, 255)' }}>
                You need to register to HousMthr first to accept invites!
                {/* Available for Download Now! */}
              </h2>
              <p className='body-normal' style={{ color: 'rgb(255, 255, 255)' }}>
                Discover how HousMthr can help manage your travel &amp; stays!
              </p>
              <div
                className='flex md:inline-flex flex-wrap gap-4 justify-center pt-4'
                style={{
                  marginTop: '50px',
                  display: 'flex',
                  justifyContent: 'space-around',
                }}
              >
                <a
                  className='button lg'
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://play.google.com/store/apps/details?id=com.housmthr.ios'
                  style={{
                    padding: '0', // Remove padding
                    backgroundColor: 'transparent', // Keep background transparent
                  }}
                >
                  <img
                    src='https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg'
                    alt='Google Play Store'
                    style={{ height: '40px' }}
                  />
                </a>
                <a
                  className='button lg'
                  target='_blank'
                  rel='noopener noreferrer'
                  href='https://apps.apple.com/us/app/housmthr/id6476569340'
                  style={{
                    padding: '0', // Remove padding
                    backgroundColor: 'transparent', // Keep background transparent
                  }}
                >
                  <img
                    src='https://upload.wikimedia.org/wikipedia/commons/3/3c/Download_on_the_App_Store_Badge.svg'
                    alt='Apple App Store'
                    style={{ height: '40px' }}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AcceptInvite
