import React, { useState } from 'react'
import {
  Box,
  Grid,
  Typography,
  IconButton,
  Select,
  MenuItem,
  CircularProgress,
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import CloseIcon from '@mui/icons-material/Close'
import CustomizedDialog from 'src/Components/CustomizedDialog'
import InputBoxWithIcon from '../Components/InputBoxWithIcon'
import { useMutation, useQuery } from 'react-query'
import {
  addInterest,
  deleteInterest,
  fetchInterest,
  updateInterest,
} from 'src/api/management/interest'
import { errorToast, successToast } from 'src/utils/toastService'

type iInterestType = '' | 'personality' | 'likes' | 'hobbies'

interface iInterestTypesObject {
  label: string
  key: iInterestType
}
const interestTypes: iInterestTypesObject[] = [
  {
    label: 'Vibe',
    key: 'personality',
  },
  {
    label: 'Likes',
    key: 'likes',
  },
  {
    label: 'Hobbies',
    key: 'hobbies',
  },
]

interface Row {
  id: number
  name: string
  choice_name: string
}

const Interests = () => {
  const [openAddModal, setOpenAddModal] = useState<boolean>(false)
  const [interestType, setInterestType] = useState<iInterestType>('')
  const [name, setName] = useState<string>('')
  const [editId, setEditId] = useState<number>(0)

  const {
    isFetching,
    data = [[], [], []], // 0 -> personality data array, 1 -> likes data array, 2 -> hobbies data array

    refetch,
  }: any = useQuery({
    queryFn: fetchInterest,
  })

  console.log('data', data)

  const handleOpenAddModal = (type: iInterestType) => {
    setEditId(0)
    setName('')
    setInterestType(type)
    setOpenAddModal(true)
  }

  const handleCloseAddModal = () => {
    setOpenAddModal(false)
  }

  const handleInterestTypeChange = (e: any) => {
    setInterestType(e.target.value)
  }

  const handleInput = (e: any) => {
    setName(e.target.value)
  }

  const insertMutation = useMutation(addInterest, {
    onSuccess: (data) => {
      console.log(data)
      successToast('Interest added successfully.')
      setName('')
      handleCloseAddModal()
      refetch()
    },
    onError: (error: any) => {
      if (error?.response?.data?.name) {
        error.response.data.name.forEach((msg: string) => {
          errorToast(msg)
        })
      } else {
        errorToast('Oops, Something went wrong.')
      }
    },
  })

  const handleSubmit = () => {
    insertMutation.mutate({
      name: name,
      type: interestType,
    })
  }

  const deleteMutation = useMutation(deleteInterest, {
    onSuccess: (data) => {
      console.log(data)
      successToast('Interest deleted successfully.')
      handleCloseAddModal()
      refetch()
    },
    onError: (error: any) => {
      if (error?.response?.data?.name) {
        error.response.data.name.forEach((msg: string) => {
          errorToast(msg)
        })
      } else {
        errorToast('Oops, Something went wrong.')
      }
    },
  })

  const handleDeleteInterest = (type: iInterestType, id: number) => {
    deleteMutation.mutate({
      id,
      type,
    })
  }

  const handleEditInterest = (type: iInterestType, row: Row) => {
    setEditId(row.id)
    setName(row.name)
    setInterestType(type)
    setOpenAddModal(true)
  }

  const updateMutation = useMutation(updateInterest, {
    onSuccess: (data) => {
      console.log(data)
      successToast('Interest updated successfully.')
      setName('')
      handleCloseAddModal()
      refetch()
    },
    onError: (error: any) => {
      if (error?.response?.data?.name) {
        error.response.data.name.forEach((msg: string) => {
          errorToast(msg)
        })
      } else {
        errorToast('Oops, Something went wrong.')
      }
    },
  })

  const handleEditSubmit = () => {
    updateMutation.mutate({
      id: editId,
      name: name,
      type: interestType,
    })
  }

  return (
    <Box>
      <Grid container>
        {isFetching || deleteMutation.isLoading ? (
          <Grid xs={12} textAlign='center'>
            <CircularProgress />
          </Grid>
        ) : (
          interestTypes.map((type, index: number) => {
            return (
              <Grid key={type.key} xs={12} md={4} sm={12} mt={5}>
                <Typography variant='subtitle1' fontWeight='bold'>
                  {type.label}
                </Typography>

                <Box>
                  {data[index].map((row: Row, i: number) => {
                    return (
                      <Grid container alignItems='center' key={row.name + i.toString()}>
                        <IconButton size='small' onClick={() => handleEditInterest(type.key, row)}>
                          <EditIcon fontSize='medium' color='warning' sx={{ marginRight: '7px' }} />
                        </IconButton>

                        {row.name}
                        <IconButton
                          size='small'
                          onClick={() => handleDeleteInterest(type.key, row.id)}
                        >
                          <CloseIcon color='info' fontSize='small' />
                        </IconButton>
                      </Grid>
                    )
                  })}

                  <Grid container alignItems='center' pl={4}>
                    <AddIcon fontSize='small' color='primary' />
                    <Typography
                      variant='subtitle2'
                      color='primary'
                      className='cursorPointer'
                      onClick={() => handleOpenAddModal(type.key)}
                    >
                      <span>Add Another</span>
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
            )
          })
        )}
      </Grid>

      <Box>
        <CustomizedDialog
          open={openAddModal}
          onClose={handleCloseAddModal}
          title={editId ? 'Edit Interest' : 'Add Interest'}
          onSave={editId ? handleEditSubmit : handleSubmit}
          maxWidth='xs'
          actionBtnPosition='center'
          saveBtnPosition='first'
          loading={insertMutation.isLoading || updateMutation.isLoading}
          saveBtnText={editId ? 'Save' : '+ Add'}
        >
          <Box mb={1}>
            <Typography variant='subtitle2' component='p' color='white' mb={1} fontWeight='bold'>
              <label> Type of Interest</label>
            </Typography>
            <Select
              name='interestType'
              id='interestType'
              value={interestType}
              onChange={handleInterestTypeChange}
              displayEmpty
              inputProps={{ 'aria-label': 'Without label' }}
              fullWidth
            >
              {(() => {
                if (editId) return <MenuItem value={interestType}>{interestType}</MenuItem>
                const menuItems = []

                menuItems.push(<MenuItem value=''>Select</MenuItem>)
                interestTypes.forEach((type, i) => {
                  menuItems.push(
                    <MenuItem key={i} value={type.key}>
                      {type.label}
                    </MenuItem>,
                  )
                })
                return menuItems
              })()}
            </Select>

            <Typography
              variant='subtitle2'
              component='p'
              color='white'
              mb={1}
              fontWeight='bold'
              mt={2}
            >
              <label>Interest</label>
            </Typography>
            <InputBoxWithIcon value={name} onChange={handleInput} placeholder='Enter interest' />
          </Box>
        </CustomizedDialog>
      </Box>
    </Box>
  )
}

export default Interests
