/* eslint-disable camelcase */
import React from 'react'

import { Box, Typography, Grid, Card, IconButton, Avatar, CircularProgress } from '@mui/material'
import LinkIcon from 'src/Components/Icons/LinkIcon'
import { ICustomerSupportRequest, paginationPayload } from 'src/api/customerSupport/index.types'
import { fetchCutomerSupportRequests } from 'src/api/customerSupport'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from 'src/utils/constant'

const CustomerSupport = () => {
  const navigate = useNavigate()

  const payload: paginationPayload = {
    page: 1,
    per_page_value: 5,
    order_by_field: 'created_at',
    order_by: 'desc',
    search: '',
  }

  const { isLoading, data }: any = useQuery({
    queryKey: ['dashboard_house_requestes', payload],
    queryFn: () => fetchCutomerSupportRequests(payload),
    cacheTime: 0,
    retry: 0,
  })

  const DATA_LIST = data?.data || []

  const navigateToCustomerSupportPage = () => {
    navigate(routeUrls.customerSupport)
  }

  const navigateToSpecificSupportPage = (id: number) => {
    navigate(routeUrls.customerSupport + '/' + id)
  }

  return (
    <Card sx={{ minHeight: '100%' }}>
      <Box p={3}>
        <Grid container justifyContent='space-between'>
          <Typography color='primary' variant='subtitle1' fontWeight='bold'>
            Customer Support
          </Typography>
          <IconButton onClick={navigateToCustomerSupportPage}>
            <LinkIcon />
          </IconButton>
        </Grid>

        {isLoading ? (
          <Grid container justifyContent='center'>
            {' '}
            <CircularProgress />{' '}
          </Grid>
        ) : (
          <Box>
            {DATA_LIST.map((row: ICustomerSupportRequest) => {
              return (
                <Box
                  key={row.id}
                  sx={{ flexGrow: 0 }}
                  mt={1}
                  alignContent='center'
                  alignItems='center'
                >
                  <Grid
                    container
                    sx={{ p: 0, cursor: 'pointer' }}
                    onClick={() => navigateToSpecificSupportPage(row.id)}
                  >
                    <Avatar
                      alt={row.reported_by.name}
                      src={row.user.picture || 'broken-image.jpg'}
                    />
                    <Typography
                      variant='subtitle1'
                      component='p'
                      fontSize='0.8rem'
                      color='white'
                      ml={1}
                      textAlign='left'
                    >
                      <strong> {row.reported_by.name}</strong>
                      <br />
                      {row.reported_by.role_name}
                    </Typography>
                  </Grid>
                </Box>
              )
            })}
          </Box>
        )}
      </Box>
    </Card>
  )
}

export default CustomerSupport
