import { useEffect } from 'react'
import OneSignal from 'react-onesignal'
import { useMutation } from 'react-query'
import { saveDeviceIdForPushNotification } from 'src/api/setting'
import { routeUrls } from 'src/utils/constant'

const usePushNotification = () => {
  const saveDeviceIdMutation = useMutation(saveDeviceIdForPushNotification)

  useEffect(() => {
    OneSignal.init({
      appId: process.env.REACT_APP_ONESIGNAL_APP_ID || '',
      // eslint-disable-next-line camelcase
      safari_web_id: process.env.REACT_APP_ONESIGNAL_SAFARI_WEB_ID || '',
      //   notifyButton: {
      //     enable: true,
      //   },
      allowLocalhostAsSecureOrigin: true,
      promptOptions: {
        actionMessage: 'Allow notification to get update on all activities.',
      },
    }).then(() => {
      OneSignal.showSlidedownPrompt()

      OneSignal.on('subscriptionChange', function (isSubscribed) {
        if (isSubscribed) {
          OneSignal.getUserId().then(function (userId) {
            // console.log('OneSignal User ID:', userId)
            saveDeviceIdMutation.mutate(userId || '')
            // (Output) OneSignal User ID: 270a35cd-4dda-4b3f-b04e-41d7463a2316
          })
        }
      })
      
      OneSignal.setDefaultNotificationUrl(window.location.origin + routeUrls.notification)
    })
  }, [])
}

export default usePushNotification
