import React, { useContext, useState } from 'react'
import { AuthContext } from 'src/Context/AuthContext'
import { Outlet, useLocation, Navigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import Header from './Header'
import Sidebar from './Sidebar'

import PubNub from 'pubnub'
import { PubNubProvider } from 'pubnub-react'
import usePushNotification from 'src/hooks/usePushNotification'
import { PUBNUB_PUBLISH_KEY, PUBNUB_SUBSCRIBE_KEY } from 'src/utils/constant'

export const drawerWidth = 320
export const headerHeight = 64

const DashboardLayout = () => {
  const { isAuthenticated, user, accessToken } = useContext(AuthContext)
  const location = useLocation()

  if (isAuthenticated === false || !user || !accessToken) {
    return <Navigate to='/login' state={{ from: location }} />
  }

  // Start Code from here

  usePushNotification()

  const [mobileOpen, setMobileOpen] = useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const pubnub = new PubNub({
    publishKey: PUBNUB_PUBLISH_KEY,
    subscribeKey: PUBNUB_SUBSCRIBE_KEY,
    userId: user.id.toString(),
    heartbeatInterval: 30,
  })

  return (
    <PubNubProvider client={pubnub}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />

        {/* Header  */}
        <Header
          drawerWidth={drawerWidth}
          headerHeight={headerHeight}
          handleDrawerToggle={handleDrawerToggle}
        />

        {/* Sidebar */}
        <Sidebar
          mobileOpen={mobileOpen}
          handleDrawerToggle={handleDrawerToggle}
          drawerWidth={drawerWidth}
        />

        {/* Main content */}
        <Box
          className='dashboardMainSection'
          component='main'
          sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` } }}
        >
          <Toolbar />
          <Box sx={{ minHeight: `calc(100vh - ${headerHeight}px)` }} p={0}>
            <Outlet />
          </Box>
        </Box>
      </Box>
    </PubNubProvider>
  )
}

export default DashboardLayout
