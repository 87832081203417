/* eslint-disable camelcase */
import axios from 'src/api/axios'

const ENDPOINT = 'api/v1/admin/management/'
const choiceName = 'interest'

type iInterestType = '' | 'personality' | 'likes' | 'hobbies'

function getApiPath(type: iInterestType) {
  const apis: any = {
    personality: ENDPOINT + 'personality/',
    likes: ENDPOINT + 'likes/',
    hobbies: ENDPOINT + 'hobbies/',
  }

  return apis[type]
}

interface insertPayload {
  name: string
  type: iInterestType
}

export function addInterest(payload: insertPayload) {
  return new Promise((resolve, reject) => {
    axios
      .post(getApiPath(payload.type), {
        name: payload.name,
        choice_name: choiceName,
      })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

function fetchInterestByType(type: iInterestType) {
  return new Promise((resolve, reject) => {
    axios
      .get(getApiPath(type) + choiceName + '/')
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function fetchInterest() {
  return Promise.all([
    fetchInterestByType('personality'),
    fetchInterestByType('likes'),
    fetchInterestByType('hobbies'),
  ])
}

interface deletePayload {
  id: number
  type: iInterestType
}

export function deleteInterest(payload: deletePayload) {
  return new Promise((resolve, reject) => {
    axios
      .delete(getApiPath(payload.type) + `${payload.id}/`)
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

interface updatePayload {
  id: number
  name: string
  type: iInterestType
}

export function updateInterest(payload: updatePayload) {
  return new Promise((resolve, reject) => {
    axios
      .put(getApiPath(payload.type) + `${payload.id}/`, {
        name: payload.name,
        choice_name: choiceName,
      })
      .then((res) => {
        resolve(res)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
