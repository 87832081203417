/* eslint-disable camelcase */
import * as React from 'react'
import {
  Box,
  Typography,
  Button,
  Grid,
  Popover,
  Pagination,
  Chip,
  IconButton,
  CircularProgress,
} from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'

import MoreVertIcon from '@mui/icons-material/MoreVert'
import EnhancedTableHead, { HeadCell, Order } from 'src/Components/Table/EnhancedTableHead'

import TableLoader from 'src/Components/Table/TableLoader'
import { perPageForListing } from 'src/utils/constant'
import { UpdateUserStatus, fetchUers, fetchUserInfoById } from 'src/api/user'
import dayjs from 'dayjs'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import SearchInputBox from 'src/Components/SearchInputBox'
import FilterIcon from 'src/Components/Icons/FilterIcon'
import { paginationPayload } from 'src/api/user/index.types'
import { errorToast, successToast } from 'src/utils/toastService'
import UserInfoComponent, { userInfoAction } from './UserInfoComponent'

interface Data {
  id: number
  username: string
  first_name?: string
  last_name?: string
  email: string
  last_seen: string
  is_active: boolean
}

const headCells: readonly HeadCell[] = [
  {
    id: 'link',
    label: '',
    sortable: false,
  },
  {
    id: 'id',
    label: '#',
    sortable: true,
  },
  {
    id: 'username',
    label: 'Name',
    sortable: true,
  },
  {
    id: 'email',
    label: 'Email',
    sortable: true,
  },
  {
    id: 'last_seen',
    label: 'Last Seen',
    sortable: true,
  },
  {
    id: 'is_active',
    label: 'Status',
    sortable: true,
  },
  {
    id: 'action',
    label: 'Action',
    sortable: false,
  },
]

interface IactionItem {
  open: boolean
  anchorEl: HTMLButtonElement | null
  data: Data | null
}

export default function Users() {
  const navigate = useNavigate()

  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<string>('id')
  const [page, setPage] = React.useState(1)
  const [search, setSearch] = React.useState<string>('')
  const [filterRef, setFilterRef] = React.useState<any>(null)
  const [filterValue, setFilterValue] = React.useState<string>('all')

  const [actionItem, setActionItem] = React.useState<IactionItem>({
    open: false,
    anchorEl: null,
    data: null,
  })

  const [userInfoAction, setUserInfoAction] = React.useState<userInfoAction>({
    open: false,
    anchorEl: null,
    data: null,
    id: null,
    name: '',
  })

  const payload: paginationPayload = {
    page: page,
    per_page_value: perPageForListing,
    search_text: search,
    order_by_field: orderBy,
    order_by: order,
    status: filterValue,
  }

  const { isFetching, data, refetch }: any = useQuery({
    queryKey: ['users', payload],
    queryFn: () => fetchUers(payload),
    cacheTime: 0,
    retry: 0,
  })

  const DATA_LIST = data?.data || []
  const META_INFO = data?.pagination_meta || {}

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handleActionMenuOpenEvent = (event: React.MouseEvent<HTMLButtonElement>, row: Data) => {
    setActionItem({
      open: true,
      anchorEl: event.currentTarget,
      data: row,
    })
  }

  const handleActionMenuCloseEvent = () => {
    setActionItem({
      open: false,
      anchorEl: null,
      data: null,
    })
  }

  const handleFilterClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setFilterRef(event.currentTarget)
  }

  const handleFilterClose = () => {
    setFilterRef(null)
  }

  const onFilterValueChange = (value: string) => {
    setFilterValue(value)
    handleFilterClose()
  }

  const mutation = useMutation(UpdateUserStatus, {
    onError: (error: any, variables, context) => {
      // An error happened!
      errorToast('Oops, Something went wrong!')
      handleActionMenuCloseEvent()
    },
    onSuccess: (data: any, variables, context) => {
      // Boom baby!
      successToast('User status changed successfully.')
      handleActionMenuCloseEvent()
      refetch()
    },
  })

  const toggleUserStatus = () => {
    mutation.mutate({
      userId: actionItem.data?.id,
      isActive: !actionItem.data?.is_active,
    })
  }

  const userInfoMutation = useMutation(fetchUserInfoById, {
    onError: (error: any, variables, context) => {
      // An error happened!
      console.log(error)
      setUserInfoAction((prevState) => {
        prevState.error = 'Unable to load user info.'
        return prevState
      })
    },
    onSuccess: (data: any, variables, context) => {
      console.log(data)
      setUserInfoAction((prevState) => {
        prevState.error = ''
        prevState.data = data.data
        return prevState
      })
    },
  })

  const handleUserInfoPopUpOpenEvent = (event: React.MouseEvent<HTMLButtonElement>, row: Data) => {
    setUserInfoAction({
      open: true,
      anchorEl: event.currentTarget,
      data: null,
      id: row.id,
      name: row.username,
    })

    userInfoMutation.mutate(row.id)
  }

  const handleUserInfoPopUpCloseEvent = () => {
    setUserInfoAction({
      open: false,
      anchorEl: null,
      data: null,
      id: null,
      name: '',
    })
  }

  return (
    <Box p={3}>
      <Box>
        <Typography variant='h5' color='primary' fontWeight='bold'>
          Users
        </Typography>

        <Grid pt={5} pb={5} container alignContent='center'>
          <SearchInputBox
            placeholder='Looking for a User?'
            width='400px'
            onChangeWithDebounce={setSearch}
            sx={{ display: 'inline-block', marginRight: '20px' }}
          />

          <IconButton
            size='small'
            sx={{
              color: 'white',
              fontSize: '15px',
              padding: '10px',
            }}
            onClick={handleFilterClick}
          >
            <FilterIcon height={20} width={20} /> Filter
          </IconButton>

          <Popover
            open={!!filterRef}
            anchorEl={filterRef}
            onClose={handleFilterClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box p={2}>
              <Typography
                sx={{ cursor: 'pointer' }}
                className={`popoverMenuItem  ${
                  filterValue === 'active' ? 'popoverMenuItem-active' : ''
                } `}
                onClick={() => onFilterValueChange('active')}
              >
                <span>Active</span>
              </Typography>
              <Typography
                sx={{ cursor: 'pointer' }}
                className={`popoverMenuItem  ${
                  filterValue === 'inactive' ? 'popoverMenuItem-active' : ''
                } `}
                onClick={() => onFilterValueChange('inactive')}
              >
                <span>Inactive</span>
              </Typography>
              <Typography
                sx={{ cursor: 'pointer' }}
                className={`popoverMenuItem  ${
                  filterValue === 'all' ? 'popoverMenuItem-active' : ''
                } `}
                onClick={() => onFilterValueChange('all')}
              >
                <span>All</span>
              </Typography>
            </Box>
          </Popover>
        </Grid>
      </Box>

      <Paper sx={{ mb: 2, overflow: 'hidden', background: 'none' }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='small'>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={DATA_LIST.length}
              headCells={headCells}
            />
            <TableBody>
              <TableLoader isLoading={isFetching}  colSpan={headCells.length}  />
              {DATA_LIST.map((row: Data) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell align='left'>
                      <IconButton onClick={(e) => handleUserInfoPopUpOpenEvent(e, row)}>
                        <ErrorOutlineIcon color='info' />
                      </IconButton>
                    </TableCell>

                    <TableCell align='left'>
                      <Typography variant='subtitle2' color='white' pl={1}>
                        {row.id}
                      </Typography>
                    </TableCell>
                    <TableCell align='left'>{row.username}</TableCell>
                    <TableCell align='left'>{row.email}</TableCell>
                    <TableCell align='left'>
                      {} {dayjs(row.last_seen).format('MMM DD, YYYY')}
                    </TableCell>
                    <TableCell align='left'>
                      {row.is_active ? (
                        <Chip label='ACTIVE' color='primary' />
                      ) : (
                        <Chip label='INACTIVE' color='secondary' />
                      )}
                    </TableCell>
                    <TableCell align='left'>
                      <IconButton
                        color='inherit'
                        onClick={(e) => handleActionMenuOpenEvent(e, row)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
              <TableRow
                style={{
                  height: '80px',
                }}
              >
                <TableCell colSpan={headCells.length} />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        {/* Action buttons */}
        <Popover
          open={actionItem.open}
          anchorEl={actionItem.anchorEl}
          onClose={handleActionMenuCloseEvent}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box p={2}>
            {actionItem.data?.is_active ? (
              <Typography
                sx={{ cursor: 'pointer' }}
                className='popoverMenuItem'
                onClick={toggleUserStatus}
              >
                {mutation.isLoading ? <CircularProgress size={20} /> : <span>Deactivate</span>}
              </Typography>
            ) : (
              <Typography
                sx={{ cursor: 'pointer' }}
                className='popoverMenuItem'
                onClick={toggleUserStatus}
              >
                {mutation.isLoading ? <CircularProgress size={20} /> : <span>Activate</span>}
              </Typography>
            )}
          </Box>
        </Popover>

        {/* User info pop up */}
        <UserInfoComponent
          userInfoAction={userInfoAction}
          isLoading={userInfoMutation.isLoading}
          handleUserInfoPopUpCloseEvent={handleUserInfoPopUpCloseEvent}
        />
      </Paper>
      <Pagination count={META_INFO.total_pages} page={page} onChange={handlePageChange} />
    </Box>
  )
}
