import * as React from 'react'
import { Box, Typography, Button, Grid, Popover, Pagination, CircularProgress } from '@mui/material'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import IconButton from '@mui/material/IconButton'

import AddBusinessDirectoryModal from './AddBusinessDirectoryModal'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import EnhancedTableHead, { HeadCell, Order } from 'src/Components/Table/EnhancedTableHead'
import { deleteBusiness, fetchBusinesses, uploadBusinessCSV } from 'src/api/business'
import { PageMeta, QueryResponse } from 'src/typings/pagination.types'
import TableLoader from 'src/Components/Table/TableLoader'
import { perPageForListing, routeUrls } from 'src/utils/constant'
import { errorToast, successToast } from 'src/utils/toastService'
import SimpleFileUploadButton from 'src/Components/SimpleFileUploadButton'
import UploadIcon from 'src/Components/Icons/UploadIcon'
import EditBusinessDirectoryModal from './EditBusinessDirectoryModal'

interface Data {
  id: number
  image: string
  businessName: string
  location: string
  phone: string
  category: string
}

const headCells: readonly HeadCell[] = [
  {
    id: 'image',
    label: 'Image',
    sortable: false,
  },
  {
    id: 'businessName',
    label: 'Business Name',
    sortable: false,
  },
  {
    id: 'location',
    label: 'Location',
    sortable: false,
  },
  {
    id: 'phone',
    label: 'Phone',
    sortable: false,
  },
  {
    id: 'category',
    label: 'Category',
    sortable: false,
  },
  {
    id: 'id',
    label: 'Action',
    sortable: false,
  },
]

interface IactionItem {
  open: boolean
  anchorEl: HTMLButtonElement | null
  data: Data | null
}

export default function BusinessDirectory() {
  const navigate = useNavigate()

  const [order, setOrder] = React.useState<Order>('asc')
  const [orderBy, setOrderBy] = React.useState<string>('businessName')
  const [page, setPage] = React.useState(1)

  const [openAddBusinessDirectory, setOpenAddBusinessDirectory] = React.useState(false)
  const [openEditBusinessDirectory, setOpenEditBusinessDirectory] = React.useState(false)

  const [actionItem, setActionItem] = React.useState<IactionItem>({
    open: false,
    anchorEl: null,
    data: null,
  })

  const { isFetching, data, refetch }: any = useQuery({
    queryKey: [
      'businesses',
      {
        page: page,
        // eslint-disable-next-line camelcase
        per_page_value: perPageForListing,
      },
    ],
    queryFn: () =>
      fetchBusinesses({
        page: page,
        // eslint-disable-next-line camelcase
        per_page_value: perPageForListing,
      }),
    cacheTime: 0,
    retry: 0,
  })

  const DATA_LIST = data?.data || []
  const META_INFO = data?.pagination_meta || {}

  const handleActionMenuOpenEvent = (event: React.MouseEvent<HTMLButtonElement>, row: Data) => {
    setActionItem({
      open: true,
      anchorEl: event.currentTarget,
      data: row,
    })
  }

  const handleActionMenuCloseEvent = () => {
    setActionItem({
      open: false,
      anchorEl: null,
      data: null,
    })
  }

  const handleOpenAddBusinessDirectory = () => setOpenAddBusinessDirectory(true)
  const handleCloseAddBusinessDirectory = () => {
    setOpenAddBusinessDirectory(false)
    refetch()
  }

  const handleOpenEditBusinessDirectory = () => {
    setOpenEditBusinessDirectory(true)
  }
  const handleCloseEditBusinessDirectory = () => {
    setOpenEditBusinessDirectory(false)
    handleActionMenuCloseEvent()
    refetch()
  }

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
    const isAsc = orderBy === property && order === 'asc'
    setOrder(isAsc ? 'desc' : 'asc')
    setOrderBy(property)
  }

  const handlePageChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value)
  }

  const handleEditRedirection = () => {
    const id = actionItem?.data?.id.toString() || ''
    navigate(routeUrls.viewBusiness.replace(':id', id))
  }

  const mutation = useMutation(deleteBusiness, {
    onError: (error: any, variables, context) => {
      errorToast('Oops, Something went wrong!')
    },
    onSuccess: (data: any, variables, context) => {
      successToast('Business deleted successfully.')
      handleActionMenuCloseEvent()
      refetch()
    },
  })

  const handleDeleteActionClick = () => {
    mutation.mutate({
      id: actionItem.data?.id,
    })
  }

  const uploadCSVmutation = useMutation(uploadBusinessCSV, {
    onError: (error: any, variables, context) => {
      errorToast('Oops, Something went wrong!')
    },
    onSuccess: (data: any, variables, context) => {
      if (data && data.data && data.data.success === true) {
        successToast('CSV uploaded successfully.')
        refetch()
      } else {
        errorToast('Oops, File not uploaded.')
      }
    },
  })

  const handleCSVupload = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files[0]) {
      const formData = new FormData()
      formData.append('file', event.target.files[0])
      uploadCSVmutation.mutate(formData)

      // reset file input
      event.target.value = ''
    }
  }

  return (
    <Box p={3} >
      <Box>
        <Grid container justifyContent='space-between'>
          <Grid item>
            <Typography variant='h5' color='primary' fontWeight='bold'>
              Business Directory
            </Typography>
          </Grid>
          <Grid item>
            <SimpleFileUploadButton
              width='150px'
              label={
                <Grid container alignItems='center' justifyContent='center'>
                  {uploadCSVmutation.isLoading ? (
                    <CircularProgress size={20} />
                  ) : (
                    <>
                      {' '}
                      <UploadIcon />
                      <Typography ml={1} variant='subtitle1' color='primary'>
                        Upload
                      </Typography>
                    </>
                  )}
                </Grid>
              }
              onChange={handleCSVupload}
              fileType='.csv'
              disabled={uploadCSVmutation.isLoading}
            />
          </Grid>
        </Grid>
        <Grid container pt={3} pb={3}>
          <Grid item xs={12} sm={9}>
            {' '}
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              variant='contained'
              fullWidth
              color='primary'
              onClick={handleOpenAddBusinessDirectory}
            >
              Add New
            </Button>
          </Grid>
        </Grid>
        <AddBusinessDirectoryModal
          open={openAddBusinessDirectory}
          onClose={handleCloseAddBusinessDirectory}
        />

        <EditBusinessDirectoryModal
          open={openEditBusinessDirectory}
          onClose={handleCloseEditBusinessDirectory}
          id={actionItem.data?.id}
        />
      </Box>

      <Paper sx={{ mb: 2, overflow: 'hidden', background: 'none' }}>
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby='tableTitle' size='small'>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={DATA_LIST.length}
              headCells={headCells}
            />
            <TableBody>
              <TableLoader isLoading={isFetching}   colSpan={headCells.length}  />
              { !isFetching && DATA_LIST.map((row: any) => {
                return (
                  <TableRow hover tabIndex={-1} key={row.id}>
                    <TableCell align='left'>
                      {row?.images[0] && row.images[0].image ? (
                        <img src={row.images[0].image} height={50} width={50} className='roundedCorner' />
                      ) : (
                        'NA'
                      )}
                    </TableCell>
                    <TableCell align='left'>{row.name}</TableCell>
                    <TableCell align='left'>{row.location}</TableCell>
                    <TableCell align='left'>{row.phone}</TableCell>
                    <TableCell align='left'>{row.category}</TableCell>
                    <TableCell align='left'>
                      <IconButton
                        color='inherit'
                        onClick={(e) => handleActionMenuOpenEvent(e, row)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                )
              })}
              <TableRow
                style={{
                  height: '80px',
                }}
              >
                <TableCell  colSpan={headCells.length}  />
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Popover
          open={actionItem.open}
          anchorEl={actionItem.anchorEl}
          onClose={handleActionMenuCloseEvent}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
        >
          <Box p={2}>
            <Typography
              sx={{ cursor: 'pointer' }}
              className='popoverMenuItem'
              onClick={handleOpenEditBusinessDirectory}
            >
              <span>Edit information</span>
            </Typography>
            <Typography
              sx={{ cursor: 'pointer' }}
              onClick={handleEditRedirection}
              className='popoverMenuItem'
            >
              <span>View</span>
            </Typography>
            <Typography
              sx={{ cursor: 'pointer' }}
              className='popoverMenuItem'
              onClick={handleDeleteActionClick}
            >
              {mutation.isLoading ? <CircularProgress size={20} /> : <span>Delete</span>}
            </Typography>
          </Box>
        </Popover>
      </Paper>
      <Pagination count={META_INFO.total_pages} page={page} onChange={handlePageChange} />
    </Box>
  )
}
