import React from 'react'
import { styled } from '@mui/material/styles'

import Carousel from 'react-material-ui-carousel'
import { Box, Button } from '@mui/material'

interface ImageCarousel {
  imageUrls: string[]
}

const Image = styled('img')`
  height: 400px;
  object-fit: cover;
  margin-bottom: ${(props) => props.theme.spacing(2)};
`

export default function ImageCarousel(props: ImageCarousel) {
  if (props.imageUrls.length === 0) return null
  return (
    <Carousel
      sx={{
        textAlign: 'center',
        overflowY: 'visible',
        overflowX: 'clip',
      }}
      navButtonsAlwaysVisible
      cycleNavigation={true}
      autoPlay={true}
      swipe={false}
      indicators={true}
      animation='fade'
      fullHeightHover={false}
    >
      {props.imageUrls.map((url, i) => (
        <Item key={i} url={url} />
      ))}
    </Carousel>
  )
}

interface ItemProp {
  url: string
}

function Item(props: ItemProp) {
  return (
    <Box p={5}>
      <Image src={props.url} />
    </Box>
  )
}
