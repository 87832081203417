import React from 'react';
import { styled } from '@mui/material/styles';
import { Grid, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const Root = styled(Grid)({
  height: '100vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  textAlign: 'center',
});

const Title = styled(Typography)({
  fontWeight: 'bold',
});

const Subtitle = styled(Typography)({
  fontWeight: 'bold',
  color:'#FEF15D'
});



const NotFoundPage: React.FC = () => {

  const navigate = useNavigate();
  const goToLoginPage = () => {
    navigate('/login')
  }

  return (
    <Root container p={3} >
      <Grid item xs={12} sm={8} md={6}>
        <Title variant="h1">404</Title>
        <Subtitle variant="subtitle1" color='warning' mb={3}  >Sorry... <br />
          There&apos;s nothing here</Subtitle>
        <Button  sx={{
             width:'300px',
             fontWeight:'bold'
        }} variant="contained" color="primary" type='button' onClick={goToLoginPage}>
          Go to Log In
        </Button>
      </Grid>
    </Root>
  );
};

export default NotFoundPage;
