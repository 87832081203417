import React from 'react';
import iconImage from 'src/assets/icons/arrow-down.png';

interface IconProp {
    width?: number,
    height?: number,
    style?: object,
    id?: string;
    className?: any;
}

const ArrowDown = (props: IconProp) => {
    const { width = 20, height = 20, style = {}, id , className} = props
    return (
        <img src={iconImage} width={width} height={height} style={style} id={id} className={className} />
    );
};

export default ArrowDown;