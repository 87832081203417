import React from 'react'
import myIcon from 'src/assets/icons/linkIcon.png'

interface IconProp {
  width?: number
  height?: number
  style?: object
  id?: string
  className?: any
}

const LinkIcon = (props: IconProp) => {
  const { width = 20, height = 20, style = {}, id, className } = props
  return (
    <img src={myIcon} width={width} height={height} style={style} id={id} className={className} />
  )
}

export default LinkIcon
