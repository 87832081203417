import * as React from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import { Dayjs } from 'dayjs'
import { PickerChangeHandlerContext, TimeValidationError } from '@mui/x-date-pickers'
// import  PickerChangeHandlerContext  from '@mui/x-date-pickers/internals/hooks/usePicker/usePickerValue.types'

export interface BasicTimePickerProps {
  value: null | Dayjs
  onChange: (value: Dayjs | null, context?: PickerChangeHandlerContext<TimeValidationError>) => void
}

export default function BasicTimePicker(props: BasicTimePickerProps) {
  const { value, onChange} = props
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['TimePicker']}>
        <TimePicker
          sx={{
            background: '#171717',
            color: 'white',
            borderRadius: '5px',
            padding: '10px',
            width: 'max-content',
            minWidth: '0px !important',
          }}
          value={value}
          onChange={onChange}
        />
      </DemoContainer>
    </LocalizationProvider>
  )
}
