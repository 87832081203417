import React, { useContext, useState } from 'react'
import { styled } from '@mui/material/styles'
import { Typography, Button, Box, CircularProgress } from '@mui/material'
import InputField from 'src/Components/InputField'
import TransparentBox from 'src/Components/TransparentBox'
import { AuthContext } from 'src/Context/AuthContext'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'

const Form = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'left',
  width: '348px',
  marginLeft: '20px',
  padding: '10px',
  '& .MuiTextField-root': {
    margin: theme.spacing(1),
    width: '100%',
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
}))

const ContactUs: React.FC = () => {
  const { contactUs: contactUsApi } = useContext(AuthContext)
  const navigate = useNavigate()
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
  const [errors, setErrors] = useState<{ [key: string]: string }>({
    name: '',
    email: '',
    message: '',
  })
  const [loading, setLoading] = useState(false)

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    // Validation
    let formIsValid = true
    const newErrors: { [key: string]: string } = {}

    if (!name) {
      formIsValid = false
      newErrors.name = 'Name is required'
    }

    if (!email) {
      formIsValid = false
      newErrors.email = 'Email is required'
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      formIsValid = false
      newErrors.email = 'Email is invalid'
    }

    if (!message) {
      formIsValid = false
      newErrors.message = 'Message is required'
    }

    if (!formIsValid) {
      setErrors(newErrors)
      return
    }

    if (formIsValid) {
      setErrors({ name: '', email: '', message: '' })
    }

    // Form submission logic
    setLoading(true)
    contactUsApi(name, email, message)
      .then((res: any) => {
        toast.success('Your message has been successfully submitted. Thank you!')
        navigate('/login')
      })
      .catch((err: { response: { data: { message: any } } }) => {
        toast.error('Failed to submit the form. Please try again later.')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <TransparentBox>
      <Typography
        variant='h2'
        component='h2'
        color='primary'
        textAlign='center'
        mt={10}
        fontWeight='bold'
      >
        Contact Us
      </Typography>
      <Box alignContent='center' alignItems='center'>
        <Form onSubmit={handleSubmit} noValidate autoComplete='off'>
          <Box>
            <InputField
              name='name'
              id='name'
              type='text'
              label='Name'
              placeholder='Your Name'
              value={name}
              onChange={(e: any) => setName(e.target.value)}
              required
            />
          </Box>
          {errors.name && (
            <Typography variant='subtitle2' component='p' color='error'>
              <strong>{errors.name}</strong>
            </Typography>
          )}
          <Box mt={2}>
            <InputField
              name='email'
              id='email'
              type='email'
              label='Email'
              placeholder='Your Email'
              value={email}
              onChange={(e: any) => setEmail(e.target.value)}
              required
            />
          </Box>
          {errors.email && (
            <Typography variant='subtitle2' component='p' color='error'>
              <strong>{errors.email}</strong>
            </Typography>
          )}
          <Box mt={2}>
            <InputField
              name='message'
              id='message'
              type='text'
              label='Message'
              placeholder='Your Message'
              value={message}
              onChange={(e: any) => setMessage(e.target.value)}
              multiline
              rows={4}
              required
            />
          </Box>
          {errors.message && (
            <Typography variant='subtitle2' component='p' color='error'>
              <strong>{errors.message}</strong>
            </Typography>
          )}
          <Button
            variant='contained'
            color='primary'
            type='submit'
            sx={{
              marginTop: '20px',
            }}
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} /> : 'Submit'}
          </Button>
        </Form>
      </Box>
    </TransparentBox>
  )
}

export default ContactUs
