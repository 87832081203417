import React, { useContext, useState } from 'react'
import { AuthContext } from 'src/Context/AuthContext'
import { Link, useNavigate } from 'react-router-dom'
import { styled } from '@mui/material/styles'
import { Box, CircularProgress } from '@mui/material'
import Drawer from '@mui/material/Drawer'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import Grid from '@mui/material/Grid'
import Toolbar from '@mui/material/Toolbar'
import Typography from '@mui/material/Typography'
import { ISidebarMenuItem, routeUrls, sidebarMenuItems } from 'src/utils/constant'
import logo from 'src/assets/images/logo.png'
import logoutIcon from 'src/assets/icons/logout.png'
import Divider from '@mui/material/Divider'
import { errorToast } from 'src/utils/toastService'

interface SidebarProps {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window?: () => Window
  drawerWidth: number
  mobileOpen: boolean
  handleDrawerToggle: () => void
}

const Sidebar = (props: SidebarProps) => {
  const { drawerWidth, window, mobileOpen, handleDrawerToggle } = props
  const { logout: logoutApi } = useContext(AuthContext)
  const navigate = useNavigate()

  const [logoutLoading, setLogoutLoading] = useState(false)

  const handleLogout = () => {
    if (logoutLoading) return
    setLogoutLoading(true)
    logoutApi()
      .then(() => {
        navigate(routeUrls.login)
      })
      .catch((err) => {
        errorToast('Unable to logout!')
      })
      .finally(() => {
        setLogoutLoading(false)
      })
  }

  const container = window !== undefined ? () => window().document.body : undefined
  const isActiveLink = (item: ISidebarMenuItem): boolean => {
    if (location.pathname === item.url) return true
    if (item.hasChildren) {
      return location.pathname.search(item.url) !== -1
    }
    return false
  }
  const webDrawer = (
    <Box>
      <Toolbar />
      <Box textAlign='center'>
        <img src={logo} alt='Logo' height={45} />
      </Box>

      <List
        sx={{
          width: 'max-content',
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingBottom: '60px',
        }}
      >
        {sidebarMenuItems.map((item: ISidebarMenuItem) => (
          <ListItem key={item.label} sx={{ paddingTop: '10px' }}>
            <Link
              className={`routerLink sidebarNavLink ${
                isActiveLink(item) ? 'sidebarActiveNavLink' : ''
              }`}
              to={item.url}
            >
              {item.label}
            </Link>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  const mobileDrawer = (
    <Box>
      <Toolbar />
      <Box textAlign='center'>
        <img src={logo} alt='Logo' height={45} />
      </Box>

      <List
        sx={{
          width: 'max-content',
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        {sidebarMenuItems.map((item: ISidebarMenuItem) => (
          <ListItem key={item.label} sx={{ paddingTop: '10px' }}>
            <Link
              className={`routerLink sidebarNavLink ${
                isActiveLink(item) ? 'sidebarActiveNavLink' : ''
              }`}
              to={item.url}
            >
              {item.label}
            </Link>
          </ListItem>
        ))}

        <ListItem sx={{ paddingTop: '10px' }} onClick={handleLogout}>
          <Grid container justifyContent='start' alignItems='center'>
            <Grid item>
              <Typography className='' component='span' sx={{}} p={2}>
                {logoutLoading ? <CircularProgress size='25px' /> : 'Logout'}
              </Typography>
            </Grid>
            <Grid item>
              <img src={logoutIcon} width={25} height={25} />
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </Box>
  )

  const LogoutBoxMobile = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    textAlign: 'center',
    cursor: 'pointer',
  }))

  const LogoutBox = styled(Box)(({ theme }) => ({
    position: 'absolute',
    bottom: '12px',
    left: 0,
    right: 0,
    textAlign: 'center',
    cursor: 'pointer',
    backgroundColor: '#343434',
    zIndex: 1200,
    color: 'white',
    width: drawerWidth - 20,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  }))

  return (
    <Box
      component='nav'
      sx={{ width: { md: drawerWidth }, flexShrink: { md: 0 }, position: 'relative' }}
      aria-label='mailbox folders'
    >
      {/* The implementation can be swapped with js to avoid SEO duplication of links. */}

      {/* Mobile sidebar */}
      <Drawer
        className='dashboardSidebar'
        container={container}
        variant='temporary'
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { xs: 'block', sm: 'block', md: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {mobileDrawer}
        {/* <LogoutBoxMobile onClick={handleLogout} id='LogoutBoxMobile'>
          <Divider />
          <Box mt={2}>
            <Grid container justifyContent='center' alignItems='center'>
              <Grid item>
                <Typography className='' component='span' sx={{}} p={2}>
                  {logoutLoading ? <CircularProgress size='25px' /> : 'Logout'}
                </Typography>
              </Grid>
              <Grid item>
                <img src={logoutIcon} width={25} height={25} />
              </Grid>
            </Grid>
          </Box>
        </LogoutBoxMobile> */}
      </Drawer>

      {/* web sidebar */}
      <Drawer
        className='dashboardSidebar'
        variant='permanent'
        sx={{
          display: { xs: 'none', sm: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {webDrawer}
      </Drawer>

      <LogoutBox onClick={handleLogout} id='logoutBox'>
        <Divider />
        <Box mt={2}>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid item>
              <Typography className='' component='span' sx={{}} p={2}>
                {logoutLoading ? <CircularProgress size='25px' /> : 'Logout'}
              </Typography>
            </Grid>
            <Grid item>
              <img src={logoutIcon} width={25} height={25} />
            </Grid>
          </Grid>
        </Box>
      </LogoutBox>
    </Box>
  )
}

export default Sidebar
