import React from 'react'
import {
  Box,
  Typography,
  Button,
  Grid,
  Popover,
  Pagination,
  Chip,
  IconButton,
  CircularProgress,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import LinkIcon from 'src/Components/Icons/LinkIcon'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from 'src/utils/constant'

export interface userInfoAction {
  open: boolean
  anchorEl: HTMLButtonElement | null
  id: number | null
  name: string | null
  data: any
  error?: string
}

interface UserInfoComponentProps {
  userInfoAction: userInfoAction
  handleUserInfoPopUpCloseEvent: () => void
  isLoading: boolean
}

const UserInfoComponent = (props: UserInfoComponentProps) => {
  const { userInfoAction, handleUserInfoPopUpCloseEvent, isLoading } = props
  const navigate = useNavigate()
  const handleNavigateToHousePage = (id:number) => {
    navigate(routeUrls.viewhouseRequest.replace(':id',id.toString()))
  }
  return (
    <Popover
      open={userInfoAction.open}
      anchorEl={userInfoAction.anchorEl}
      onClose={handleUserInfoPopUpCloseEvent}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box p={2} width='500px'>
        <Typography variant='subtitle1' color='primary' fontWeight='bold'>
          {userInfoAction.name}
          <IconButton size='small' sx={{ float: 'right' }} onClick={handleUserInfoPopUpCloseEvent}>
            <CloseIcon color='info' fontSize='small' />
          </IconButton>
        </Typography>

        {isLoading ? (
          <Grid height='100%' container justifyContent='center' alignItems='center'>
            {' '}
            <CircularProgress />{' '}
          </Grid>
        ) : userInfoAction.data ? (
          <Box>
            <Grid container pt={2}>
              <Grid xs={5}>
                {' '}
                <Typography variant='subtitle2' color='white'>
                  User since
                </Typography>{' '}
              </Grid>
              <Grid xs={7}>
                {' '}
                <Typography variant='subtitle2' color='white'>
                  {dayjs(userInfoAction.data.user_since).format('DD/MM/YY')}
                </Typography>
              </Grid>
            </Grid>

            <Grid container pt={2}>
              <Grid xs={5}>
                <Typography variant='subtitle2' color='white'>
                  Related house
                </Typography>
              </Grid>
              <Grid xs={7} container alignItems='center'>
                {userInfoAction.data.house_related_house_ids.map((house: any) => {
                  return (
                    <span key={house}>
                      <Typography variant='subtitle2' color='white' component='span'>
                        ID #{house}
                      </Typography>
                      <IconButton onClick={() => handleNavigateToHousePage(house)}>
                        <LinkIcon height={15} width={15} />
                      </IconButton>
                    </span>
                  )
                })}
              </Grid>
            </Grid>

            <Grid container pt={2}>
              <Grid xs={5}>
                {' '}
                <Typography variant='subtitle2' color='white'>
                  Tags
                </Typography>{' '}
              </Grid>
              <Grid xs={7}>
                {userInfoAction.data.role_tags.map((role: any) => (
                  <Chip label={role} key={role} color='default' />
                ))}
              </Grid>
            </Grid>

            <Grid container pt={2}>
              <Grid xs={5}>
                <Typography variant='subtitle2' color='white'>
                  Last viewed houses
                </Typography>
              </Grid>
              <Grid xs={7} container alignItems='center'>
                {userInfoAction.data.last_viewed_house_ids.map((house: any) => {
                  return (
                    <span key={house}>
                      <Typography variant='subtitle2' color='white' component='span'>
                        ID #{house}
                      </Typography>
                      <IconButton onClick={() => handleNavigateToHousePage(house)}>
                        <LinkIcon height={15} width={15} />
                      </IconButton>
                    </span>
                  )
                })}
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Grid height='100%' container justifyContent='center' alignItems='center'>
            {' '}
            {userInfoAction.error}{' '}
          </Grid>
        )}
      </Box>
    </Popover>
  )
}

export default UserInfoComponent
