/* eslint-disable camelcase */
import React from 'react'
import { Typography, Box, Chip } from '@mui/material'
import { IbusinessHoursInitialState, IbusinessHoursInitialState_IDays, ITimeSlot } from './constant'

interface BusinessHourPreviewProps {
  businessHours: IbusinessHoursInitialState
}

const BusinessHourPreview = (props: BusinessHourPreviewProps) => {
  const { businessHours } = props
  return (
    <>
      {businessHours.isOpenAllDays ? (
        <Typography variant='subtitle2' color='white'>
          Open 24/7
        </Typography>
      ) : (
        businessHours.days.map((day: IbusinessHoursInitialState_IDays, i: number) => {
          if (day.active) {
            return (
              <Box key={i} p={1}>
                <Typography variant='subtitle2' color='white'>
                  {day.day}
                  {' : '}
                  {day.timeSlot.map((slot: ITimeSlot, j: number) => {
                    if (slot.from && slot.to) {
                      return (
                        <Chip
                          key={j}
                          label={`${slot.from.format('hh:mm A')} to ${slot.to.format('hh:mm A')}`}
                          color='primary'
                          sx={{ margin: '2px' }}
                        />
                      )
                    }
                    return null
                  })}
                </Typography>
              </Box>
            )
          }
          return null
        })
      )}
    </>
  )
}

export default BusinessHourPreview
