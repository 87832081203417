import React from 'react'
import { Box, Typography, Grid, MenuItem, IconButton, Chip } from '@mui/material'
import GooglePlacesAutocomplete from 'react-google-places-autocomplete'

export interface GooglePlacesAutocomplete_LocationValue {
  label: string
  value: any
}

interface AutoCompleteLocationProps {
  value: GooglePlacesAutocomplete_LocationValue | null
  onChange: (value: any) => void
  label: string
}

const AutoCompleteLocation = (props: AutoCompleteLocationProps) => {
  const { value, onChange, label } = props
  return (
    <>
      <Typography variant='subtitle2' component='p' color='white' mb={1} fontWeight='bold'>
        <label> {label}</label>
      </Typography>

      <Box id='google_auto_complete_wrapper'>
        <GooglePlacesAutocomplete
          selectProps={{
            placeholder: `Type ${label}`,
            value: value,
            onChange: onChange,
            styles: {
              input: (provided) => ({
                ...provided,
                color: 'white',
              }),
              option: (provided) => ({
                ...provided,
                color: 'white',
                background: '#5F5F5F',
                cursor: 'pointer',
              }),
              singleValue: (provided) => ({
                ...provided,
                color: 'white',
              }),
              control: (provider) => ({
                ...provider,
                background: 'none',
                borderRadius: '16px',
                ':focus': {
                  borderColor: 'white !important',
                },
              }),
              menu: (provider) => ({
                ...provider,
                margin: 0,
                padding: 0,
              }),
              menuList: (provider) => ({
                ...provider,
                margin: 0,
                padding: 0,
                borderRadius: '4px',
              }),
              noOptionsMessage: (provider) => ({
                ...provider,
                background: '#5F5F5F',
              }),
              loadingMessage: (provider) => ({
                ...provider,
                background: '#5F5F5F',
              }),
            },
          }}
          apiOptions={{
            language: 'en',
            region: 'us',
          }}
          minLengthAutocomplete={5}
          debounce={1000}
        />
      </Box>
    </>
  )
}

export default AutoCompleteLocation
