import { TableRow, TableCell, CircularProgress } from '@mui/material'
import React from 'react'

interface TableLoaderProp {
  isLoading: boolean,
  colSpan?: number
}

const TableLoader = (props: TableLoaderProp) => {
  if (!props.isLoading) return null
  return (
    <TableRow
      style={{
        height: '70px',
      }}
    >
      <TableCell colSpan={props.colSpan || 6}  style={{
        textAlign:'center'
      }} >
        <CircularProgress />
      </TableCell>
    </TableRow>
  )
}

export default TableLoader
