import { QueryClient, QueryCache } from 'react-query'

const queryCache = new QueryCache()

const queryClient = new QueryClient({
  queryCache,
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
})
export default queryClient
