import * as React from 'react';
import { styled } from '@mui/material/styles';
import { Typography } from '@mui/material';
import Input from '@mui/material/Input';
import AddIcon from 'src/Components/Icons/AddIcon';
// Define a custom styled file input component
const StyledFileInput = styled('label')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  cursor: 'pointer',
});

// Define the props interface for the styled file input component
interface StyledFileInputProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  multiple?: boolean
}

// Define the styled file input component
const StyledFileInputComponent: React.FC<StyledFileInputProps> = ({ onChange, multiple = false }) => (
  <StyledFileInput>
    <AddIcon />
    <Input
      type="file"
      onChange={onChange}
      inputProps={{ accept: 'image/*', multiple }}
      sx={{ display: 'none' }}
    
    />
    <Typography pt={1} variant="subtitle2" component="p" color="white" > Add Photo</Typography>
  </StyledFileInput>
);

export default StyledFileInputComponent;
