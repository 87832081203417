import axios from 'src/api/axios'

const ENDPOINT = 'api/v1/genaric/'

export function fetchNotifications(page = 1) {
  return new Promise((resolve, reject) => {
    const limit = 20
    const offset = (page - 1) * limit

    axios
      .get(ENDPOINT + 'bell_notifications/', {
        params: {
          limit,
          offset,
        },
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

export function updateMarkAsRead(id: number) {
  return new Promise((resolve, reject) => {
    axios
      .patch(ENDPOINT + `bell_notifications/${id}/mark_as_read/`, {
        // eslint-disable-next-line camelcase
        is_read: true,
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}


interface sendChatPushNotificationPayload{
  data:any;
  message: string; 
  recipient: number[]
}

export function sendChatPushNotification(payload: sendChatPushNotificationPayload) {
  return new Promise((resolve, reject) => {
    axios
      .post(ENDPOINT + 'chat-notification/',payload)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}
