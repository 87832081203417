import React from 'react'
import { Box, Typography, Grid, CircularProgress, Avatar, Divider, Badge } from '@mui/material'

import dayjs from 'dayjs'

import relativeTime from 'dayjs/plugin/relativeTime'
import { useMutation } from 'react-query'
import { updateMarkAsRead } from 'src/api/notification'
import { useNavigate } from 'react-router-dom'
import { routeUrls } from 'src/utils/constant'

dayjs.extend(relativeTime)

interface NotificationRowProp {
  notification: any
}

const NotificationRow = ({ notification }: NotificationRowProp) => {
  const navigate = useNavigate()

  const navigateToDetinationPage = () => {
    switch (notification.data.notification_type) {
      case 'customer_support':
        navigate(routeUrls.customerSupport + `/${notification.data.id}`)
        break

      case 'house_request':
        navigate(routeUrls.houseRequests + `/${notification.data.id}`)
        break

      default:
        navigate(routeUrls.dashboard)
        break
    }
  }

  const markAsReadMutation = useMutation(updateMarkAsRead, {
    onSuccess: (data: any, variables, context) => {
      navigateToDetinationPage()
    },
  })

  const handleRead = () => {
    markAsReadMutation.mutate(notification.id)
  }

  if (markAsReadMutation.isLoading) {
    return (
      <Box pt={3}>
        <Box textAlign='center'>
          <CircularProgress />
        </Box>
      </Box>
    )
  }

  return (
    <Box>
      <Grid
        container
        justifyContent='space-between'
        mt={1}
        mb={1}
        sx={{ cursor: 'pointer' }}
        onClick={handleRead}
      >
        <Grid item>
          <Box sx={{ flexGrow: 0 }} mt={1} alignContent='center' alignItems='center'>
            <Grid container sx={{ p: 0 }}>
              <Avatar
                alt={notification.user.name}
                src={notification.user.picture || 'brokenimage.png'}
              />
              <Typography
                variant='subtitle2'
                component='p'
                fontSize='0.8rem'
                color='white'
                ml={3}
                textAlign='left'
              >
                <strong> {notification.user.name}</strong>
                <>
                  <p> {notification.title}</p>

                  <p
                    style={{
                      background: '#343434',
                      borderRadius: '8px',
                      padding: '3px',
                      width: 'max-content',
                    }}
                  >
                    {' '}
                    {notification.message}
                  </p>
                </>
              </Typography>
            </Grid>
          </Box>
        </Grid>

        <Grid item pr={5}>
          <Grid container justifyContent='space-between'>
            <Grid item mr={10}>
              <Typography variant='subtitle2' component='p'>
                {dayjs(notification.created_at).fromNow()}
              </Typography>
            </Grid>
            <Grid item> {!notification.is_read && <Badge badgeContent={''} color='error' />} </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
    </Box>
  )
}

export default NotificationRow
