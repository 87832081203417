import React from 'react'
import { Grid } from '@mui/material'
import DashboardInfoCard from 'src/Components/DashboardInfoCard'
import { UseQueryResult, useQuery } from 'react-query'
import { fetchDashboardStats } from 'src/api/dashboard'
import { DashboardStatsResponseData } from 'src/api/dashboard/index.types'

const Stats = () => {
  const { isLoading, data }: UseQueryResult<DashboardStatsResponseData> = useQuery({
    queryKey: ['dashboard_stats'],
    queryFn: fetchDashboardStats,
    cacheTime: 0,
    retry: 0,
  })

  console.log(data, 'data')

  return (
    <Grid container mt={3}>
      <Grid lg={3} md={6} sm={6} xs={12} p={2}>
        <DashboardInfoCard label='Total Users' value={data?.data.total_user_count || 0} />
      </Grid>

      <Grid lg={3} md={6} sm={6} xs={12} p={2}>
        <DashboardInfoCard label='Total Profiles' value={data?.data.total_profile_count || 0} />
      </Grid>

      <Grid lg={3} md={6} sm={6} xs={12} p={2}>
        <DashboardInfoCard label='Businesses' value={data?.data.total_business_count || 0} />
      </Grid>

      <Grid lg={3} md={6} sm={6} xs={12} p={2}>
        <DashboardInfoCard label='Houses Listed' value={data?.data.total_house_count || 0} />
      </Grid>
    </Grid>
  )
}

export default Stats
