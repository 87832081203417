/* eslint-disable camelcase */
import axios from 'src/api/axios'

const ENDPOINT = 'api/v1/admin/settings/'

export function fetchAccountInfo(id: number) {
  return new Promise((resolve, reject) => {
    axios
      .get(ENDPOINT + `${id}/`)
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

interface updateAccountInfoPayload{
 firstName: string;
 lastName: string;
 email:string
}

export function updateAccountInfo({firstName, lastName, email}:updateAccountInfoPayload) {
  return new Promise((resolve, reject) => {
    axios
      .post(ENDPOINT , {
        first_name: firstName,
        last_name: lastName,
        email: email,
        name: `${firstName} ${lastName}`
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}

interface updatePasswordInfoPayload{
  currentPassword: string;
  newPassword: string;
  confirmPassword:string
 }
 
 export function updatePasswordInfo({currentPassword, newPassword, confirmPassword}:updatePasswordInfoPayload) {
   return new Promise((resolve, reject) => {
     axios
       .post(ENDPOINT + 'change_password/' , {
        old_password: currentPassword,
        new_password1: newPassword,
        new_password2: confirmPassword,
       })
       .then((res) => {
         resolve(res.data)
       })
       .catch((err) => {
         reject(err)
       })
   })
 }




 export function saveDeviceIdForPushNotification(playerId: string) {
  return new Promise((resolve, reject) => {
    axios
      .post('api/v1/genaric/device_info/',{
        player_id:playerId
      })
      .then((res) => {
        resolve(res.data)
      })
      .catch((err) => {
        reject(err)
      })
  })
}