import React, { useContext, useState, useEffect } from 'react'
import { AuthContext } from 'src/Context/AuthContext'

import { Box, Typography, Divider, Grid, Button, CircularProgress } from '@mui/material'
import InputField from 'src/Components/InputField'
import { fetchAccountInfo, updateAccountInfo, updatePasswordInfo } from 'src/api/setting'
import { useMutation, useQuery } from 'react-query'
import { errorToast, successToast } from 'src/utils/toastService'
const Setting = () => {
  const context = useContext(AuthContext)
  const userId = Number(context.user?.id)

  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')

  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState('')

  const { isLoading, data }: any = useQuery({
    queryKey: ['adminInfo', userId],
    queryFn: () => fetchAccountInfo(userId),
    cacheTime: 0,
    retry: 0,
  })

  useEffect(() => {
    if (data?.data) {
      setFirstName(data.data.first_name)
      setLastName(data.data.last_name)
      setEmail(data.data.email)
    }
  }, [data])

  const updateAccountInfoMutation = useMutation(updateAccountInfo, {
    onError: (error: any, variables, context) => {
      // An error happened!
      errorToast('Oops, unable to save account info!')
    },
    onSuccess: (data: any, variables, context) => {
      // Boom baby!
      successToast('Account info changed successfully.')
    },
  })

  const handleAccountInfoSubmit = () => {
    if (!firstName) {
      errorToast('First Name is required.')
      return
    }

    if (!lastName) {
      errorToast('Last Name is required.')
      return
    }
    updateAccountInfoMutation.mutate({
      firstName,
      lastName,
      email: email || context.user?.email || '',
    })
  }

  const updatePasswordInfoMutation = useMutation(updatePasswordInfo, {
    onError: (error: any, variables, context) => {
      // An error happened!
      console.log(error)

      errorToast(error?.response?.data?.error || 'Oops, unable to change password!')
    },
    onSuccess: (data: any, variables, context) => {
      // Boom baby!
      successToast('Password changed successfully.')
      setCurrentPassword('')
      setNewPassword('')
      setConfirmPassword('')
    },
  })

  const handlePasswordInfoSubmit = () => {
    if (!currentPassword) {
      errorToast('Current password is required.')
      return
    }

    if (!newPassword) {
      errorToast('New password is required.')
      return
    }

    if (newPassword !== confirmPassword) {
      errorToast('Confirm password is not correct.')
      return
    }

    updatePasswordInfoMutation.mutate({
      currentPassword,
      newPassword,
      confirmPassword,
    })
  }
  return (
    <Box p={3}>
      <Typography variant='h5' color='primary' fontWeight='bold'>
        Settings
      </Typography>

      <Box mt={3} p={2}>
        <Box>
          <Typography variant='subtitle1' color='primary' fontWeight='bold'>
            Account Info
          </Typography>
          <Divider />
          <Box mt={1}>
            <Grid container>
              <Grid xs={12} sm={12} md={4} m={3} ml={0}>
                <InputField
                  name='firstname'
                  id='firstname'
                  type='text'
                  label='First Name'
                  placeholder='Enter First Name'
                  value={firstName}
                  onChange={(e: any) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid xs={12} sm={12} md={4} m={3} ml={0}>
                <InputField
                  name='lastname'
                  id='lastname'
                  type='text'
                  label='Last Name'
                  placeholder='Enter Last Name'
                  value={lastName}
                  onChange={(e: any) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid xs={12} sm={12} md={4} m={3} ml={0}>
                <InputField
                  name='email'
                  id='email'
                  type='text'
                  label='Email'
                  placeholder='Email'
                  value={email}
                  disabled
                  // onChange={(e: any) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} sm={12} md={4} m={3} ml={0}>
                <Button
                  variant='contained'
                  fullWidth
                  color='primary'
                  onClick={handleAccountInfoSubmit}
                  disabled={isLoading || updateAccountInfoMutation.isLoading}
                >
                  {isLoading || updateAccountInfoMutation.isLoading ? (
                    <CircularProgress size={25} />
                  ) : (
                    'Save'
                  )}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>

        <Box>
          <Typography variant='subtitle1' color='primary' fontWeight='bold'>
            Password
          </Typography>
          <Divider />
          <Box mt={1}>
            <Grid container>
              <Grid xs={12} sm={12} md={4} m={3} ml={0}>
                <InputField
                  name='currentPassword'
                  id='currentPassword'
                  type='password'
                  autoComplete='new-password' // to turn off browser auto complete
                  label='Current Password'
                  placeholder='Enter Current Password'
                  value={currentPassword}
                  onChange={(e: any) => setCurrentPassword(e.target.value)}
                />
              </Grid>
              <Grid xs={12} sm={12} md={4} m={3} ml={0}>
                <InputField
                  name='newPassword'
                  id='newPassword'
                  type='password'
                  label='New Password'
                  placeholder='Enter New Password'
                  value={newPassword}
                  onChange={(e: any) => setNewPassword(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} sm={12} md={4} m={3} ml={0}>
                <InputField
                  name='confirmPassword'
                  id='confirmPassword'
                  type='password'
                  label='Confirm Password'
                  placeholder='Enter Confirm Password'
                  value={confirmPassword}
                  onChange={(e: any) => setConfirmPassword(e.target.value)}
                />
              </Grid>
            </Grid>
            <Grid container>
              <Grid xs={12} sm={12} md={4} m={3} ml={0}>
                <Button
                  variant='contained'
                  fullWidth
                  color='primary'
                  onClick={handlePasswordInfoSubmit}
                  disabled={updatePasswordInfoMutation.isLoading}
                >
                  {updatePasswordInfoMutation.isLoading ? <CircularProgress size={25} /> : 'Save'}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Setting
