/* eslint-disable camelcase */
import * as React from 'react'
import { Box, Typography } from '@mui/material'
import Interests from './Interests'
import Amenities from './Amenities'
import TypeofHouse from './TypeofHouse'
import HouseRules from './HouseRules'
import MyTab from 'src/Components/TabComponents/MyTab'

export default function Management() {
  const menus = [
    {
      label: 'Interests',
      component: <Interests />,
    },
    {
      label: 'Amenities',
      component: <Amenities />,
    },
    {
      label: 'Type of House',
      component: <TypeofHouse />,
    },
    {
      label: 'House Rules',
      component: <HouseRules />,
    },
  ]
  const [activeTabIndex, setActiveTabIndex] = React.useState(0)

  const handleActiveIndexChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTabIndex(newValue)
  }

  return (
    <Box p={3} >
      <Box>
        <Typography variant='h5' color='primary' fontWeight='bold'>
          Management
        </Typography>
      </Box>

      <Box mt={2}>
        <MyTab menuItems={menus} value={activeTabIndex} onChange={handleActiveIndexChange} />
      </Box>
    </Box>
  )
}
